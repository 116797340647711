import React, { useState } from "react";
import "./Card.css";
import { AiFillDelete, AiFillEdit } from "react-icons/ai";
import { BsImageFill } from "react-icons/bs";

function MyCard({
  title,
  desc,
  icon,
  image,
  onClickEdit,
  onClickDelete,
  onClick,
  nonEditable = false,
}) {
  const [cardFlip, setCardFlip] = useState(false);
  const onClickImageIcon = () => {
    setCardFlip(!cardFlip);
  };

  return (
    <div className="myCard" onClick={onClick}>
      <div className="myCard__actions">
        {image ? (
          <BsImageFill
            className="myCard__imageIcon"
            onClick={onClickImageIcon}
          />
        ) : null}

        {!nonEditable ? (
          <>
            <AiFillEdit className="myCard__editIcon" onClick={onClickEdit} />

            <AiFillDelete
              className="myCard__deleteIcon"
              onClick={onClickDelete}
            />
          </>
        ) : null}
      </div>
      <div
        className="myCard__wrapper"
        style={{ transform: cardFlip ? "rotateY(180deg)" : "rotateY(0deg)" }}
      >
        <div className="myCard__front">
          {image ? (
            <img className="myCard__frontImage" src={image} alt="cardImage" />
          ) : null}
          <div className="myCard__frontWrapper">
            <div className="myCard__detailsWrapper">
              <div className="myCard__details">
                {title ? <p className="myCard__title">{title}</p> : null}
                {desc ? <p className="myCard__desc">{desc}</p> : null}
              </div>
              {icon ? <div className="myCard__iconWrapper">{icon}</div> : null}
            </div>
          </div>
        </div>
        <div className="myCard__back">
          <img className="myCard__backImage" src={image} alt="cardImage" />
        </div>
      </div>
    </div>
  );
}

export default MyCard;
