import Layout from "../../component/Layout";
import Breadcrumbs from "../../library/Breadcrumbs";
import { useEffect, useMemo, useState } from "react";
import MaterialReactTable from "material-react-table";
import { Edit as EditIcon, Delete as DeleteIcon } from "@mui/icons-material";
import { Box, IconButton, Button as MaterialButton } from "@mui/material";
import "./style.css";
import { callAPI } from "../../Network/networkConnection";
import {
  APIUrl,
  NetworkConfiguration,
} from "../../Network/networkConfiguration";
import moment from "moment/moment";
import { FaFileDownload } from "react-icons/fa";
import AlertPopUp from "../../component/AlertPopUp";
import { successToast } from "../../helper/toast";
import Form from "../../component/Form";
import PopModal from "../../component/PopUpModal";
import ClientForm from "../../forms_components/ClientForm";
import { mkConfig, generateCsv, download } from "export-to-csv";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

const AppliedJobs = () => {
  const [jobRequests, setJobRequests] = useState([]);
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [jobMessage, setJobMessage] = useState("");
  const [jobId, setJobId] = useState("");

  useEffect(() => {
    fetchJobs();
  }, []);

  const handleDeleteRow = (id) => {
    callAPI(APIUrl + NetworkConfiguration.JOBREQUEST + "/" + id, "DELETE")
      .then((res) => {
        successToast(res.message);
        console.log(res, "deleted");
        fetchJobs();
        setJobId("");
        setDeleteAlert(false);
      })
      .catch((err) => console.log(err));
  };

  const fetchJobs = () => {
    callAPI(APIUrl + NetworkConfiguration.JOBREQUEST, "GET")
      .then((res) => {
        setJobRequests(res.data);
      })
      .catch((err) => {
        if (err.status === 404) {
          setJobRequests([]);
        }
        console.log("error", err);
      });
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "firstName", //simple recommended way to define a column
        header: "First Name",
        Header: <i style={{ color: "#31435f" }}>First Name</i>,
      },
      {
        accessorKey: "lastName", //simple recommended way to define a column
        header: "Last Name",
        Header: <i style={{ color: "#31435f" }}>Last Name</i>,
      },
      {
        accessorKey: "email",
        header: "E-mail",
        Header: <i style={{ color: "#31435f" }}>E-mail</i>,
      },
      {
        accessorKey: "contact",
        header: "Mobile Number",
        Header: <i style={{ color: "#31435f" }}>Mobile Number</i>,
      },
      {
        accessorKey: "careerOpening",
        header: "Career Opening",
        Header: <i style={{ color: "#31435f" }}>Career Opening</i>,
      },
      {
        accessorKey: "message",
        header: "Message",
        size: 300,
        Header: <i style={{ color: "#31435f" }}>Message</i>,
        Cell: ({ cell }) => {
          return (
            <p
              onClick={() => {
                setShowMessage(true);
                setJobMessage(cell.row.original?.message);
              }}
              className="message__hover"
            >
              {cell.row.original?.message}
            </p>
          );
        },
      },

      {
        accessorKey: "createdAt",
        header: "Date & Time",
        Header: <i style={{ color: "#31435f" }}>Date & Time</i>,
        Cell: ({ cell }) =>
          moment(cell.row.original.createdAt).format("MM/DD/YYYY , LT"),
      },
      {
        accessorKey: "image",
        header: "Image",
        Header: <i style={{ color: "#31435f" }}>Image</i>,
        Cell: ({ cell }) => {
          console.log(cell, "cell");
          return (
            <a
              href={cell.row.original?.imageUrl}
              target="_blank"
              rel="noreferrer"
            >
              <FaFileDownload />
            </a>
          );
        },
      },
    ],
    []
  );

  const onClickDelete = (id) => {
    setDeleteAlert(true);
    setJobId(id);
  };

  const csvConfig = mkConfig({
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: true,
    // headers: columns.map((c) => c.header),
  });

  const handleExportRows = (rows) => {
    const csv = generateCsv(csvConfig)(rows.map((row) => row.original));
    download(csvConfig)(csv);
  };

  const handleExportData = () => {
    const csv = generateCsv(csvConfig)(jobRequests);
    download(csvConfig)(csv);
  };

  return (
    <div>
      {/* <Layout> */}
      <div className="middle_container_top_alignment">
        <div className="breadcrumb__styling">
          <div className="path_styling">
            <h3 className="path__header">Applied Jobs</h3>
            {/* <Breadcrumbs /> */}
          </div>
        </div>
      </div>

      <AlertPopUp
        show={deleteAlert}
        header="Delete Applied Job"
        description="Do you want to delete this applied job?"
        submitText="Yes"
        cancelText="No"
        onCancelClick={() => setDeleteAlert(false)}
        onSubmitClick={() => handleDeleteRow(jobId)}
      ></AlertPopUp>

      <PopModal show={showMessage} onRequestClose={() => setShowMessage(false)}>
        <div
          style={{
            width: "30rem",
            height: "auto",
            overflow: "scroll",
            padding: ".5rem",
            textAlign: "justify",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {jobMessage}
        </div>
      </PopModal>

      <div className="table_data">
        <MaterialReactTable
          columns={columns}
          data={jobRequests}
          enableRowNumbers={true}
          enableColumnOrdering
          enableGlobalFilter={false}
          enableRowActions
          enableRowSelection
          renderRowActions={({ row, table }) => (
            // <Box
            //   sx={{ display: "flex", flexWrap: "nowrap", height: "300px" }}
            // >
            <IconButton
              color="error"
              onClick={() => {
                jobRequests.splice(row.index, 1);
              }}
            >
              <DeleteIcon onClick={() => onClickDelete(row.original._id)} />
            </IconButton>
            // </Box>
          )}
          renderTopToolbarCustomActions={({ table }) => (
            <Box
              sx={{
                display: "flex",
                gap: "1rem",
                p: "0.5rem",
                flexWrap: "wrap",
              }}
            >
              <MaterialButton
                color="primary"
                //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                onClick={handleExportData}
                startIcon={<FileDownloadIcon />}
                variant="contained"
              >
                Export All Data
              </MaterialButton>
              <MaterialButton
                disabled={table.getPrePaginationRowModel().rows.length === 0}
                //export all rows, including from the next page, (still respects filtering and sorting)
                onClick={() =>
                  handleExportRows(table.getPrePaginationRowModel().rows)
                }
                startIcon={<FileDownloadIcon />}
                variant="contained"
              >
                Export All Rows
              </MaterialButton>
              <MaterialButton
                disabled={table.getRowModel().rows.length === 0}
                //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
                onClick={() => handleExportRows(table.getRowModel().rows)}
                startIcon={<FileDownloadIcon />}
                variant="contained"
              >
                Export Page Rows
              </MaterialButton>
              <MaterialButton
                disabled={
                  !table.getIsSomeRowsSelected() &&
                  !table.getIsAllRowsSelected()
                }
                //only export selected rows
                onClick={() =>
                  handleExportRows(table.getSelectedRowModel().rows)
                }
                startIcon={<FileDownloadIcon />}
                variant="contained"
              >
                Export Selected Rows
              </MaterialButton>
            </Box>
          )}
        />
      </div>
      {/* </Layout> */}
    </div>
  );
};

export default AppliedJobs;
