import { useEffect, useState } from "react";
import Layout from "../../component/Layout";
import PopModal from "../../component/PopUpModal";
import Breadcrumbs from "../../library/Breadcrumbs";
import Button from "../../library/Button";
import "./style.css";
import { callAPI } from "../../Network/networkConnection";
import {
  APIUrl,
  NetworkConfiguration,
} from "../../Network/networkConfiguration";
import Dropdown from "../../library/DropDown";
import SubCategoryForm from "../../forms_components/SubCategoryForm";
import MyCard from "../../component/MyCard/Card";
import AlertPopUp from "../../component/AlertPopUp";
import { ImageService } from "../../service/ImageService";
import { AiFillDelete } from "react-icons/ai";
import { errorToast, successToast } from "../../helper/toast";

const SubCategory = () => {
  const [showUserForm, setShowUserForm] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [showCategory, setShowCategory] = useState([]);
  const [subcategory, setSubCategory] = useState([]);
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [subCategoryId, setSubCategoryId] = useState("");
  const [subCategoryImage, setSubCategoryImage] = useState("");
  const [isEdited, setIsEdited] = useState(false);
  const [subCategoryData, setSubCategoryData] = useState({});

  useEffect(() => {
    if (selectedCategory) {
      fetchSubCategories();
    } else {
      setSubCategory([]);
    }
  }, [selectedCategory]);

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = () => {
    callAPI(APIUrl + NetworkConfiguration.GETCATEGORIES, "GET")
      .then((res) => {
        setShowCategory(convertItemsForDropdown(res.categories));
      })
      .catch((err) => console.log("error", err));
  };

  const convertItemsForDropdown = (items) => {
    let dropdownItems = [
      // {
      //   name: "Any",
      //   value: "",
      // },
    ];
    setSelectedCategory(items[0]?._id);
    for (let i = 0; i < items.length; i++) {
      dropdownItems.push({
        name: items[i]?.categoryName,
        value: items[i]?._id,
      });
    }

    return dropdownItems;
  };

  const onSubmit = (subCategoryData) => {
    addSubCategory(subCategoryData);
  };
  const addSubCategory = (subCategoryData) => {
    let data = new FormData();
    data.append("category", selectedCategory);
    data.append("subCategoryName", subCategoryData.subCategoryName);
    data.append("image", subCategoryData.imageUrl);

    callAPI(APIUrl + NetworkConfiguration.ADDSUBCATEGORY, "POST", data)
      .then((res) => {
        setSubCategoryData({
          category: "",
          subCategoryName: "",
          imageUrl: "",
        });
        successToast(res.message);
        console.log("add subcategory response: ", res);
        setShowUserForm(false);
        fetchSubCategories();
      })
      .catch((err) => {
        errorToast(" Please enter full details");

        console.log(err, "reject.......");
      });
  };

  const fetchSubCategories = () => {
    callAPI(
      APIUrl +
        NetworkConfiguration.SUBCATEGORYBYCATEGORY +
        "/" +
        selectedCategory,
      "GET"
    )
      .then((res) => {
        setSubCategory(res.subCategories);
      })
      .catch((err) => {
        if (err.status === 404) {
          setSubCategory([]);
        }
        console.log("error", err);
      });
  };

  const onUpdate = (subCategoryData) => {
    subCategoryData.id = subCategoryId;
    callAPI(
      APIUrl + NetworkConfiguration.UPDATESUBCATEGORY,
      "PUT",
      JSON.stringify(subCategoryData),
      { "Content-Type": "application/json" }
    )
      .then((res) => {
        successToast(res.message);
        if (subCategoryData?.imageUrl) {
          ImageService.imageUpload(
            subCategoryData?.imageUrl,
            "subcategory",
            res?.id
          )
            .then(() => {
              setSubCategoryData({
                category: "",
                subCategoryName: "",
                imageUrl: "",
              });
              setIsEdited(false);
              setShowUserForm(false);
              fetchSubCategories();
            })
            .catch((err) => {
              setShowUserForm(false);

              errorToast(err.message);
              console.log(err);
            });
        } else {
          console.log("hellooowowo");
          setShowUserForm(false);
          fetchSubCategories();
        }
      })
      .catch((err) => {
        setShowUserForm(false);

        errorToast(err.message);
        console.log(err);
      });
  };

  const handleDeleteRow = (_id, iconUrl) => {
    callAPI(
      APIUrl + NetworkConfiguration.DELETESUBCATEGORY,
      "DELETE",
      JSON.stringify({
        subCategoryId: _id,
        imageUrl: iconUrl,
      }),
      { "Content-Type": "application/json" }
    )
      .then((res) => {
        successToast(res.message);
        fetchSubCategories();
        setDeleteAlert(false);
        setSubCategoryId("");
        setSubCategoryImage("");
      })
      .catch((err) => console.log("error", err));
  };

  const onClickDelete = (id, image) => {
    setDeleteAlert(true);
    setSubCategoryId(id);
    setSubCategoryImage(image);
  };

  const onClickEdit = (id) => {
    setSubCategoryId(id);
    setIsEdited(true);
    setShowUserForm(true);
  };

  return (
    <div>
      {/* <Layout> */}
      <div className="middle_container_top_alignment">
        <div className="breadcrumb__styling">
          <div className="path_styling">
            <h3 className="path__header"> Sub-Category</h3>
            {/* <Breadcrumbs /> */}
          </div>
        </div>
        <div className="header__align">
          <Dropdown
            onChange={(e) => {
              setSelectedCategory(e.target.value);
            }}
            options={showCategory}
            style={{ width: "10vw" }}
          />
          {selectedCategory ? (
            <Button
              text="Add Sub-Category"
              onClick={() => setShowUserForm(true)}
              style={{
                height: "6vh",
                width: "auto",
                margin: "20px",
                display: "flex",
                padding: "10px",
                alignItems: "center",
                justifyContent: "center",
              }}
            />
          ) : (
            ""
          )}
        </div>

        <AlertPopUp
          show={deleteAlert}
          header="Delete Sub-Category"
          description="Do you want to delete this Sub-Category?"
          submitText="Yes"
          cancelText="No"
          onCancelClick={() => setDeleteAlert(false)}
          onSubmitClick={() => handleDeleteRow(subCategoryId, subCategoryImage)}
        ></AlertPopUp>

        <PopModal
          show={showUserForm}
          onRequestClose={() => setShowUserForm(false)}
        >
          <SubCategoryForm
            onSubmit={onSubmit}
            onUpdate={onUpdate}
            data={subCategoryData}
            isEdited={isEdited}
          />
        </PopModal>
      </div>

      <div className="category__card">
        {subcategory?.length > 0 &&
          subcategory.map((subCategory) => {
            return (
              <MyCard
                title={subCategory?.subCategoryName}
                image={subCategory?.imageUrl}
                onClickEdit={() => {
                  setSubCategoryData({
                    subCategoryName: subCategory.subCategoryName,
                    imageUrl: subCategory.imageUrl,
                  });
                  onClickEdit(subCategory._id, subCategory.imageUrl);
                }}
                onClickDelete={() =>
                  onClickDelete(subCategory._id, subCategory.imageUrl)
                }
              />
            );
          })}
      </div>
      {/* </Layout> */}
    </div>
  );
};

export default SubCategory;
