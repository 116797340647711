import { useEffect, useState } from "react";
import InputField from "../../library/TextField";
import Button from "../../library/Button";
import "./style.css";

const SubCategoryForm = ({ onSubmit, onUpdate, isEdited, data }) => {
  const [subCategoryData, setSubCategoryData] = useState({
    subCategoryName: "",
    imageUrl: "",
  });
  const [error, setError] = useState({
    subCategoryName: "",
    imageUrl: "",
  });

  const setSubCategoryName = (e) => {
    setSubCategoryData({ ...subCategoryData, subCategoryName: e.target.value });
    setError({ ...error, subCategoryName: "" });
  };

  const setSubCategoryImage = (e) => {
    setSubCategoryData({ ...subCategoryData, imageUrl: e.target.files[0] });
    setError({ ...error, imageUrl: "" });
  };

  useEffect(() => {
    setSubCategoryData(data);
  }, [data]);

  const onClickSubmit = () => {
    if (validate()) {
      isEdited ? onUpdate(subCategoryData) : onSubmit(subCategoryData);
    }
  };

  const validate = () => {
    let result = true;
    if (!subCategoryData.subCategoryName) {
      setError({ ...error, subCategoryName: "Sub-Category name is required" });
      result = false;
    } else if (!subCategoryData.imageUrl) {
      setError({ ...error, imageUrl: "Image is required" });
      result = false;
    }
    return result;
  };

  return (
    <div style={{ width: "350px", height: "auto" }}>
      <h1 className="form__heading">
        {isEdited ? "Edit Sub-Category" : "Add Sub-Category"}
      </h1>

      <div className="form__data">
        <InputField
          value={subCategoryData.subCategoryName}
          onChange={setSubCategoryName}
          label="Sub-Category Name"
          error={error.subCategoryName}
        />
      </div>
      <br />

      <div className="form__data">
        <InputField
          onChange={setSubCategoryImage}
          label="Sub-Category Icon"
          type="file"
          accept="image/*"
          placeholder="Ex- #products, #3dassets"
          style={{
            paddingTop: "8px",
          }}
          error={error.imageUrl}
        />
      </div>

      <Button
        onClick={onClickSubmit}
        text={isEdited ? "Save Changes" : "Add Sub-Category"}
        style={{
          width: "12vw",
          height: "7vh",
          borderRadius: "5px",
          marginLeft: "5vw",
          marginBottom: "3vh",
          marginTop: "10px",
        }}
      />
    </div>
  );
};

export default SubCategoryForm;
