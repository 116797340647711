import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import 'react-toastify/dist/ReactToastify.css';


//Pages
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import Testimonial from "./pages/Testimonials";
import Users from "./pages/Users";
import Contact from "./pages/ContactUs";
import OrderList from "./pages/OrderList";
import Products from "./pages/Products";
import ProductList from "./pages/ProductList";
import Technologies from "./pages/Technologies";
import Clients from "./pages/Clients";
import CareerOpenings from "./pages/CareerOpenings";
import AppliedJobs from "./pages/AppliedJobs";
import Events from "./pages/Events";
import Cart from "./pages/Cart";
import ProjectMetrics from "./pages/ProjectMetrics";
import Team from "./pages/Team";
import SubCategory from "./pages/SubCategory";
import Banner from "./pages/Banner";
import Notification from "./pages/Notification";
import { ToastContainer } from "react-toastify";
import ChangePassword from "./pages/ChangePassword";
import NewPassword from "./pages/NewPassword";
import EditProfile from "./pages/EditProfile";
import ChangeOldPassword from "./pages/ChangeOldPassword";
import WarnedUser from "./pages/WarnedUsers";
import SuspendedUsers from "./pages/SuspendedUsers";
import AppContainer from "./base/AppContainer";

function App() {
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <ToastContainer />
      <BrowserRouter>
        {/* <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/users" element={<Users />} />
          <Route path="/team" element={<Team />} />
          <Route path="/categories" element={<Products />} />
          <Route path="/subcategories" element={<SubCategory />} />
          <Route path="/productlist" element={<ProductList />} />
          <Route path="/technologies" element={<Technologies />} />
          <Route path="/clients" element={<Clients />} />
          <Route path="/testimonials" element={<Testimonial />} />
          <Route path="/careeropenings" element={<CareerOpenings />} />
          <Route path="/AppliedJobs" element={<AppliedJobs />} />
          <Route path="/events" element={<Events />} />
          <Route path="/banner" element={<Banner />} />
          <Route path="/notification" element={<Notification />} />
          <Route path="/orderhistory" element={<OrderList />} />
          <Route path="/projectmetrics" element={<ProjectMetrics />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/contactus" element={<Contact />} />
          <Route path="/changepassword" element={<ChangePassword />} />
          <Route path="/editprofile" element={<EditProfile />} />
          <Route path="/newpassword" element={<NewPassword />} />
          <Route path="/changeoldpassword" element={<ChangeOldPassword />} />
          <Route path="/warnedusers" element={<WarnedUser />} />
          <Route path="/suspendedusers" element={<SuspendedUsers />} />
        </Routes> */}
        <AppContainer />
      </BrowserRouter>
    </>
  );
}

export default App;
