import { useNavigate } from "react-router-dom";
import "./style.css";
import AlertPopUp from "../AlertPopUp";
import { useState } from "react";

const PopMenu = ({ popOpen, children }) => {
  const [deleteAlert, setDeleteAlert] = useState(false);
  let navigate = useNavigate();
  const handleLogout = () => {
    console.log("sorry");
    localStorage.clear();
    navigate("/login");
  };
  return (
    <div>
      {children}
      <div
        style={{ display: popOpen ? "block" : "none" }}
        className="pop_container"
      >
        <div className="popup_text">
          <h4 className="logout_heading">Welcome Admin!</h4>
          <p
            className="logout_para"
            onClick={() => {
              console.log("Welcome");
              navigate("/changeoldpassword");
            }}
          >
            Change Password
          </p>
          <p
            className="logout_para"
            onClick={() => {
              setDeleteAlert(true);
            }}
          >
            Logout
          </p>
        </div>
      </div>
      <AlertPopUp
        show={deleteAlert}
        header="Logout"
        description="Are you sure, you want to Logout?"
        submitText="Yes"
        cancelText="No"
        onCancelClick={() => setDeleteAlert(false)}
        onSubmitClick={handleLogout}
      ></AlertPopUp>
    </div>
  );
};

export default PopMenu;
