import "./style.css";

const InputField = (props) => {
  return (
    <div
      // className="popup__box"
      style={{
        position: "relative",
        width: "20rem",
      }}
    >
      {/* <div
        style={{
          position: "relative",
          width: "100%",
          marginRight: "13px",
        }}
      > */}
      {props?.label ? (
        <label className="label_styling" style={props.labelStyle}>
          {props.label}
        </label>
      ) : null}
      <input
        style={props.style}
        placeholder={props.placeholder}
        className={`textinput ${props.className}`}
        type={props.type}
        max={props.max}
        min={props.min}
        onChange={props.onChange}
        accept={props.accept}
        value={props.value}
        multiple={props.multiple}
        onClick={props.onClick}
        disabled={props.disabled}
      />
      <div
        onClick={props.onEyeClick}
        style={{
          position: "absolute",
          textAlign: "center",
          right: 10,
          bottom: 10,
          cursor: "pointer",
        }}
      >
        {props.icon}
      </div>
      {props?.error ? <p className="error_styling">*{props.error}</p> : null}
      {/* </div> */}
    </div>
  );
};

export default InputField;
