import React, { useEffect, useState } from "react";
import {
  LineChart,
  //   ResponsiveContainer,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Line,
} from "recharts";
import Layout from "../../component/Layout";
import { callAPI } from "../../Network/networkConnection";
import {
  APIUrl,
  NetworkConfiguration,
} from "../../Network/networkConfiguration";
import MyCard from "../../component/MyCard/Card";
import { useNavigate } from "react-router-dom";
import { StorageConstant } from "../../constant/StorageConstant";
import useUserStore from "../../store/userStore";
import "./style.css";
import DashboardCard from "../../component/DashboardCard/DashboardCard";
import PopModal from "../../component/PopUpModal";
import InputField from "../../library/TextField";
import Button from "../../library/Button";
import moment from "moment/moment";

const Dashboard = () => {
  const { user } = useUserStore();
  console.log("Edit Profile-->>>", user);
  let navigate = useNavigate();
  const [getDetails, setGetDetails] = useState([]);
  const [sales, setSales] = React.useState([]);
  const [users, setUsers] = React.useState([]);
  const [graphData, setGraphData] = useState([]);
  const [showCustomDatePicker, setShowCustomDatePicker] = useState(false);
  const [selectedCustomDateRange, setSelectedCustomDateRange] = useState({
    fromDate: "",
    toDate: "",
  });
  const [selectedDateFilter, setSelectedDateFilter] = useState("");

  useEffect(() => {
    fetch();
    fetchSales();
    fetchUsers();
  }, []);

  useEffect(() => {
    fetchSales(selectedCustomDateRange);
    fetchUsers(selectedCustomDateRange);
  }, [selectedCustomDateRange]);

  useEffect(() => {
    if (selectedDateFilter) {
      const fromDate = moment()
        .startOf(selectedDateFilter)
        .format("YYYY-MM-DD hh:mm");
      const toDate = moment()
        .endOf(selectedDateFilter)
        .format("YYYY-MM-DD hh:mm");

      setSelectedCustomDateRange({
        fromDate,
        toDate,
      });
      if (selectedDateFilter === "custom") {
        setShowCustomDatePicker(true);
        setSelectedCustomDateRange({
          fromDate: "",
          toDate: "",
        });
      } else {
        setShowCustomDatePicker(false);
      }
    } else {
      setShowCustomDatePicker(false);
      setSelectedCustomDateRange({
        fromDate: "",
        toDate: "",
      });
    }
  }, [selectedDateFilter]);

  useEffect(() => {
    let usersSalesGraphData = [];
    sales.forEach((sale) => {
      usersSalesGraphData.push({
        date: `${sale.day}/${sale.month}/${sale.year}`,
        User: 0,
        Orders: sale.count,
      });
    });

    users.forEach((user) => {
      let graphDataIndex = usersSalesGraphData.findIndex(
        (graphData) =>
          graphData.date === `${user.day}/${user.month}/${user.year}`
      );
      if (graphDataIndex >= 0) {
        usersSalesGraphData[graphDataIndex]["User"] = user.count;
      } else {
        usersSalesGraphData.push({
          date: `${user.day}/${user.month}/${user.year}`,
          User: user.count,
          Orders: 0,
        });
      }
    });

    console.log(
      "Graph data after sorting: ",
      usersSalesGraphData.sort(
        (a, b) => parseFloat(a.monthsYears) - parseFloat(b.monthsYears)
      )
    );

    setGraphData(
      usersSalesGraphData.sort(
        (a, b) => parseFloat(a.monthsYears) - parseFloat(b.monthsYears)
      )
    );
  }, [users, sales]);

  // useEffect(() => {
  //   let salesGraphData = [];
  //   sales.forEach((sale) => {
  //     salesGraphData.push({
  //       monthsYears: `${sale._id.month}, ${sale._id.year}`,
  //       count: sale.count,
  //       totalSaleAmount: sale.totalSaleAmount,
  //     });
  //   });
  //   setGraphData(...graphData, [salesGraphData]);
  // }, [sales]);
  // const rearrangedDataHost = Array(12).fill(null);
  // for (let i = 0; i < traffic.length; i++) {
  //   rearrangedDataHost[traffic[i]._id.month - 1] = traffic[i];
  // }

  // const rearrangedDataUser = Array(12).fill(null);
  // for (let i = 0; i < traffic2.length; i++) {
  //   rearrangedDataUser[traffic2[i].month - 1] = traffic2[i];
  // }

  useEffect(() => {
    console.log("Graph data: ", graphData);
  }, [graphData]);

  useEffect(() => {
    async function getLoginHeader() {
      const loginHeader = await localStorage.getItem(
        StorageConstant.LOGIN_HEADER
      );
      if (!loginHeader) {
        navigate("/", { replace: true });
      }
    }
    getLoginHeader();
  }, []);

  const fetch = () => {
    callAPI(APIUrl + NetworkConfiguration.DASHBOARD, "GET")
      .then((res) => {
        setGetDetails(res);
      })
      .catch((err) => console.log("error", err));
  };

  const fetchSales = (selectedCustomDateRange) => {
    callAPI(
      APIUrl + NetworkConfiguration.MONTHLYSALES,
      "POST",
      JSON.stringify(selectedCustomDateRange),
      {
        "Content-Type": "application/json",
      }
    )
      .then((res) => {
        setSales(res.data);
        console.log("Sales", res.data);
      })
      .catch((err) => console.log("error", err));
  };

  const fetchUsers = (selectedCustomDateRange) => {
    callAPI(
      APIUrl + NetworkConfiguration.MONTHLYUSER,
      "POST",
      JSON.stringify(selectedCustomDateRange),
      {
        "Content-Type": "application/json",
      }
    )
      .then((res) => {
        setUsers(res.data);
        console.log("Users ", res.data);
      })
      .catch((err) => console.log("error", err));
  };
  return (
    <div>
      {/* <Layout> */}
      <div className="middle_container_top_alignment">
        <div className="breadcrumb__styling">
          <div className="path_styling">
            <h3 className="path__header">Dashboard</h3>
            {/* <Breadcrumbs /> */}
          </div>
        </div>
      </div>
      <div
        style={{
          padding: "1rem 2rem",
          width: "100%",
          display: "flex",
          rowGap: "0.5rem",
          columnGap: "0.5rem",
          flexWrap: "wrap",
        }}
      >
        <div
          style={{
            flexGrow: 1,
            flexShrink: 0,
            justifyContent: "center",
            display: "flex",
          }}
        >
          <DashboardCard
            onClick={() => {
              navigate("/productList");
            }}
            nonEditable={true}
            title={"Total Products"}
            icon={
              <p style={{ fontSize: "2rem", fontWeight: "700" }}>
                {getDetails.totalProducts}
              </p>
            }
          />
        </div>

        <div
          style={{
            flexGrow: 1,
            flexShrink: 0,
            justifyContent: "center",
            display: "flex",
          }}
        >
          <DashboardCard
            onClick={() => {
              navigate("/Users");
            }}
            nonEditable={true}
            title={"Total Users"}
            icon={
              <p style={{ fontSize: "2rem", fontWeight: "700" }}>
                {getDetails.totalUsers}
              </p>
            }
          />
        </div>
        <div
          style={{
            flexGrow: 1,
            flexShrink: 0,
            justifyContent: "center",
            display: "flex",
          }}
        >
          <DashboardCard
            onClick={() => {
              navigate("/clients");
            }}
            nonEditable={true}
            title={"Total Clients"}
            icon={
              <p style={{ fontSize: "2rem", fontWeight: "700" }}>
                {getDetails.totalClient}
              </p>
            }
          />
        </div>
        <div
          style={{
            flexGrow: 1,
            flexShrink: 0,
            justifyContent: "center",
            display: "flex",
          }}
        >
          <DashboardCard
            onClick={() => {
              navigate("/orderhistory");
            }}
            nonEditable={true}
            title={"Total Completed Orders"}
            icon={
              <p style={{ fontSize: "2rem", fontWeight: "700" }}>
                {getDetails.totalOrders}
              </p>
            }
          />
        </div>
      </div>
      <div className="back">
        <div className="chart-heading">
          <h1> Users and Monthly Sales </h1>
          <select
            onChange={(e) => {
              setSelectedDateFilter(e.target.value);
            }}
            style={{ padding: "10px", borderRadius: "none" }}
            className="suspend_warning_action"
          >
            <option value="" selected default>
              Select
            </option>
            <option className="suspend_warning_action" value="week">
              This week
            </option>
            <option className="suspend_warning_action" value="month">
              This month
            </option>
            <option className="suspend_warning_action" value="custom">
              Custom date
            </option>
          </select>
          {/* <p> Of The Year {traffic[0]?._id?.year}</p> */}
        </div>

        <div
          className="dateRangeWrapper"
          style={{ display: showCustomDatePicker ? "flex" : "none" }}
        >
          <InputField
            onChange={(e) => {
              console.log(
                "From date value: ",
                moment(e.target.value).format("YYYY-MM-DD hh:mm")
              );
              setSelectedCustomDateRange({
                ...selectedCustomDateRange,
                fromDate: moment(e.target.value).format("MM-DD-YYYY"),
              });
            }}
            type="date"
            label="From Date"
          />
          <InputField
            onChange={(e) => {
              console.log(
                "To date value: ",
                moment(e.target.value).format("MM-DD-YYYY")
              );
              setSelectedCustomDateRange({
                ...selectedCustomDateRange,
                toDate: moment(e.target.value).format("YYYY-MM-DD hh:mm"),
              });
            }}
            disabled={selectedCustomDateRange?.fromDate ? false : true}
            type="date"
            label="To Date"
            min={moment(selectedCustomDateRange?.fromDate).format("YYYY-MM-DD")}
          />
        </div>

        <div className="chart-design">
          <LineChart
            width={1200}
            height={300}
            data={graphData}
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip /*contentStyle={{ backgroundColor: "#39aeed" }}*/ />
            <Legend />
            <Line type="monotone" dataKey="Orders" stroke="#8884d8" />
            <Line type="monotone" dataKey="User" stroke="#82ca9d" />
          </LineChart>
          {/* </ResponsiveContainer> */}
        </div>
      </div>
      {/* </Layout> */}
    </div>
  );
};

export default Dashboard;
