import { useEffect, useState } from "react";
import Layout from "../../component/Layout";
import Breadcrumbs from "../../library/Breadcrumbs";
import Button from "../../library/Button";
import InputField from "../../library/TextField";
import "./style.css";
import { callAPI } from "../../Network/networkConnection";
import {
  APIUrl,
  NetworkConfiguration,
} from "../../Network/networkConfiguration";
import { successToast } from "../../helper/toast";

const ProjectMetrics = () => {
  const [matrixData, setMatrixData] = useState({});

  useEffect(() => {
    fetchMatrix();
  }, []);

  const fetchMatrix = () => {
    callAPI(APIUrl + NetworkConfiguration.MATRIX, "GET")
      .then((res) => {
        setMatrixData(res.data[0]);
      })
      .catch((err) => console.log("error", err));
  };

  const handleUpdateMatrix = () => {
    callAPI(
      APIUrl + NetworkConfiguration.MATRIX,
      "PUT",
      JSON.stringify({
        activeClients: Number(matrixData.activeClients),
        projectsDone: Number(matrixData.projectsDone),
        teamAdvisors: Number(matrixData.teamAdvisors),
        gloriousYears: Number(matrixData.gloriousYears),
      }),
      { "Content-Type": "application/json" }
    )
      .then((res) => {
        successToast(res.message);
        fetchMatrix();
      })
      .catch((err) => console.log(err));
  };

  return (
    <div>
      {/* <Layout> */}
      <div className="breadcrumb__styling">
        <div className="path_styling">
          <h3 className="path__header">Matrix</h3>
          {/* <Breadcrumbs /> */}
        </div>
      </div>
      <div className="middle_container">
        <div className="projects_updation">
          <div className="client_field">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-evenly",
              }}
            >
              <div>
                <InputField
                  onChange={(e) => {
                    setMatrixData({
                      ...matrixData,
                      activeClients: e.target.value,
                    });
                  }}
                  label="Active Clients"
                  type="number"
                  placeholder="Enter Text"
                  style={{ height: "7vh", width: "18vw" }}
                  value={matrixData.activeClients}
                />
              </div>
              <div>
                <InputField
                  onChange={(e) => {
                    setMatrixData({
                      ...matrixData,
                      projectsDone: e.target.value,
                    });
                  }}
                  label="Projects Done"
                  type="number"
                  placeholder="Enter Text"
                  style={{ height: "7vh", width: "18vw" }}
                  value={matrixData.projectsDone}
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-evenly",
              }}
            >
              <div className="client_field">
                <InputField
                  onChange={(e) => {
                    setMatrixData({
                      ...matrixData,
                      teamAdvisors: e.target.value,
                    });
                  }}
                  label="Team Advisors"
                  type="number"
                  placeholder="Enter Text"
                  style={{ height: "7vh", width: "18vw" }}
                  value={matrixData.teamAdvisors}
                />
              </div>
              <div className="client_field">
                <InputField
                  onChange={(e) => {
                    setMatrixData({
                      ...matrixData,
                      gloriousYears: e.target.value,
                    });
                  }}
                  label="Glorious Years"
                  type="number"
                  placeholder="Enter Text"
                  style={{ height: "7vh", width: "18vw" }}
                  value={matrixData.gloriousYears}
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                // alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <Button
                onClick={handleUpdateMatrix}
                text="Update"
                style={{
                  height: "7vh",
                  width: "15vw",
                }}
              />
            </div>
          </div>
        </div>
      </div>
      {/* </Layout> */}
    </div>
  );
};

export default ProjectMetrics;
