import axios from "axios";
import { useState, useEffect } from "react";
import { APIUrl } from "../../../Network/networkConfiguration";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";

const EditGiveaway = () => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [selectedContest, setSelectedContest] = useState(null);
  const [selectedQuiz, setSelectedQuiz] = useState(null);
  const [contests, setContests] = useState([]);
  const [quizzes, setQuizzes] = useState([]);
  const [image, setImage] = useState(null); // For image upload
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    const fetchContests = async () => {
      try {
        const response = await axios.get(`${APIUrl}contest`);

        setContests(response.data.data);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchContests();
  }, []);

  useEffect(() => {
    const fetchContests = async () => {
      try {
        const response = await axios.get(`${APIUrl}quiz`);

        setQuizzes(response.data.data);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchContests();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const data = await axios.get(`${APIUrl}giveaway/${id}`);
      console.log(data.data.data.giveaway, "data");
      setTitle(data.data.data.giveaway.title);
      setDescription(data.data.data.giveaway.description);
      setSelectedContest(data.data.data.giveaway.contest || null);
      setSelectedQuiz(data.data.data.giveaway.quiz || null);
      setImage(data.data.data.giveaway.imageUrl || null); // Preload the image if it exists
    };
    fetchData();
  }, []);

  const handleContestChange = (e) => {
    setSelectedContest(e.target.value);
    setSelectedQuiz(null); // Clear selected quiz if contest is selected
  };

  const handleQuizChange = (e) => {
    setSelectedQuiz(e.target.value);
    setSelectedContest(null); // Clear selected contest if quiz is selected
  };

  const handleImageChange = (e) => {
    setImage(e.target.files[0]); // Set the selected image file for upload
  };

  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append("title", title);
    formData.append("description", description);
    {
      selectedContest && formData.append("contest", selectedContest);
    }
    {
      selectedQuiz && formData.append("quiz", selectedQuiz);
    }
    if (image) {
      formData.append("image", image); // Append image only if selected
    }

    try {
      const response = await axios.put(`${APIUrl}giveaway/${id}`, formData);

      if (response) {
        toast.success("GiveAway Updated successfully");

        navigate("/Component/giveaway/list");
      } else {
        toast.error("Failed to update GiveAway");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Failed to Update Giveaway");
    } // Use formData for submission
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold">Edit Giveaway</h1>
      <div className="mt-4">
        <label className="block mb-2">Title</label>
        <input
          type="text"
          className="border p-2 w-full"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />

        <label className="block mt-4 mb-2">Description</label>
        <textarea
          className="border p-2 w-full"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />

        <label className="block mt-4 mb-2">Image</label>
        <input
          type="file"
          className="border p-2 w-full"
          accept="image/*"
          onChange={handleImageChange}
        />

        <div className="mt-4">
          <label className="block mb-2">Select Contest</label>
          <select
            value={selectedContest || ""}
            onChange={handleContestChange}
            className="border p-2 w-full"
            disabled={!!selectedQuiz}
          >
            <option value="">Select Contest</option>
            {contests?.map((contest) => (
              <option key={contest._id} value={contest._id}>
                {contest.title}
              </option>
            ))}
          </select>
        </div>

        <div className="mt-4">
          <label className="block mb-2">Select Quiz</label>
          <select
            value={selectedQuiz || ""}
            onChange={handleQuizChange}
            className="border p-2 w-full"
            disabled={!!selectedContest}
          >
            <option value="">Select Quiz</option>
            {quizzes?.map((quiz) => (
              <option key={quiz._id} value={quiz._id}>
                {quiz.title}
              </option>
            ))}
          </select>
        </div>

        <button
          onClick={handleSubmit}
          className="mt-4 bg-blue-500 text-white p-2 rounded"
        >
          Update
        </button>
      </div>
    </div>
  );
};

export default EditGiveaway;
