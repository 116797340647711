import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { APIUrl } from "../../../Network/networkConfiguration";
import { toast } from "react-toastify";

const EditQAPage = () => {
  const { id } = useParams();
  const [question, setQuestion] = useState("");
  const [options, setOptions] = useState([{ text: "", isCorrect: false }]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchQA = async () => {
      try {
        const response = await axios.get(`${APIUrl}question/${id}`);
        const { question, options } = response.data;
        setQuestion(question);
        setOptions(options);
      } catch (error) {
        console.error("Error:", error);
      }
    };
    fetchQA();
  }, [id]);

  const addOption = () => {
    setOptions([...options, { text: "", isCorrect: false }]);
  };

  const removeOption = (index) => {
    const newOptions = options.filter((_, i) => i !== index);
    setOptions(newOptions);
  };

  const handleOptionChange = (index, field, value) => {
    const newOptions = [...options];
    newOptions[index][field] = value;
    setOptions(newOptions);
  };

  const handleSubmit = async () => {
    const data = { question, options };
    try {
      await axios.put(`${APIUrl}question/${id}`, data);
      toast.success("Q/A updated successfully");
      navigate("/Component/questions/list");
    } catch (error) {
      console.error("Error:", error);
      toast.error("Failed to update Q/A");
    }
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold">Edit Q/A</h1>
      <div className="mt-4">
        <label className="block mb-2">Question</label>
        <input
          type="text"
          className="border p-2 w-full"
          value={question}
          onChange={(e) => setQuestion(e.target.value)}
        />
      </div>
      <div className="mt-4">
        {options?.map((option, index) => (
          <div key={index} className="flex items-center mb-2">
            <input
              type="text"
              className="border p-2 w-full"
              placeholder={`Option ${index + 1}`}
              value={option.text}
              onChange={(e) =>
                handleOptionChange(index, "text", e.target.value)
              }
            />
            <label className="ml-2">
              <input
                type="checkbox"
                checked={option.isCorrect}
                onChange={(e) =>
                  handleOptionChange(index, "isCorrect", e.target.checked)
                }
              />{" "}
              Correct
            </label>
            <button
              className="ml-2 bg-red-500 text-white p-1 rounded"
              onClick={() => removeOption(index)}
            >
              Remove
            </button>
          </div>
        ))}
        <button
          className="mt-2 bg-blue-500 text-white p-2 rounded"
          onClick={addOption}
        >
          Add Option
        </button>
      </div>
      <button
        className="mt-4 bg-green-500 text-white p-2 rounded"
        onClick={handleSubmit}
      >
        Update
      </button>
    </div>
  );
};

export default EditQAPage;
