import {
  BiSolidDashboard,
  BiLogoMicrosoftTeams,
  BiSolidCategoryAlt,
} from "react-icons/bi";
import { FaClipboardList, FaUserTie, FaUsers } from "react-icons/fa";
import { RiContactsFill, RiGalleryFill } from "react-icons/ri";
import {
  MdAutoGraph,
  MdRateReview,
  MdNotificationsActive,
} from "react-icons/md";
import { GoGraph } from "react-icons/go";
import { PiListChecksBold } from "react-icons/pi";
import { BsFillBagCheckFill } from "react-icons/bs";
import { IoMdCart } from "react-icons/io";
import { SiReactos } from "react-icons/si";
import { AiFillWarning, AiOutlinePicCenter } from "react-icons/ai";
import { TiUserDelete } from "react-icons/ti";

export const SidebarData = [
  {
    label: "Dashboard",
    link: "/",
    icon: <BiSolidDashboard />,
  },
  {
    label: "Users",
    link: "/users",
    icon: <FaUsers />,
  },

  {
    label: "Categories",
    link: "/categories",
    icon: <BiSolidCategoryAlt />,
  },

  {
    label: "Sub-Categories",
    link: "/subcategories",
    icon: <BiSolidCategoryAlt />,
  },

  {
    label: "Product List",
    link: "/productlist",
    icon: <FaClipboardList />,
  },
  {
    label:"Code Mania",
    link:"/CodeMania",
    icon: <FaClipboardList />,


  },

  {
    label: "Testimonials",
    link: "/testimonials",
    icon: <MdRateReview />,
  },

  {
    label: "Team",
    link: "/team",
    icon: <BiLogoMicrosoftTeams />,
  },

  {
    label: "Order History",
    link: "/orderhistory",
    icon: <BsFillBagCheckFill />,
  },

  {
    label: "Technologies",
    link: "/technologies",
    icon: <SiReactos />,
  },

  {
    label: "Clients",
    link: "/clients",
    icon: <FaUserTie />,
  },

  {
    label: "Career Openings",
    link: "/careeropenings",
    icon: <GoGraph />,
  },

  {
    label: "Applied Jobs",
    link: "/appliedjobs",
    icon: <PiListChecksBold />,
  },

  {
    label: "Events",
    link: "/events",
    icon: <RiGalleryFill />,
  },

  {
    label: "Cart",
    link: "/cart",
    icon: <IoMdCart />,
  },

  {
    label: "Banner",
    link: "/banner",
    icon: <AiOutlinePicCenter />,
  },

  // {
  //   label: "Coupon",
  //   link: "/coupon",
  //   icon: <AiOutlinePicCenter />,
  // },

  {
    label: "Warned Users",
    link: "/warnedusers",
    icon: <AiFillWarning />,
  },

  {
    label: "Suspended Users",
    link: "/suspendedusers",
    icon: <TiUserDelete />,
  },

  {
    label: "Notification",
    link: "/notification",
    icon: <MdNotificationsActive />,
  },

  {
    label: "Project Metrics",
    link: "/projectmetrics",
    icon: <MdAutoGraph />,
  },

  {
    label: "Contact Us",
    link: "/contactus",
    icon: <RiContactsFill />,
  },
];

export const OrderStatusList = ["Placed", "Delivered"];
export const DeliveryStatusList = ["Pending", "Completed"];
