import { useState } from "react";
import InputField from "../../library/TextField";
import "./style.css";
import Button from "../../library/Button";
import Dropdown from "../../library/DropDown";

const TeamForm = ({ onSubmit, teamData, setTeamData }) => {
  const [error, setError] = useState({
    name: "",
    role: "",
    typeType: "",
    image: "",
  });

  const onClickSubmit = () => {
    if (validate()) {
      onSubmit(teamData);
    }
  };

  const setTeamName = (e) => {
    setTeamData({ ...teamData, name: e.target.value });
    setError({ ...error, name: "" });
  };

  const setRole = (e) => {
    setTeamData({ ...teamData, role: e.target.value });
    setError({ ...error, role: "" });
  };

  const setTeamType = (e) => {
    setTeamData({ ...teamData, teamType: e.target.value });
    setError({ ...error, teamType: "" });
  };

  const setImage = (e) => {
    setTeamData({ ...teamData, image: e.target.files[0] });
    setError({ ...error, image: "" });
  };

  const validate = () => {
    let result = true;
    if (!teamData.name) {
      setError({ ...error, name: "Name is required" });
      result = false;
    } else if (!teamData.role) {
      setError({ ...error, role: "Role is required" });
      result = false;
    } else if (!teamData.teamType) {
      setError({ ...error, teamType: "Team Type is required" });
      result = false;
    } else if (!teamData.image) {
      setError({ ...error, image: "Image is required" });
      result = false;
    }
    return result;
  };

  return (
    <div className="product_form_container">
      <h1 className="form__heading">Add Team</h1>

      <div className="form__data">
        <InputField
          value={teamData.name}
          onChange={setTeamName}
          label="Member Name"
          placeholder="Enter Name"
          // style={{ marginLeft: "20px", height: "6vh", width: "18vw" }}
          error={error.name}
        />
      </div>

      <div className="form__data">
        <InputField
          value={teamData.role}
          onChange={setRole}
          label="Role"
          placeholder="Enter Role"
          // style={{ marginLeft: "20px", height: "6vh", width: "18vw" }}
          error={error.role}
        />
      </div>

      <div className="form__data">
        <Dropdown
          selectedValue={teamData.teamType}
          onChange={setTeamType}
          label="Team Type"
          options={[
            {
              name: "Any",
              value: "",
            },
            {
              name: "Employee",
              value: "employee",
            },
            {
              name: "Leadership",
              value: "leadership",
            },
            {
              name: "Professional",
              value: "professional",
            },
          ]}
          placeholder="Enter type"
          // style={{ marginLeft: "20px", height: "6vh", width: "18vw" }}
          error={error.teamType}
        />
      </div>

      <div className="form__data">
        <InputField
          onChange={setImage}
          label="Upload Image"
          type="file"
          accept="image/*"
          style={{ paddingTop: "10px" }}
          error={error.image}
        />
      </div>
      <Button
        onClick={onClickSubmit}
        text="Add Team"
        style={{
          width: "12vw",
          height: "7vh",
          borderRadius: "5px",
          margin: "5px auto",
        }}
      />
    </div>
  );
};

export default TeamForm;
