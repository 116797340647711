import Breadcrumbs from "../../library/Breadcrumbs";
import Button from "../../library/Button";
import Layout from "../../component/Layout";
import { useEffect, useMemo, useState } from "react";
import PopModal from "../../component/PopUpModal";
import TechnologyForm from "../../forms_components/TechnologyForm";
import "./style.css";
import { callAPI } from "../../Network/networkConnection";
import {
  APIUrl,
  NetworkConfiguration,
} from "../../Network/networkConfiguration";
import MyCard from "../../component/MyCard/Card";
import AlertPopUp from "../../component/AlertPopUp";
import { successToast } from "../../helper/toast";
import MaterialReactTable from "material-react-table";
import { mkConfig, generateCsv, download } from "export-to-csv";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { Edit as EditIcon, Delete as DeleteIcon } from "@mui/icons-material";
import { Box, IconButton, Button as MaterialButton } from "@mui/material";
import moment from "moment";

const Technologies = () => {
  const [showUserForm, setShowUserForm] = useState(false);
  const [showTechnologies, setshowTechnologies] = useState([]);
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [technologyId, setTechnologyId] = useState("");
  const [technologyData, setTechnologyData] = useState({
    name: "",
  });
  const [isEdited, setIsEdited] = useState(false);

  const onSubmit = (data) => {
    console.log(data, "data");
    callAPI(
      APIUrl + NetworkConfiguration.TECHNOLOGIES,
      "POST",
      JSON.stringify(data),
      { "Content-Type": "application/json" }
    )
      .then((res) => {
        setTechnologyData({
          name: "",
        });
        successToast(res.message);
        setShowUserForm(false);
        fetchTechnology();
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    fetchTechnology();
  }, []);

  const fetchTechnology = () => {
    callAPI(APIUrl + NetworkConfiguration.TECHNOLOGIES, "GET")
      .then((res) => {
        console.log(res.data, "12345678");
        setshowTechnologies(res.data);
      })
      .catch((err) => {
        if (err.status === 404) {
          setshowTechnologies([]);
        }
        console.log("error", err);
      });
  };

  const handleDeleteRow = (id) => {
    callAPI(APIUrl + NetworkConfiguration.TECHNOLOGY + "/" + id, "DELETE")
      .then((res) => {
        successToast(res.message);
        fetchTechnology();
        setTechnologyId("");
        setDeleteAlert(false);
      })
      .catch((err) => console.log(err));
  };

  const onClickDelete = (id) => {
    setDeleteAlert(true);
    setTechnologyId(id);
  };

  const onUpdate = (data) => {
    console.log(data, "-----------");
    // setTechnologyData["id"] = setTechnologyId;
    console.log(technologyData, "data");
    callAPI(
      APIUrl + NetworkConfiguration.TECHNOLOGY + "/" + data._id,
      "PUT",
      JSON.stringify({ name: data.name }),
      { "Content-Type": "application/json" }
    )
      .then((res) => {
        setTechnologyData({
          name: "",
        });
        successToast(res.message);
        setIsEdited(false);
        setShowUserForm(false);
        fetchTechnology();
      })
      .catch((err) => console.log(err));
  };

  const onClickEdit = (id) => {
    setIsEdited(true);
    setTechnologyId(id);
    setShowUserForm(true);
  };

  const handleSaveRow = async ({ exitEditingMode, row, values }) => {
    callAPI(
      APIUrl + NetworkConfiguration.TECHNOLOGY + "/" + row.original._id,
      "PUT",
      JSON.stringify({ name: values.name }),
      { "Content-Type": "application/json" }
    )
      .then((res) => {
        setTechnologyData({
          name: "",
        });
        successToast(res.message);
        setIsEdited(false);
        setShowUserForm(false);
        fetchTechnology();
        return exitEditingMode();
      })
      .catch((err) => console.log(err));
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "name", //simple recommended way to define a column
        header: "Technology",
        Header: <i style={{ color: "#31435f" }}>Technology</i>,
      },
      {
        // accessorFn: (originalRow) => originalRow.email, //alternate way
        accessorKey: "createdAt", //id required if you use accessorFn instead of accessorKey
        header: "Created At",
        Header: <i style={{ color: "#31435f" }}>Created At</i>,
        Cell: ({ cell }) =>
          moment(cell.row.original.createdAt).format("MM/DD/YYYY , LT"),
      },
    ],
    []
  );

  const csvConfig = mkConfig({
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: true,
    // headers: columns.map((c) => c.header),
  });

  const handleExportRows = (rows) => {
    const csv = generateCsv(csvConfig)(rows.map((row) => row.original));
    download(csvConfig)(csv);
  };

  const handleExportData = () => {
    const csv = generateCsv(csvConfig)(showTechnologies);
    download(csvConfig)(csv);
  };

  return (
    <div>
      {/* <Layout> */}
      <div className="middle_container_top_alignment">
        <div className="breadcrumb__styling">
          <div className="path_styling">
            <h3 className="path__header">Technologies</h3>
            {/* <Breadcrumbs /> */}
          </div>
        </div>

        <AlertPopUp
          show={deleteAlert}
          header="Delete Technology"
          description="Do you want to delete this technology?"
          submitText="Yes"
          cancelText="No"
          onCancelClick={() => setDeleteAlert(false)}
          onSubmitClick={() => handleDeleteRow(technologyId)}
        ></AlertPopUp>

        <PopModal
          show={showUserForm}
          onRequestClose={() => setShowUserForm(false)}
        >
          <TechnologyForm
            onSubmit={onSubmit}
            onUpdate={onUpdate}
            data={technologyData}
            setData={setTechnologyData}
            isEdited={isEdited}
          />
        </PopModal>

        <Button
          text="Add Technology"
          onClick={() => {
            setShowUserForm(true);
            setIsEdited(false);
          }}
          style={{ height: "6vh", width: "10vw", margin: "20px" }}
        />
      </div>
      {/* <div className="category__card" style={{ padding: "2rem" }}>
        {showTechnologies?.length > 0 &&
          showTechnologies.map((data) => {
            return (
              <MyCard
                title={data.name}
                onClickDelete={() => onClickDelete(data._id)}
                onClickEdit={() => {
                  console.log(data, "data");
                  setTechnologyData(data);
                  onClickEdit(data._id);
                }}
              />
            );
          })}
      </div> */}
      <div className="table_data">
        <MaterialReactTable
          columns={columns}
          data={showTechnologies}
          enableRowNumbers={true}
          enableColumnOrdering
          enableGlobalFilter={false} //turn off a feature
          enableRowActions
          enableRowSelection
          onEditingRowSave={handleSaveRow}
          renderRowActions={({ row, table }) => (
            <Box sx={{ display: "flex", flexWrap: "nowrap" }}>
              <IconButton
                style={{ color: "#1468af", marginLeft: "-6px" }}
                onClick={() => {
                  table.setEditingRow(row);
                }}
              >
                <EditIcon />
              </IconButton>
              <IconButton color="error">
                <DeleteIcon onClick={() => onClickDelete(row.original._id)} />
              </IconButton>
            </Box>
          )}
          renderTopToolbarCustomActions={({ table }) => (
            <Box
              sx={{
                display: "flex",
                gap: "1rem",
                p: "0.5rem",
                flexWrap: "wrap",
              }}
            >
              <MaterialButton
                color="primary"
                //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                onClick={handleExportData}
                startIcon={<FileDownloadIcon />}
                variant="contained"
              >
                Export All Data
              </MaterialButton>
              <MaterialButton
                disabled={table.getPrePaginationRowModel().rows.length === 0}
                //export all rows, including from the next page, (still respects filtering and sorting)
                onClick={() =>
                  handleExportRows(table.getPrePaginationRowModel().rows)
                }
                startIcon={<FileDownloadIcon />}
                variant="contained"
              >
                Export All Rows
              </MaterialButton>
              <MaterialButton
                disabled={table.getRowModel().rows.length === 0}
                //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
                onClick={() => handleExportRows(table.getRowModel().rows)}
                startIcon={<FileDownloadIcon />}
                variant="contained"
              >
                Export Page Rows
              </MaterialButton>
              <MaterialButton
                disabled={
                  !table.getIsSomeRowsSelected() &&
                  !table.getIsAllRowsSelected()
                }
                //only export selected rows
                onClick={() =>
                  handleExportRows(table.getSelectedRowModel().rows)
                }
                startIcon={<FileDownloadIcon />}
                variant="contained"
              >
                Export Selected Rows
              </MaterialButton>
            </Box>
          )}
        />
      </div>
      {/* </Layout> */}
    </div>
  );
};

export default Technologies;
