import { callAPI } from "../Network/networkConnection";
import { APIUrl, NetworkConfiguration } from "../Network/networkConfiguration";

const imageUpload = (image, model, modelId) => {
  let data = new FormData();
  data.append("image", image);
  data.append("model", model);
  return new Promise((resolve, reject) => {
    callAPI(APIUrl + NetworkConfiguration.IMAGE + `/${modelId}`, "POST", data)
      .then(async (res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const multiImageUpload = (images, model, modelId) => {
  let data = new FormData();
  images.forEach((image) => {
    data.append("images", image);
  });
  data.append("model", model);
  return new Promise((resolve, reject) => {
    callAPI(APIUrl + NetworkConfiguration.IMAGES + `/${modelId}`, "POST", data)
      .then(async (res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const ImageService = {
  imageUpload,
  multiImageUpload,
};
