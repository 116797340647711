import { useState } from "react";
import InputField from "../../library/TextField";
import Dropdown from "../../library/DropDown";
import Button from "../../library/Button";
import "./style.css";

const EditTeam = ({ onUpdate, data, setData }) => {
  console.log(data);
  const [teamData, setTeamData] = useState({
    name: "",
    role: "",
    teamType: "",
    image: "",
  });

  return (
    <div className="product_form_container">
      <h1 className="form__heading">Update Team</h1>

      <div className="form__data">
        <InputField
          value={data.name}
          onChange={(e) => setData({ ...data, name: e.target.value })}
          label="Member Name"
          placeholder="Enter Name"
        />
      </div>

      <div className="form__data">
        <InputField
          value={data.role}
          onChange={(e) =>
            setData({
              ...data,
              role: e.target.value,
            })
          }
          label="Role"
          placeholder="Enter Role"
        />
      </div>

      <div className="form__data">
        <Dropdown
          value={data.teamType}
          onChange={(e) => setData({ ...data, teamType: e.target.value })}
          label="Team Type"
          options={[
            {
              name: "Any",
              value: "",
            },
            {
              name: "Employee",
              value: "employee",
            },
            {
              name: "Leadership",
              value: "leadership",
            },
            {
              name: "Professional",
              value: "professional",
            },
          ]}
          placeholder="Enter type"
        />
      </div>

      <div className="form__data">
        <InputField
          value={data.image}
          onChange={(e) =>
            setTeamData({
              ...data,
              image: e.target.files[0],
            })
          }
          accept="image/*"
          label="Upload Image"
          type="file"
          style={{ paddingTop: "10px" }}
        />
      </div>

      <Button
        onClick={() => onUpdate(data._id)}
        text="Save Changes"
        style={{
          width: "12vw",
          height: "7vh",
          borderRadius: "5px",
          margin: "5px autos",
        }}
      />
    </div>
  );
};

export default EditTeam;
