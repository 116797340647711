import { useEffect, useState } from "react";
import Layout from "../../component/Layout";
import MyCard from "../../component/MyCard/Card";
import PopModal from "../../component/PopUpModal";
import BannerForm from "../../forms_components/BannerForm";
import Breadcrumbs from "../../library/Breadcrumbs";
import Button from "../../library/Button";
import "./style.css";
import { callAPI } from "../../Network/networkConnection";
import {
  APIUrl,
  NetworkConfiguration,
} from "../../Network/networkConfiguration";
import { successToast } from "../../helper/toast";
import AlertPopUp from "../../component/AlertPopUp";
import { ImageService } from "../../service/ImageService";

const Banner = () => {
  const [showUserForm, setShowUserForm] = useState(false);
  const [showBanner, setShowBanner] = useState([]);
  const [bannerId, setBannerId] = useState("");
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [bannerImage, setBannerImage] = useState("");
  const [isEdited, setIsEdited] = useState(false);
  const [bannerData, setBannerData] = useState({
    bannerName: "",
    imageUrl: "",
  });

  // const [isEdited, setIsEdited] = useState(false);

  useEffect(() => {
    fetchBanner();
  }, []);

  const fetchBanner = () => {
    callAPI(APIUrl + NetworkConfiguration.BANNER, "GET")
      .then((res) => {
        setShowBanner(res.data);
        // setBannerData({ categoryName: "", categoryImage: null });
      })
      .catch((err) => {
        if (err.status === 404) {
          setShowBanner([]);
        }
        console.log("error", err);
      });
  };

  const handleDeleteRow = (_id) => {
    callAPI(
      APIUrl + NetworkConfiguration.BANNER + "/" + _id,
      "DELETE",
      JSON.stringify({
        bannerId: _id,
        // imageUrl: iconUrl,
      }),
      { "Content-Type": "application/json" }
    )
      .then((res) => {
        successToast(res.message);
        fetchBanner();
        setDeleteAlert(false);
        setBannerId("");
        // setSubCategoryImage("");
      })
      .catch((err) => console.log("error", err));
  };

  const onClickDelete = (id) => {
    setDeleteAlert(true);
    setBannerId(id);
    // setSubCategoryImage(image);
  };

  const onSubmit = (bannerData) => {
    // console.log("----", openingData);
    callAPI(
      APIUrl + NetworkConfiguration.BANNER,
      "POST",
      JSON.stringify(bannerData),
      { "Content-Type": "application/json" }
    )
      .then((res) => {
        if (bannerData?.imageUrl) {
          console.log("abcd", bannerData?.imageUrl);
          ImageService.imageUpload(
            bannerData?.imageUrl,
            "banner",
            res?.response?._id
          )
            .then(() => {
              setBannerData({
                bannerName: "",
                imageUrl: "",
              });
              successToast(res.message);
              setShowUserForm(false);
              fetchBanner();
            })
            .catch((err) => console.log(err));
        }
      })
      .catch((err) => console.log(err));
  };

  const onUpdate = () => {
    callAPI(
      APIUrl + NetworkConfiguration.BANNER + `/${bannerId}`,
      "PUT",
      JSON.stringify(bannerData),
      { "Content-Type": "application/json" }
    )
      .then((res) => {
        if (bannerData?.imageUrl) {
          ImageService.imageUpload(
            bannerData?.imageUrl,
            "banner",
            res?.result?._id
          )
            .then(() => {
              setBannerData({
                bannerName: "",
                imageUrl: "",
              });
              successToast(res.message);
              setIsEdited(false);
              setShowUserForm(false);
              fetchBanner();
            })
            .catch((err) => console.log(err));
        }
      })
      .catch((err) => console.log(err));
  };

  const onClickEdit = (id, data) => {
    setIsEdited(true);
    setBannerId(id);
    setBannerData(data);
    setShowUserForm(true);
  };

  return (
    <div>
      {/* <Layout> */}
      <div className="middle_container_top_alignment">
        <div className="breadcrumb__styling">
          <div className="path_styling">
            <h3 className="path__header">Banner</h3>
            {/* <Breadcrumbs /> */}
          </div>
        </div>
        <Button
          text="Add Banner"
          onClick={() => setShowUserForm(true)}
          style={{ height: "6vh", width: "10vw", margin: "20px" }}
        />
      </div>

      <AlertPopUp
        show={deleteAlert}
        header="Delete Banner"
        description="Do you want to delete this Banner?"
        submitText="Yes"
        cancelText="No"
        onCancelClick={() => setDeleteAlert(false)}
        onSubmitClick={() => handleDeleteRow(bannerId)}
      ></AlertPopUp>
      <PopModal
        show={showUserForm}
        onRequestClose={() => setShowUserForm(false)}
      >
        <BannerForm
          onSubmit={onSubmit}
          onUpdate={onUpdate}
          data={bannerData}
          setData={setBannerData}
          isEdited={isEdited}
        />
      </PopModal>

      <div style={{ padding: "2rem" }} className="category__card">
        {showBanner?.length > 0 &&
          showBanner.map((data) => {
            return (
              <MyCard
                title={data.bannerName}
                image={data.imageUrl}
                onClickEdit={() => onClickEdit(data._id, data)}
                onClickDelete={() => onClickDelete(data._id)}
              />
            );
          })}
      </div>

      {/* <MyCard title={bannerData?.name} image={bannerData?.imageUrl} /> */}
      {/* // onClickDelete={() => onClickDelete(data._id)}/> */}
      {/* </Layout> */}
    </div>
  );
};

export default Banner;
