import React, { useEffect } from "react";
import Sidebar from "../Sidebar";
import Navbar from "../Navbar";
import "./style.css";
import { StorageConstant } from "../../constant/StorageConstant";
import { useNavigate } from "react-router-dom";

const Layout = ({ children }) => {
  const navigate = useNavigate();
  useEffect(() => {
    async function getLoginHeader() {
      const loginHeader = await localStorage.getItem(
        StorageConstant.LOGIN_HEADER
      );
      if (!loginHeader) {
        navigate("/login", { replace: true });
      }
    }
    getLoginHeader();
  }, []);
  return (
    <div className="layout_row">
      <Sidebar />
      <div>
        <Navbar />
        <div className="layout_childrens">{children}</div>
      </div>
    </div>
  );
};

export default Layout;
