import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaEye } from "react-icons/fa";
import axios from "axios";
import { APIUrl } from "../../../Network/networkConfiguration";
import { toast } from "react-toastify";

const GiveawayList = () => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showImageModal, setShowImageModal] = useState(false);
  const [showDescriptionModal, setShowDescriptionModal] = useState(false);
  const [giveaways, setGiveaways] = useState([]);
  const [selectedGiveaway, setSelectedGiveaway] = useState(null);
  const [selectedImageUrl, setSelectedImageUrl] = useState("");
  const [selectedDescription, setSelectedDescription] = useState("");
  const navigate = useNavigate();

  const handleDeleteClick = (id) => {
    setSelectedGiveaway(id);
    setShowDeleteModal(true);
  };

  const handleViewImageClick = (imgUrl) => {
    setSelectedImageUrl(imgUrl);
    setShowImageModal(true);
  };

  const handleDescriptionClick = (description) => {
    setSelectedDescription(description);
    setShowDescriptionModal(true);
  };

  useEffect(() => {
    const fetchGiveAway = async () => {
      try {
        const response = await axios.get(`${APIUrl}giveaway`);
        setGiveaways(response.data.data);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchGiveAway();
  }, []);

  const confirmDelete = () => {
    onDelete(selectedGiveaway);
    setShowDeleteModal(false);
  };

  const onDelete = async (id) => {
    try {
      const response = await axios.delete(`${APIUrl}giveaway/${id}`);
      if (response) {
        toast.success(`GiveAway deleted succesfully`);
        try {
          const response = await axios.get(`${APIUrl}giveaway`);
          setGiveaways(response.data.data);
        } catch (error) {
          console.error("Error:", error);
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const onEdit = (id) => {
    navigate(`/Component/giveaway/edit/${id}`);
  };

  const handleredirect = () => {
    navigate(`/Component/giveaway/add`);
  };

  return (
    <div className="container relative mx-auto p-4">
      <h1 className="text-2xl font-bold">Giveaway List</h1>
      <div className="absolute right-4 top-5">
        <button onClick={handleredirect}>
          <h3 className="p-2 bg-blue-500 rounded-xl px-5 text-white">Add</h3>
        </button>
      </div>
      <table className="min-w-full table-auto mt-4">
        <thead>
          <tr>
            <th className="px-4 py-2">Title</th>
            <th className="px-4 py-2">Description</th>
            <th className="px-4 py-2">Image</th>
            <th className="px-4 py-2">Actions</th>
          </tr>
        </thead>
        <tbody>
          {giveaways?.map((giveaway) => (
            <tr key={giveaway._id}>
              <td className="border px-4 py-2">{giveaway?.title}</td>
              <td className="border px-4 py-2">
                {giveaway?.description.length > 20
                  ? `${giveaway.description.substring(0, 20)}...`
                  : giveaway?.description}
                {giveaway?.description.length > 20 && (
                  <button
                    className="text-blue-500 underline ml-2"
                    onClick={() =>
                      handleDescriptionClick(giveaway?.description)
                    }
                  >
                    View
                  </button>
                )}
              </td>
              <td className="border px-4 py-2 text-center">
                {giveaway?.imageUrl && (
                  <button
                    onClick={() => handleViewImageClick(giveaway?.imageUrl)}
                    className="text-blue-500"
                  >
                    <FaEye />
                  </button>
                )}
              </td>
              <td className="border px-4 py-2">
                <button
                  onClick={() => onEdit(giveaway?._id)}
                  className="bg-yellow-500 text-white px-4 py-2 mr-2 rounded"
                >
                  Edit
                </button>
                <button
                  onClick={() => handleDeleteClick(giveaway?._id)}
                  className="bg-red-500 text-white px-4 py-2 rounded"
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Delete Confirmation Modal */}
      {showDeleteModal && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center">
          <div className="bg-white p-4 rounded shadow-lg">
            <h2 className="text-xl">Do you really want to delete?</h2>
            <div className="mt-4">
              <button
                onClick={confirmDelete}
                className="bg-red-500 text-white px-4 py-2 mr-2 rounded"
              >
                Yes
              </button>
              <button
                onClick={() => setShowDeleteModal(false)}
                className="bg-gray-500 text-white px-4 py-2 rounded"
              >
                No
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Image Modal */}
      {showImageModal && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center">
          <div className="bg-white p-4 rounded shadow-lg">
            <img
              src={selectedImageUrl}
              alt="Giveaway"
              className="w-96 h-96 object-cover"
            />
            <button
              className="mt-4 bg-gray-500 text-white px-4 py-2 rounded"
              onClick={() => setShowImageModal(false)}
            >
              Close
            </button>
          </div>
        </div>
      )}

      {/* Description Modal */}
      {showDescriptionModal && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center">
          <div className="bg-white p-4 rounded shadow-lg max-h-[500px] overflow-y-auto">
            <h2 className="text-xl">Full Description</h2>
            <p className="mt-4">{selectedDescription}</p>
            <button
              className="mt-4 bg-gray-500 text-white px-4 py-2 rounded"
              onClick={() => setShowDescriptionModal(false)}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default GiveawayList;
