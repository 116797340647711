import { useEffect, useState } from "react";
import Button from "../../library/Button";
import Breadcrumbs from "../../library/Breadcrumbs";
import Layout from "../../component/Layout";
import Opening from "../../forms_components/OpeningForm";
import PopModal from "../../component/PopUpModal";
import "./style.css";
import { callAPI } from "../../Network/networkConnection";
import {
  APIUrl,
  NetworkConfiguration,
} from "../../Network/networkConfiguration";
import { ImageService } from "../../service/ImageService";
import MyCard from "../../component/MyCard/Card";
import AlertPopUp from "../../component/AlertPopUp";
import { FiDownload } from "react-icons/fi";
import { successToast } from "../../helper/toast";

const CareerOpenings = () => {
  const [showUserForm, setShowUserForm] = useState(false);
  const [showOpenings, setShowOpenings] = useState([]);
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [openingId, setOpeningId] = useState("");
  const [isEdited, setIsEdited] = useState(false);
  const [openingData, setOpeningData] = useState({
    name: "",
    imageUrl: "",
  });

  const onSubmit = (openingData) => {
    // console.log("----", openingData);
    callAPI(
      APIUrl + NetworkConfiguration.OPENING,
      "POST",
      JSON.stringify(openingData),
      { "Content-Type": "application/json" }
    )
      .then((res) => {
        if (openingData?.imageUrl) {
          ImageService.imageUpload(
            openingData?.imageUrl,
            "openings",
            res?.response?._id
          )
            .then(() => {
              setOpeningData({
                name: "",
                imageUrl: "",
              });

              successToast(res.message);
              setShowUserForm(false);
              setOpeningData({
                name: "",
                imageUrl: "",
              });
              fetchOpenings();
            })
            .catch((err) => console.log(err));
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    fetchOpenings();
  }, []);

  useEffect(() => {
    console.log("Show openings...", showOpenings);
  }, [showOpenings]);

  const fetchOpenings = () => {
    callAPI(APIUrl + NetworkConfiguration.OPENINGS, "GET")
      .then((res) => {
        setShowOpenings(res.data);
        // setShowUserForm(false);
      })
      .catch((err) => {
        if (err.status === 404) {
          setShowOpenings([]);
        }
        console.log("opening error", err);
      });
  };

  const handleDeleteRow = (_id) => {
    callAPI(APIUrl + NetworkConfiguration.OPENING + `/${_id}`, "DELETE")
      .then((res) => {
        console.log(res, "Deleted");
        successToast(res.message);
        fetchOpenings();
        setDeleteAlert(false);
        setOpeningId("");
      })
      .catch((err) => console.log("error", err));
  };

  const onClickDelete = (id) => {
    setDeleteAlert(true);
    setOpeningId(id);
  };

  const onUpdate = (id) => {
    callAPI(
      APIUrl + NetworkConfiguration.OPENING + `/${openingId}`,
      "PUT",
      JSON.stringify(openingData),
      { "Content-Type": "application/json" }
    )
      .then((res) => {
        successToast(res.message);
        setIsEdited(false);
        setShowUserForm(false);
        fetchOpenings();
        if (openingData?.image) {
          ImageService.imageUpload(
            openingData?.image,
            "opening",
            res?.data?._id
          )
            .then(() => {
              setOpeningData({
                name: "",
                imageUrl: "",
              });
              fetchOpenings();
            })
            .catch((err) => console.log(err));
        }
      })
      .catch((err) => console.log(err));
  };

  const onClickEdit = (id, data) => {
    setIsEdited(true);
    setOpeningId(id);
    setOpeningData(data);
    setShowUserForm(true);
  };

  return (
    <div>
      {/* <Layout> */}
      <div className="middle_container_top_alignment">
        <div className="breadcrumb__styling">
          <div className="path_styling">
            <h3 className="path__header">Career Openings</h3>
            {/* <Breadcrumbs /> */}
          </div>
        </div>

        <AlertPopUp
          show={deleteAlert}
          header="Delete Opening"
          description="Do you want to delete this opening?"
          submitText="Yes"
          cancelText="No"
          onCancelClick={() => setDeleteAlert(false)}
          onSubmitClick={() => handleDeleteRow(openingId)}
        ></AlertPopUp>

        <PopModal
          show={showUserForm}
          onRequestClose={() => setShowUserForm(false)}
        >
          <Opening
            onSubmit={onSubmit}
            onUpdate={onUpdate}
            data={openingData}
            setData={setOpeningData}
            isEdited={isEdited}
          />
        </PopModal>

        <Button
          text="Add Job Opening"
          onClick={() => {
            setIsEdited(false);
            setOpeningData({
              name: "",
              imageUrl: "",
            });
            setShowUserForm(true);
          }}
          style={{ height: "6vh", width: "12vw", margin: "20px" }}
        />
      </div>
      <div className="category__card">
        {showOpenings?.length > 0 &&
          showOpenings.map((data) => {
            console.log("opening data ", data);
            return (
              <MyCard
                title={data?.name}
                icon={
                  data?.imageUrl && (
                    <FiDownload
                      style={{ width: "30px", height: "auto" }}
                      onClick={() => window.open(data?.imageUrl, "_blank")}
                    />
                  )
                }
                onClickDelete={() => onClickDelete(data._id)}
                onClickEdit={() => onClickEdit(data._id, data)}
              />
            );
          })}
      </div>
      {/* </Layout> */}
    </div>
  );
};

export default CareerOpenings;
