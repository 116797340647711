import { useState } from "react";
import Button from "../../library/Button";
import InputField from "../../library/TextField";
import "./style.css";
import TextArea from "../../library/TextArea";
import { callAPI } from "../../Network/networkConnection";
import {
  APIUrl,
  NetworkConfiguration,
} from "../../Network/networkConfiguration";
import { errorToast, successToast } from "../../helper/toast";

const NotificationForm = ({ onSubmit, userDetails }) => {
  const [notificationFormData, setNotificationFormData] = useState({
    title: "",
    body: "",
  });

  const [error, setError] = useState({
    title: "",
    body: "",
  });

  const clearFormValues = () => {
    setNotificationFormData({ title: "", body: "" });
  };

  const onClickSendNotification = () => {
    const notificationPayload = {
      title: notificationFormData?.title,
      body: notificationFormData?.body,
      registrationTokens: userDetails?.registrationTokens,
      userIds: userDetails?.userIds,
    };
    if (validateNotification()) {
      callAPI(
        APIUrl + NetworkConfiguration.SENDNOTIFICATION,
        "POST",
        JSON.stringify(notificationPayload),
        {
          "Content-Type": "application/json",
        }
      )
        .then((res) => {
          successToast("Notification sent successfully");

          clearFormValues();
          onSubmit();
        })
        .catch((err) => {
          errorToast(err.message);
          console.log("error: ", err);
        });
    }
  };

  const validateNotification = () => {
    let result = true;
    if (!notificationFormData.title) {
      setError({ ...error, title: "Title is required" });
      result = false;
    } else if (!notificationFormData.body) {
      setError({ ...error, body: "Body is required" });
      result = false;
    }

    return result;
  };

  const setTitle = (e) => {
    setNotificationFormData({
      ...notificationFormData,
      title: e.target.value,
    });
    setError({ ...error, title: "" });
  };

  const setBody = (e) => {
    setNotificationFormData({
      ...notificationFormData,
      body: e.target.value,
    });
    setError({ ...error, body: "" });
  };

  return (
    <div className="product_form_container">
      <h1 className="form__heading">Send Notification</h1>
      <br />

      <div className="form__data">
        <InputField
          onChange={setTitle}
          value={notificationFormData.title}
          error={error.title}
          label="Title"
        />
      </div>
      <br />

      <div className="form__data">
        <TextArea
          onChange={setBody}
          value={notificationFormData.body}
          error={error.body}
          label="Body"
          style={{ height: "15vh" }}
        />
      </div>

      <Button
        onClick={onClickSendNotification}
        text="Send Notification"
        style={{
          width: "12vw",
          height: "7vh",
          borderRadius: "5px",
          marginLeft: "9vw",
          margin: "15px auto",
        }}
      />
    </div>
  );
};

export default NotificationForm;
