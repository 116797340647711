import Layout from "../../component/Layout";
import { Edit as EditIcon, Delete as DeleteIcon } from "@mui/icons-material";
import { Box, Button as MaterialButton } from "@mui/material";
import MaterialReactTable from "material-react-table";
import moment from "moment";
import { ImImages } from "react-icons/im";
import { MdHistory } from "react-icons/md";
import { useEffect, useMemo, useState } from "react";

import "./style.css";
import { callAPI } from "../../Network/networkConnection";
import {
  APIUrl,
  NetworkConfiguration,
} from "../../Network/networkConfiguration";
import PopModal from "../../component/PopUpModal";
import { mkConfig, generateCsv, download } from "export-to-csv";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

const SuspendedUsers = () => {
  const [suspendedUsersData, setSuspendedUsersData] = useState([]);
  //   const [showUserImage, setShowUserImage] = useState(false);
  //   const [userImage, setUserImage] = useState("");
  const [showOldNumber, setShowOldNumber] = useState(false);
  const [showOldMail, setShowOldMail] = useState(false);
  const [oldNumbers, setOldNumbers] = useState([]);
  const [oldEmail, setOldEmail] = useState([]);

  useEffect(() => {
    fetchWarnedUsers();
  }, []);

  const fetchWarnedUsers = () => {
    callAPI(APIUrl + NetworkConfiguration.SUSPENDED, "GET")
      .then((res) => {
        setSuspendedUsersData(res.suspendedU);
      })
      .catch((err) => console.log(err, "error"));
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "userIds.firstName",
        header: "First Name",
        Header: <i style={{ color: "#31435f" }}>First Name</i>,
      },
      {
        accessorKey: "userIds.lastName",
        header: "Last Name",
        Header: <i style={{ color: "#31435f" }}>Last Name</i>,
      },
      //   {
      //     accessorKey: "userIds.dob",
      //     header: "DOB",
      //     Header: <i style={{ color: "#31435f" }}>DOB</i>,
      //   },
      {
        accessorKey: "userIds.phone",
        header: "Mobile Number",
        Header: <i style={{ color: "#31435f" }}>Mobile Number</i>,
        Cell: ({ cell }) => {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "5px",
              }}
            >
              {cell.row.original.userIds.phone}
              <MdHistory
                onClick={() => {
                  setShowOldNumber(true);
                  setOldNumbers(cell.row.original.userIds.oldPhone);
                }}
                style={{
                  width: "20px",
                  height: "auto",
                  cursor: "pointer",
                }}
              />
            </div>
          );
        },
      },
      {
        accessorKey: "userIds.email",
        header: "E-mail",
        Header: <i style={{ color: "#31435f" }}>E-mail</i>,
        Cell: ({ cell }) => {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "5px",
              }}
            >
              {cell.row.original.userIds.email}
              {cell.row.original.userIds.email ? (
                <MdHistory
                  onClick={() => {
                    setShowOldMail(true);
                    console.log("Old email: ");
                    setOldEmail(cell.row.original.userIds.oldEmail);
                  }}
                  style={{
                    width: "20px",
                    height: "auto",
                    cursor: "pointer",
                  }}
                />
              ) : null}
            </div>
          );
        },
      },
      //   {
      //     accessorKey: "gender",
      //     header: "Gender",
      //     Header: <i style={{ color: "#31435f" }}>Gender</i>,
      //   },
      {
        accessorKey: "userIds.address.state",
        header: "State",
        Header: <i style={{ color: "#31435f" }}>State</i>,
      },
      {
        accessorKey: "userIds.address.city",
        header: "City",
        Header: <i style={{ color: "#31435f" }}>City</i>,
      },
      {
        accessorKey: "userIds.address.zip_code",
        header: "Zip Code",
        Header: <i style={{ color: "#31435f" }}>Zip_code</i>,
      },
      {
        accessorKey: "userIds.companyName",
        header: "Company Name",
        Header: <i style={{ color: "#31435f" }}>Company Name</i>,
      },
      {
        accessorKey: "userIds.gstNumber",
        header: "GST Number",
        Header: <i style={{ color: "#31435f" }}>GST Number</i>,
      },

      //   {
      //     accessorKey: "imageUrl",
      //     header: "Image",
      //     Header: <i style={{ color: "#31435f" }}>Image</i>,
      //     Cell: ({ cell }) => {
      //       return (
      //         cell.row.original.imageUrl && (
      //           <ImImages
      //             show={showUserImage}
      //             onRequestClose={() => setShowUserImage(false)}
      //             className="eye_icon"
      //             onClick={() => {
      //               setShowUserImage(true);
      //               setUserImage(cell.row.original.imageUrl);
      //             }}
      //           />
      //         )
      //       );
      //     },
      //   },
      {
        accessorKey: "createdAt",
        header: "Created At",
        Header: <i style={{ color: "#31435f" }}>Created At</i>,
        Cell: ({ cell }) =>
          moment(cell.row.original.createdAt).format("MM/DD/YYYY , LT"),
      },
      {
        accessorKey: "suspendedUntil",
        header: "Suspended Until",
        Header: <i style={{ color: "red" }}>Suspended Until</i>,
        Cell: ({ cell }) =>
          moment(cell.row.original.createdAt).format("MM/DD/YYYY"),
      },
    ],
    []
  );

  const csvConfig = mkConfig({
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: true,
    // headers: columns.map((c) => c.header),
  });

  const handleExportRows = (rows) => {
    const csv = generateCsv(csvConfig)(rows.map((row) => row.original));
    download(csvConfig)(csv);
  };

  const handleExportData = () => {
    const csv = generateCsv(csvConfig)(suspendedUsersData);
    download(csvConfig)(csv);
  };

  return (
    <div>
      {/* <Layout> */}
      <div className="middle_container_top_alignment">
        <div className="breadcrumb__styling">
          <div className="path_styling">
            <h3 className="path__header">Suspended Users</h3>
            {/* <Breadcrumbs /> */}
          </div>
        </div>
      </div>
      <div className="table_data">
        <MaterialReactTable
          columns={columns}
          data={suspendedUsersData}
          enableRowNumbers={true}
          enableColumnOrdering
          enableGlobalFilter={false}
          enableRowSelection
          renderTopToolbarCustomActions={({ table }) => (
            <Box
              sx={{
                display: "flex",
                gap: "1rem",
                p: "0.5rem",
                flexWrap: "wrap",
              }}
            >
              <MaterialButton
                color="primary"
                //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                onClick={handleExportData}
                startIcon={<FileDownloadIcon />}
                variant="contained"
              >
                Export All Data
              </MaterialButton>
              <MaterialButton
                disabled={table.getPrePaginationRowModel().rows.length === 0}
                //export all rows, including from the next page, (still respects filtering and sorting)
                onClick={() =>
                  handleExportRows(table.getPrePaginationRowModel().rows)
                }
                startIcon={<FileDownloadIcon />}
                variant="contained"
              >
                Export All Rows
              </MaterialButton>
              <MaterialButton
                disabled={table.getRowModel().rows.length === 0}
                //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
                onClick={() => handleExportRows(table.getRowModel().rows)}
                startIcon={<FileDownloadIcon />}
                variant="contained"
              >
                Export Page Rows
              </MaterialButton>
              <MaterialButton
                disabled={
                  !table.getIsSomeRowsSelected() &&
                  !table.getIsAllRowsSelected()
                }
                //only export selected rows
                onClick={() =>
                  handleExportRows(table.getSelectedRowModel().rows)
                }
                startIcon={<FileDownloadIcon />}
                variant="contained"
              >
                Export Selected Rows
              </MaterialButton>
            </Box>
          )}
        />
      </div>

      <PopModal
        show={showOldNumber}
        onRequestClose={() => {
          setShowOldNumber(false);
        }}
      >
        <div>
          <p className="old__data">
            {oldNumbers.length !== 0 ? (
              <>
                <h3 style={{ marginBottom: "10px" }}>Old Numbers</h3>
                <div>
                  <span className="old__numbers">
                    {oldNumbers?.map((item) => (
                      <>{item}</>
                    ))}
                  </span>
                </div>
              </>
            ) : (
              <h3>No data found</h3>
            )}
          </p>
        </div>
      </PopModal>
      <PopModal
        show={showOldMail}
        onRequestClose={() => {
          setShowOldMail(false);
        }}
      >
        <div>
          <p className="old__data">
            {oldEmail.length > 0 ? (
              <>
                <h3 style={{ marginBottom: "10px" }}>Old Emails</h3>
                <div>
                  <span className="old__emails">
                    {oldEmail?.map((item) => (
                      <>{item}</>
                    ))}
                  </span>
                </div>
              </>
            ) : (
              <h3>No data found</h3>
            )}
          </p>
        </div>
      </PopModal>
      {/* </Layout> */}
    </div>
  );
};

export default SuspendedUsers;
