import { useState } from "react";
import Layout from "../../component/Layout";
import Breadcrumbs from "../../library/Breadcrumbs";
import Button from "../../library/Button";
import InputField from "../../library/TextField";
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
import "./style.css";
import { callAPI } from "../../Network/networkConnection";
import {
  APIUrl,
  NetworkConfiguration,
} from "../../Network/networkConfiguration";
import { errorToast, successToast } from "../../helper/toast";
import { useNavigate } from "react-router-dom";
// import useUserStore from "../../store/userStore";

const ChangeOldPassword = () => {
  let navigate = useNavigate();
  // const { setUser } = useUserStore();

  const [eye, setEye] = useState(true);
  const [eye2, setEye2] = useState(true);
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const onSubmit = (e) => {
    if (password === "") {
      errorToast("Please fill out your password");
      return;
    } else if (newPassword === "") {
      errorToast("Please fill out your new password");
      return;
    } else if (confirmPassword === "") {
      errorToast("Please fill out your confirm password");
      return;
    } else if (newPassword !== confirmPassword) {
      errorToast("Password do not match");
      return;
    }

    console.log(password);
    console.log(newPassword);
    console.log(confirmPassword);
    callAPI(
      APIUrl + NetworkConfiguration.CHANGEPASSWORD,
      "POST",
      JSON.stringify({ password: password, newPassword: newPassword }),
      { "Content-Type": "application/json" }
    )
      .then((res) => {
        successToast("Your Password has been changed successfully");
        localStorage.clear();
        navigate("/login");
        // setUser({ password: password });
      })
      .catch((err) => {
        errorToast(err.message);
        console.log(err);
      });
  };

  const eyeIcon = () => {
    return eye ? <BsFillEyeFill /> : <BsFillEyeSlashFill />;
  };

  const inputType = () => {
    return eye ? "password" : "text";
  };

  const toHideShowPassword = () => {
    setEye(!eye);
  };

  const eyeIcon2 = () => {
    return eye2 ? <BsFillEyeFill /> : <BsFillEyeSlashFill />;
  };

  const inputType2 = () => {
    return eye2 ? "password" : "text";
  };

  const toHideShowPassword2 = () => {
    setEye2(!eye2);
  };
  return (
    <div>
      {/* <Layout> */}
      <div className="middle_container_top_alignment">
        <div className="breadcrumb__styling">
          <div className="path_styling">
            <h3 className="path__header">Change Old Password</h3>
            {/* <Breadcrumbs /> */}
          </div>
        </div>
      </div>
      <div
        style={{
          backgroundColor: "lightgrey",
          width: "70vw",
          height: "auto",
          marginLeft: "4vw",
          marginTop: "2rem",
          borderRadius: "10px",
          padding: "1rem",
        }}
      >
        <div>
          <InputField
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            type="text"
            label="Enter Old Password"
          />
        </div>
        <div style={{ marginTop: "20px" }}>
          <InputField
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            type={inputType()}
            label="Enter New Password"
            onEyeClick={toHideShowPassword}
            icon={eyeIcon()}
          />
        </div>

        <div style={{ marginTop: "20px" }}>
          <InputField
            type={inputType2()}
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            label="Confirm New Password"
            onEyeClick={toHideShowPassword2}
            icon={eyeIcon2()}
            // style={{ width: "18vw" }}
          />
        </div>

        <div style={{ marginTop: "30px" }}>
          <Button
            onClick={onSubmit}
            text="Save New Password"
            label="Confirm New Password"
            style={{ width: "18vw", height: "7vh", marginLeft: "2vw" }}
          />
        </div>
      </div>
      {/* </Layout> */}
    </div>
  );
};

export default ChangeOldPassword;
