export const AppColor = {
  colorDarkblue: "#033fc1",
  colorMediumblue: "#0d4bae",
  colorLightblue: "#01b6eb",
  colorDarkgrey: "00",
  colorLightgrey: "00",
  colorWhite: "#fff",
  colorBlack: "#000",
  colorRed: "#ff0000",
};
