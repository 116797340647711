import { useState } from "react";
import Button from "../../library/Button";
import InputField from "../../library/TextField";
import "./style.css";

const TestimonialForm = ({ onSubmit, testimonialData, setTestimonialData }) => {
  // const [testimonialData, setTestimonialData] = useState({
  //   name: "",
  //   message: "",
  //   company: "",
  // });

  const onClickSubmit = () => {
    if (validate()) {
      onSubmit(testimonialData);
    }
  };

  const [error, setError] = useState({
    name: "",
    message: "",
    company: "",
  });

  const setName = (e) => {
    setTestimonialData({ ...testimonialData, name: e.target.value });
    setError({ ...error, name: "" });
  };

  const setMessage = (e) => {
    setTestimonialData({ ...testimonialData, message: e.target.value });
    setError({ ...error, message: "" });
  };

  const setCompanyName = (e) => {
    setTestimonialData({ ...testimonialData, company: e.target.value });
    setError({ ...error, company: "" });
  };

  const validate = () => {
    let result = true;
    if (!testimonialData.name) {
      setError({ ...error, name: "Name is required" });
      result = false;
    } else if (!testimonialData.message) {
      setError({ ...error, message: "Message is required" });
      result = false;
    } else if (!testimonialData.company) {
      setError({ ...error, company: "Company name is required" });
      result = false;
    }
    return result;
  };

  return (
    <div className="product_form_container">
      <h1 className="form__heading">Add Testimonial</h1>
      <br />

      <div className="form__data">
        <InputField
          value={testimonialData?.name}
          onChange={setName}
          label="Person Name"
          error={error.name}
        />
      </div>
      <br />

      <div className="form__data">
        <InputField
          value={testimonialData?.message}
          onChange={setMessage}
          label="Message"
          // style={{ marginLeft: "20px", height: "6vh", width: "18vw" }}
          error={error.message}
        />
      </div>
      <br />

      <div className="form__data">
        <InputField
          value={testimonialData?.company}
          onChange={setCompanyName}
          label="Company Name"
          // style={{ marginLeft: "20px", height: "6vh", width: "18vw" }}
          error={error.company}
        />
      </div>
      <br />
      <Button
        onClick={onClickSubmit}
        text="Add Testimonial"
        style={{
          width: "12vw",
          height: "7vh",
          borderRadius: "5px",
          margin: "5px auto",
        }}
      />
    </div>
  );
};

export default TestimonialForm;
