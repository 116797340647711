import { getAuthLocalStorage } from "../storage/getLocalStorage";

export const callAPI = (url, method, body, requestHeaders = {}) =>
  new Promise((resolve, reject) => {
    getAuthLocalStorage().then((res) => {
      let loginHeader = res.loginHeader;
      if (loginHeader && loginHeader.length > 0) {
        requestHeaders["Authorization"] = `Bearer ${loginHeader}`;
      }

      return fetchWithTimeout(url, {
        method: method,
        headers: requestHeaders,
        body: body,
        timeout: 35000, // 35 seconds.
      })
        .then((response) => {
          console.log("NetworkConnection.js Raw Response -->", response);
          let statusCode = +response.status;
          if (statusCode < 300) {
            // If success than execute other response.
            // console.log("response success");
            resolve(response.json());
          } else {
            console.log("reject ....................");
            // response.json().then((data) => reject(data));
            reject(response);
          }
        })
        .catch((error) => {
          console.log("error............");
          // To handle error.

          let title = "Something went wrong";
          let description = "Unknown Error.";

          if (error.name === "AbortError") {
            // if request timeout.
            title = "Request timeout";
            description = "Server out of reachable.";
            // error.message = "Internet problem";
          }

          reject({
            ...error,
            status: NetworkStatus.REQUEST_TIMEOUT,
            statusText: title,
          });
        });
    });
  });

export const fetchWithTimeout = async (resource, options) => {
  // console.log("fetch With Timeout Called........................");
  const { timeout = 8000 } = options;

  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);
  const response = await fetch(resource, {
    ...options,
    signal: controller.signal,
  });
  clearTimeout(id);

  return response;
};

export const NetworkStatus = {
  REQUEST_TIMEOUT: 408,
};
