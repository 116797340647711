import Breadcrumbs from "../../library/Breadcrumbs";
import Layout from "../../component/Layout";
import { useEffect, useMemo, useState } from "react";
import MaterialReactTable from "material-react-table";
import moment from "moment/moment";
import { FaListAlt } from "react-icons/fa";
import { callAPI } from "../../Network/networkConnection";
import {
  APIUrl,
  NetworkConfiguration,
} from "../../Network/networkConfiguration";
import "./style.css";
import PopModal from "../../component/PopUpModal";
import { ImImages } from "react-icons/im";
// import Swiper from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

// import { SwiperSlide } from "swiper/react";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { TiTick } from "react-icons/ti";
import { DeliveryStatusList, OrderStatusList } from "../../helper/Utility";
import { errorToast, successToast } from "../../helper/toast";
import { mkConfig, generateCsv, download } from "export-to-csv";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { Box, Button as MaterialButton } from "@mui/material";

const OrderList = () => {
  const [orderlist, setOrderList] = useState([]);
  const [showProductList, setShowProductList] = useState(false);
  const [productList, setProductList] = useState([]);
  const [productImage, setProductImage] = useState("");
  const [showProductImage, setShowProductImage] = useState(false);
  const [showProductImages, setShowProductImages] = useState(false);
  const [imgUrls, setImgUrls] = useState([]);
  const [orderId, setOrderId] = useState("");
  // const [mainImg, setMainImg] = useState("");

  useEffect(() => {
    fetchOrderList();
  }, []);

  const fetchOrderList = () => {
    callAPI(APIUrl + NetworkConfiguration.GETORDERS, "GET")
      .then((res) => {
        console.log("Order List", res);
        setOrderList(res.orders);
        // console.log(
        //   "Order List",
        //   res.orders[0].products[0].productId.productName
        // );
      })
      .catch((err) => console.log("error", err));
  };

  const handleShowProductList = (products) => {
    console.log("handleShowProductList-->" + products);

    setShowProductList(true);
  };

  const columns = useMemo(
    () => [
      {
        // accessorKey: "productId.productName",
        header: "Products",
        Header: <i style={{ color: "#31435f" }}>Products</i>,
        Cell: ({ cell }) => {
          return (
            <FaListAlt
              style={{ width: "20px", height: "auto", cursor: "pointer" }}
              onClick={() => {
                handleShowProductList(cell.row.original.products);
                setProductList(cell.row.original.products);
                setOrderId(cell.row.original._id);
                console.log("Product list", cell.row.original.products);
              }}
            />
          );
        },
      },
      {
        accessorKey: "user.email",
        header: "Email",
        Header: <i style={{ color: "#31435f" }}>Email</i>,
      },
      {
        accessorKey: "user.phone",
        header: "Contact Us",
        Header: <i style={{ color: "#31435f" }}>Contact Us</i>,
      },
      {
        accessorKey: "products.length",
        header: "Quantity",
        Header: <i style={{ color: "#31435f" }}>Quantity</i>,
      },
      {
        accessorKey: "totalAmount",
        header: "Total Amount",
        Header: <i style={{ color: "#31435f" }}>Total Amount</i>,
      },
      {
        accessorKey: "paymentStatus",
        header: "Payment Status",
        Header: <i style={{ color: "#31435f" }}>Payment Status</i>,
      },
      {
        accessorKey: "orderStatus",
        header: "Order Status",
        Header: <i style={{ color: "#31435f" }}>Order Status</i>,
        Cell: ({ cell }) => {
          const order = cell.row.original;
          return (
            <OrderStatus
              orderId={order?._id}
              value={order.orderStatus}
              items={OrderStatusList}
            />
          );
        },
      },

      {
        accessorKey: "gstNumber",
        header: "Gst Number",
        Header: <i style={{ color: "#31435f" }}>Gst Number</i>,
      },
      {
        accessorKey: "checkoutDate",
        header: "Date & Time",
        Header: <i style={{ color: "#31435f" }}>Date & Time</i>,
        Cell: ({ cell }) =>
          moment(cell.row.original.checkoutDate).format("MM/DD/YYYY , LT"),
      },
    ],
    []
  );

  const onSuccessDeliveryStatus = () => {
    fetchOrderList();
    setShowProductList(false);
    setOrderId("");
  };

  const csvConfig = mkConfig({
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: true,
    // headers: columns.map((c) => c.header),
  });

  const handleExportRows = (rows) => {
    const csv = generateCsv(csvConfig)(rows.map((row) => row.original));
    download(csvConfig)(csv);
  };

  const handleExportData = () => {
    const csv = generateCsv(csvConfig)(orderlist);
    download(csvConfig)(csv);
  };

  return (
    <div>
      {/* <Layout> */}
      <div className="middle_container_top_alignment">
        <div className="breadcrumb__styling">
          <div className="path_styling">
            <h3 className="path__header">Order List</h3>
            {/* <Breadcrumbs /> */}
          </div>
        </div>
      </div>
      <div className="table_data">
        <MaterialReactTable
          columns={columns}
          data={orderlist}
          enableRowNumbers={true}
          enableColumnOrdering
          enableRowSelection
          enableGlobalFilter={false} //turn off a feature
          renderTopToolbarCustomActions={({ table }) => (
            <Box
              sx={{
                display: "flex",
                gap: "1rem",
                p: "0.5rem",
                flexWrap: "wrap",
              }}
            >
              <MaterialButton
                color="primary"
                //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                onClick={handleExportData}
                startIcon={<FileDownloadIcon />}
                variant="contained"
              >
                Export All Data
              </MaterialButton>
              <MaterialButton
                disabled={table.getPrePaginationRowModel().rows.length === 0}
                //export all rows, including from the next page, (still respects filtering and sorting)
                onClick={() =>
                  handleExportRows(table.getPrePaginationRowModel().rows)
                }
                startIcon={<FileDownloadIcon />}
                variant="contained"
              >
                Export All Rows
              </MaterialButton>
              <MaterialButton
                disabled={table.getRowModel().rows.length === 0}
                //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
                onClick={() => handleExportRows(table.getRowModel().rows)}
                startIcon={<FileDownloadIcon />}
                variant="contained"
              >
                Export Page Rows
              </MaterialButton>
              <MaterialButton
                disabled={
                  !table.getIsSomeRowsSelected() &&
                  !table.getIsAllRowsSelected()
                }
                //only export selected rows
                onClick={() =>
                  handleExportRows(table.getSelectedRowModel().rows)
                }
                startIcon={<FileDownloadIcon />}
                variant="contained"
              >
                Export Selected Rows
              </MaterialButton>
            </Box>
          )}
        />
      </div>
      <PopModal
        show={showProductList}
        onRequestClose={() => {
          setShowProductList(false);
          setOrderId("");
        }}
      >
        <table className="product_table">
          <thead className="product_table_head">
            <tr>
              <th className="product_table_row">S.no</th>
              <th className="product_table_row">Product Name</th>
              <th className="product_table_row">Product Price</th>
              <th className="product_table_row">Product Offer Price</th>
              <th className="product_table_row">Product Main Image</th>
              <th className="product_table_row">Product Images</th>
              <th className="product_table_row">Product Category</th>
              <th className="product_table_row">Product Sub-Category</th>
              <th className="product_table_row">Delivery Status</th>
            </tr>
          </thead>
          <tbody>
            {productList?.map((product, index) => {
              console.log("Products", product);
              return (
                <tr className="product_table_row" key={index}>
                  <td className="product_table_data">{index + 1}</td>
                  <td className="product_table_data">
                    {product.productId.productName}
                  </td>
                  <td className="product_table_data">
                    {product.productId.productPrice}
                  </td>
                  <td className="product_table_data">
                    {product.productId.productOfferPrice}
                  </td>
                  <td className="product_table_data">
                    <ImImages
                      style={{
                        cursor: "pointer",
                        width: "20px",
                        height: "auto",
                      }}
                      onClick={() => {
                        setShowProductImage(true);
                        setProductImage(product.productId.productMainImgUrl);
                      }}
                    />
                  </td>

                  <td className="product_table_data">
                    <ImImages
                      style={{
                        cursor: "pointer",
                        width: "20px",
                        height: "auto",
                      }}
                      onClick={() => {
                        setShowProductImages(true);
                        setProductImage(product.productId.productMainImgUrl);
                        setImgUrls(product.productId.productImgUrl);
                        // console.log(product.productId.productImgUrl);
                      }}
                    />
                  </td>
                  <td className="product_table_data">
                    {product?.productId?.productCategory?.categoryName}
                  </td>
                  <td className="product_table_data">
                    {product?.productId?.productSubCategory?.subCategoryName}
                  </td>
                  <td className="product_table_data">
                    <DeliveryStatus
                      orderId={orderId}
                      productId={product._id}
                      value={product.deliveryStatus}
                      items={DeliveryStatusList}
                      onSuccessDeliveryStatus={onSuccessDeliveryStatus}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </PopModal>
      <PopModal
        show={showProductImage}
        onRequestClose={() => setShowProductImage(false)}
      >
        <img
          src={productImage}
          alt="Product_Main_Image"
          style={{
            width: "25vw",
            height: "auto",
            textAlign: "center",
          }}
        />
      </PopModal>

      <PopModal
        show={showProductImages}
        onRequestClose={() => setShowProductImages(false)}
      >
        <div style={{ height: "100%" }}>
          <Swiper
            navigation={true}
            modules={[Navigation]}
            style={{
              width: "25rem",
              height: "auto",
              borderRadius: "10px",
              padding: "10px ",
            }}
          >
            {productImage && (
              <SwiperSlide>
                <img src={productImage} alt="swiper img" />
              </SwiperSlide>
            )}
            {console.log("Image urls", imgUrls)}
            {imgUrls?.length > 0 &&
              imgUrls?.map((img) => {
                console.log("Image: ", img);
                return (
                  <SwiperSlide>
                    <img src={img} alt="swiper img" />
                  </SwiperSlide>
                );
              })}
          </Swiper>
        </div>
      </PopModal>
      {/* </Layout> */}
    </div>
  );
};

const OrderStatus = ({ orderId, items, value }) => {
  const [selectedStatus, setSelectedStatus] = useState(value);

  const onChangeStatus = (e) => {
    setSelectedStatus(e.target.value);
  };

  const onSubmitOrderStatus = () => {
    const orderStatusPayload = {
      orderStatus: selectedStatus,
    };
    callAPI(
      APIUrl + NetworkConfiguration.UPDATE_ORDER_STATUS + `/${orderId}`,
      "PUT",
      JSON.stringify(orderStatusPayload),
      {
        "Content-Type": "application/json",
      }
    )
      .then((res) => {
        console.log("Order Status update", res);
        successToast(res.message);
      })
      .catch((err) => {
        err.json().then((error) => {
          errorToast(error.message);
        });
        console.log("error", err);
      });
  };

  return (
    <Dropdown
      items={items}
      value={selectedStatus}
      onChange={onChangeStatus}
      onSubmit={onSubmitOrderStatus}
    />
  );
};

const DeliveryStatus = ({
  orderId,
  productId,
  items,
  value,
  onSuccessDeliveryStatus,
}) => {
  const [selectedStatus, setSelectedStatus] = useState(value);

  const onChangeStatus = (e) => {
    setSelectedStatus(e.target.value);
  };

  const onSubmitOrderStatus = () => {
    const deliveryStatusPayload = {
      productId,
      deliveryStatus: selectedStatus,
    };
    callAPI(
      APIUrl + NetworkConfiguration.UPDATE_ORDER_STATUS + `/${orderId}`,
      "PUT",
      JSON.stringify(deliveryStatusPayload),
      {
        "Content-Type": "application/json",
      }
    )
      .then((res) => {
        console.log("Delivery Status update", res);
        successToast(res.message);
        onSuccessDeliveryStatus();
      })
      .catch((err) => {
        err.json().then((error) => {
          errorToast(error.message);
        });
        console.log("error", err);
      });
  };

  return (
    <Dropdown
      items={items}
      value={selectedStatus}
      onChange={onChangeStatus}
      onSubmit={onSubmitOrderStatus}
    />
  );
};

const Dropdown = ({ items, value, onChange, onSubmit }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        gap: "10px",
        alignItems: "center",
      }}
    >
      <select
        onChange={onChange}
        value={value}
        style={{ padding: "10px", borderRadius: "none" }}
        className="status_action"
      >
        <option value="" selected default>
          Select
        </option>
        {items.length > 0 &&
          items.map((item) => {
            return (
              <option value={item} className="status_action">
                {item}
              </option>
            );
          })}
      </select>
      <TiTick
        onClick={onSubmit}
        style={{ cursor: "pointer", width: "30px", height: "auto" }}
      />
    </div>
  );
};

export default OrderList;
