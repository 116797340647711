import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { SidebarData } from "../../helper/Utility";
import { MdKeyboardArrowRight } from "react-icons/md";
import Logo from "../../assets/images/white logo.jpg";
import { AppColor } from "../../assets/colors/AppColor";
import "./style.css";

const Sidebar = () => {
  let navigate = useNavigate();

  const pathName = window.location.pathname;

  return (
    <div className="sidebar__container">
      <img src={Logo} alt="kickr__logo" className="kickr__logo" />
      {SidebarData.map((sidedata, index) => {
        return (
          <div key={index} className="sidebar__data">
            {sidedata.options ? (
              <SidebarSubmenus sidedata={sidedata} />
            ) : (
              <div
                className="sidebar_item_row"
                onClick={() => navigate(`${sidedata.link}`)}
                style={{
                  backgroundColor:
                    pathName === sidedata.link
                      ? AppColor.colorLightblue
                      : "transparent",
                }}
              >
                <p className="sidebar__icon">{sidedata.icon}</p>
                <p className="sidebar__head">{sidedata.label}</p>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

const SidebarSubmenus = ({ sidedata }) => {
  let navigate = useNavigate();
  const [showSubmenu, setShowSubMenu] = useState(false);
  const pathName = window.location.pathname;

  return (
    <>
      <div
        className="sidebar_item_row"
        onClick={() => setShowSubMenu(!showSubmenu)}
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <div style={{ display: "flex" }}>
          <p className="sidebar__icon">{sidedata.icon}</p>
          <p className="sidebar__head">{sidedata.label}</p>
        </div>
        <div
          style={{
            transform: showSubmenu ? "rotate(90deg)" : "none",
            transitionTimingFunction: "ease-in", 
          }}
        >
          {<MdKeyboardArrowRight className="arrow__icon" />}
        </div>
      </div>

      <div className="sidebar__data" style={{
                  backgroundColor:
                    pathName === sidedata.link
                      ? AppColor.colorLightblue
                      : "transparent",
                }}>
        <p
          className="sidebar__submenu"
          style={{ display: showSubmenu ? "block" : "none" }}
        >
          {sidedata?.options?.map((item) => {
            return (
              <p
                className="sidebar__submenu__item"
                onClick={() => navigate(`${item.link}`)}
                style={{
                  background: pathName === sidedata.link ? "blue" : "",
                }}
              >
                {item.label}
              </p>
            );
          })}
        </p>
      </div>
    </>
  );
};

export default Sidebar;
