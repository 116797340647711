import "./style.css";

const Switch = ({ checked, onChange }) => {
  return (
    <label class="toggle">
      <input
        class="toggle-input"
        type="checkbox"
        checked={checked}
        onChange={onChange}
      />
      <span class="toggle-label"></span>
      <span class="toggle-handle"></span>
    </label>
  );
};

export default Switch;
