import { useEffect, useState } from "react";
import InputField from "../../library/TextField";
import Button from "../../library/Button";
import "./style.css";

const initailErrorState = {
  categoryName: "",
  imageUrl: "",
};

const initialDataState = {
  categoryName: "",
  imageUrl: "",
};

const initialSubCategoryDataState = {
  subCategoryName: "",
  imageUrl: "",
};

const CategoryForm = ({ onSubmit, onUpdate, data, isEdited }) => {
  const [categoryData, setCategoryData] = useState(initialDataState);
  const [subCategoryData, setSubCategoryData] = useState(
    initialSubCategoryDataState
  );

  useEffect(() => {
    if (isEdited) {
      setCategoryData(data);
    } else {
      setCategoryData(initailErrorState);
    }
    setError(initailErrorState);
  }, [data, isEdited]);

  const [error, setError] = useState(initailErrorState);

  const setCategoryName = (e) => {
    setCategoryData({ ...categoryData, categoryName: e.target.value });
    setError({ ...error, categoryName: "" });
  };

  const setCategoryImage = (e) => {
    setCategoryData({ ...categoryData, imageUrl: e.target.files[0] });
    setError({ ...error, imageUrl: "" });
  };

  const setSubCategoryName = (e) => {
    setSubCategoryData({ ...subCategoryData, subCategoryName: e.target.value });
    setError({ ...error, categoryName: "" });
  };

  const setSubCategoryImage = (e) => {
    setSubCategoryData({ ...subCategoryData, imageUrl: e.target.files[0] });
    setError({ ...error, imageUrl: "" });
  };

  const onClickSubmit = () => {
    if (validate()) {
      isEdited
        ? onUpdate(categoryData)
        : onSubmit(categoryData, subCategoryData);
    }
  };

  const validate = () => {
    let result = true;
    if (!categoryData.categoryName) {
      setError({ ...error, categoryName: "Category name is required" });
      result = false;
    } else if (!categoryData.imageUrl) {
      setError({ ...error, imageUrl: "Image is required" });
      result = false;
    }
    return result;
  };

  return (
    <div style={{ width: "350px" }}>
      <h1 className="form__heading">
        {isEdited ? "Edit Category" : "Add Category"}
      </h1>
      <div className="form__data">
        <InputField
          value={categoryData?.categoryName}
          onChange={setCategoryName}
          label="Enter Category Name"
          error={error.categoryName}
        />
      </div>
      <br />

      <div className="form__data">
        <InputField
          onChange={setCategoryImage}
          label="Category Image"
          type="file"
          accept="image/*"
          placeholder="Ex- #products, #3dassets"
          style={{
            paddingTop: "9px",
          }}
          error={error.imageUrl}
        />
      </div>

      {!isEdited && (
        <>
          <br />
          <div className="form__data">
            <InputField
              value={subCategoryData?.subCategoryName}
              onChange={setSubCategoryName}
              label="Enter SubCategory Name"
            />
          </div>
          <br />

          <div className="form__data">
            <InputField
              onChange={setSubCategoryImage}
              label="SubCategory Image"
              type="file"
              accept="image/*"
              placeholder="Ex- #products, #3dassets"
              style={{
                paddingTop: "9px",
              }}
            />
          </div>
        </>
      )}

      <Button
        onClick={onClickSubmit}
        text={isEdited ? "Save Changes" : "Add Category"}
        style={{
          width: "auto",
          height: "auto",
          borderRadius: "5px",
          padding: "1rem",
          marginBottom: "1rem",
          marginTop: "1rem",
        }}
      />
    </div>
  );
};

export default CategoryForm;
