import React, { useState } from "react";
import InputField from "../../library/TextField";
import Button from "../../library/Button";

const Opening = ({ onSubmit, isEdited, onUpdate, data, setData }) => {
  const [error, setError] = useState({
    name: "",
    imageUrl: "",
  });

  const onClickSubmit = () => {
    if (validate()) {
      isEdited ? onUpdate(data) : onSubmit(data);
    }
  };

  const setOpeningName = (e) => {
    setData({ ...data, name: e.target.value });
    setError({ ...error, name: "" });
  };

  const setOpeningImage = (e) => {
    setData({ ...data, imageUrl: e.target.files[0] });
    setError({ ...error, imageUrl: "" });
  };

  const validate = () => {
    let result = true;
    if (!data.name) {
      setError({ ...error, name: "Job Opening name is required" });
      result = false;
    } else if (!data.imageUrl) {
      setError({ ...error, imageUrl: "Job Description PDF is required" });
      result = false;
    }

    return result;
  };

  return (
    <div style={{ width: "350px" }}>
      <h1 className="form__heading">
        {isEdited ? "Edit Job Opening" : "Add Job Opening"}
      </h1>
      <br />
      <div className="form__data">
        <InputField
          value={data.name}
          onChange={setOpeningName}
          label=" Job Opening"
          error={error.name}
        />
      </div>
      <br />

      <div className="form__data">
        <InputField
          onChange={setOpeningImage}
          label="Upload Job Description"
          type="file"
          accept="application/pdf"
          style={{ paddingTop: "10px" }}
          error={error.imageUrl}
        />
        {data?.imageUrl ? (
          <a rel="noreferrer" href={data?.imageUrl} target="_blank">
            selected pdf
          </a>
        ) : null}
      </div>
      <br />

      <Button
        onClick={onClickSubmit}
        text={isEdited ? "Save Changes" : "Add Job Opening"}
        style={{
          width: "12vw",
          height: "7vh",
          borderRadius: "5px",
          margin: "5px auto",
        }}
      />
    </div>
  );
};

export default Opening;
