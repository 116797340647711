import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { RxCross2 } from "react-icons/rx";
import { APIUrl } from "../../../Network/networkConfiguration";
import { toast } from "react-toastify";

export default function AddQuiz() {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [priceMoney, setPriceMoney] = useState("");

  const [file, setFile] = useState(null);
  const [questions, setQuestions] = useState([
    { text: "", options: [{ text: "", isCorrect: false }] },
  ]);
  const navigate = useNavigate();

  const handleQuestionChange = (index, value) => {
    const newQuestions = [...questions];
    newQuestions[index].text = value;
    setQuestions(newQuestions);
  };

  const handleOptionChange = (qIndex, oIndex, value) => {
    const newQuestions = [...questions];
    newQuestions[qIndex].options[oIndex].text = value;
    setQuestions(newQuestions);
  };

  const handleIsCorrectChange = (qIndex, oIndex) => {
    const newQuestions = [...questions];
    newQuestions[qIndex].options.forEach(
      (option) => (option.isCorrect = false)
    ); // Only one correct option per question
    newQuestions[qIndex].options[oIndex].isCorrect = true;
    setQuestions(newQuestions);
  };

  const addQuestion = () => {
    setQuestions([
      ...questions,
      { text: "", options: [{ text: "", isCorrect: false }] },
    ]);
  };

  const addOption = (index) => {
    const newQuestions = [...questions];
    newQuestions[index].options.push({ text: "", isCorrect: false });
    setQuestions(newQuestions);
  };

  const removeQuestion = (index) => {
    const newQuestions = [...questions];
    newQuestions.splice(index, 1);
    setQuestions(newQuestions);
  };

  const removeOption = (qIndex, oIndex) => {
    const newQuestions = [...questions];
    newQuestions[qIndex].options.splice(oIndex, 1);
    setQuestions(newQuestions);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("title", title);
    formData.append("description", description);
    formData.append("priceMoney", priceMoney);

    if (file) formData.append("image", file);
    formData.append("questions", JSON.stringify(questions));

    try {
      const response = await axios.post(`${APIUrl}quiz`, formData);

      if (response) {
        toast.success("Quiz created successfully");
        navigate("/Component/quiz/list");
      } else {
        toast.error("Failed to create contest");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Failed to create contest");
    }
  };

  const handleredirect = () => {
    navigate(`/Component/quiz/list`);
  };

  return (
    <div className="max-w-4xl relative mx-auto p-8">
      <h1 className="text-2xl font-bold mb-4">Add Quiz</h1>
      <div className="absolute right-4 top-5">
        <button onClick={handleredirect}>
          <h3 className="p-2 bg-blue-500 rounded-xl px-5 text-white">List</h3>
        </button>
      </div>
      <form onSubmit={handleSubmit} className="space-y-6">
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Title
          </label>
          <input
            type="text"
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Description
          </label>
          <textarea
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Image
          </label>
          <input
            type="file"
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
            onChange={(e) => setFile(e.target.files[0])}
            required
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Prize Money
          </label>
          <input
            type="number"
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
            value={priceMoney}
            onChange={(e) => setPriceMoney(e.target.value)}
            required
          />
        </div>

        {questions.map((question, qIndex) => (
          <div key={qIndex} className="p-4 border rounded-md">
            <div className="flex justify-between items-center">
              <label className="block text-sm font-medium text-gray-700">
                Question {qIndex + 1}
              </label>
              <button
                type="button"
                className="text-white bg-red-500 rounded-md p-1 text-xs"
                onClick={() => removeQuestion(qIndex)}
              >
                Remove Question
              </button>
            </div>
            <input
              type="text"
              className="mt-1 block w-full p-2 border  border-gray-300 rounded-md"
              value={question.text}
              onChange={(e) => handleQuestionChange(qIndex, e.target.value)}
              required
            />
            <div className="mt-4 space-y-2">
              {question.options.map((option, oIndex) => (
                <div key={oIndex} className="flex items-center space-x-2">
                  <input
                    type="text"
                    className="block w-full p-2 border border-gray-300 rounded-md"
                    value={option.text}
                    onChange={(e) =>
                      handleOptionChange(qIndex, oIndex, e.target.value)
                    }
                    required
                  />
                  <input
                    type="radio"
                    name={`correctOption-${qIndex}`}
                    checked={option.isCorrect}
                    onChange={() => handleIsCorrectChange(qIndex, oIndex)}
                  />
                  <button
                    type="button"
                    className="text-white bg-red-500 p-1 rounded-md text-xs"
                    onClick={() => removeOption(qIndex, oIndex)}
                  >
                    <RxCross2 size={20} color="white" />
                  </button>
                </div>
              ))}
            </div>
            <button
              type="button"
              className="mt-2 text-blue-500"
              onClick={() => addOption(qIndex)}
            >
              Add Option
            </button>
          </div>
        ))}
        <button
          type="button"
          className="mt-4 text-blue-500"
          onClick={addQuestion}
        >
          Add Question
        </button>
        <div>
          <button
            type="submit"
            className="w-full py-2 px-4 bg-blue-600 text-white rounded-md hover:bg-blue-700"
          >
            Create Quiz
          </button>
        </div>
      </form>
    </div>
  );
}
