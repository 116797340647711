import "./style.css";

const TextArea = (props) => {
  return (
    <>
      {props?.label ? (
        <label className="label_styling">{props.label}</label>
      ) : null}

      <textarea
        className="input-style textarea_style"
        onChange={props.onChange}
        placeholder={props.placeholder}
        value={props.value}
        style={props.style}
      />
      {props?.error ? <p className="error_styling">*{props.error}</p> : null}
    </>
  );
};

export default TextArea;
