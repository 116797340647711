import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { APIUrl } from "../../Network/networkConfiguration";
import { toast } from "react-toastify";

export default function AddComponent() {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [code, setCode] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      name: name,
      description: description,
      code: code,
    };

    try {
      const response = await axios.post(`${APIUrl}add-component`, data);

      if (response) {
        toast.success("Component created successfully");

        navigate("/Component/list");
      } else {
        toast.error("Failed to create component");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Failed to create component");
    }
  };

  const handleredirect = () => {
    navigate(`/Component/list`);
  };

  return (
    <div className="container relative mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Add New Component</h1>
      <div className="absolute right-4 top-5">
        <button onClick={handleredirect}>
          <h3 className="p-2 bg-blue-500 rounded-xl px-5 text-white">List</h3>
        </button>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Name
          </label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
            placeholder="Enter modal name"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Description
          </label>
          <textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
            placeholder="Enter modal description"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Code
          </label>
          <textarea
            value={code}
            onChange={(e) => setCode(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
            placeholder="Enter modal code"
          />
        </div>
        <button
          type="submit"
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
        >
          Submit
        </button>
      </form>
    </div>
  );
}
