import Layout from "../../component/Layout";
import Breadcrumbs from "../../library/Breadcrumbs";
// import Table from "../../component/Table";
import Form from "../../component/Form";
import { useEffect, useMemo, useState } from "react";
import { MaterialReactTable } from "material-react-table";
import { Box, IconButton, Button as MaterialButton } from "@mui/material";
import { Edit as EditIcon, Delete as DeleteIcon } from "@mui/icons-material";
import Button from "../../library/Button";
import Product from "../../forms_components/product";
import {
  APIUrl,
  NetworkConfiguration,
} from "../../Network/networkConfiguration";
import { callAPI } from "../../Network/networkConnection";
// import { useNavigate } from "react-router-dom";
import { ImImages } from "react-icons/im";
import { Swiper, SwiperSlide } from "swiper/react";
import PopModal from "../../component/PopUpModal";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "./style.css";
import { ImageService } from "../../service/ImageService";
import moment from "moment";
import AlertPopUp from "../../component/AlertPopUp";
import { errorToast, successToast } from "../../helper/toast";
import Switch from "../../library/Switch";
import { AiFillStar } from "react-icons/ai";
import { mkConfig, generateCsv, download } from "export-to-csv";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

const ProductList = () => {
  const [showUserForm, setShowUserForm] = useState(false);
  const [productList, setProductList] = useState([]);
  const [showImageSlider, setShowImageSlider] = useState(false);
  const [imgUrls, setImgUrls] = useState([]);
  const [mainImg, setMainImg] = useState("");
  const [data, setData] = useState([]);
  const [isEdited, setIsEdited] = useState(false);
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [productId, setProductId] = useState("");
  const [showProductDescription, setShowProductDescription] = useState(false);
  const [productDescriptionMessage, setProductDescriptionMessage] =
    useState("");

  const [productData, setProductData] = useState({
    productName: "",
    productPrice: "",
    productOfferPrice: "",
    openFiles: "",
    format: "",
    productCategory: "",
    productSubCategory: "",
    productDescription: "",
    image: "",
    imageUrl: "",
    tags: "",
    isFeatured: false,
  });

  // const navigate = useNavigate();

  const columns = useMemo(
    () => [
      {
        accessorKey: "isFeatured",
        header: "Featured",
        size: 100,
        Header: <i style={{ color: "#31435f" }}>Featured</i>,
        Cell: ({ cell }) => {
          return cell.row.original.isFeatured ? (
            <div style={{ textAlign: "center" }}>
              <AiFillStar size={25} color="#ffa534" />
            </div>
          ) : null;
        },
      },
      {
        accessorKey: "productName",
        header: "Product Name",
        Header: <i style={{ color: "#31435f" }}>Product Name</i>,
      },
      {
        accessorKey: "productPrice",
        header: "Product Price",
        Header: <i style={{ color: "#31435f" }}>Product Price</i>,
      },
      {
        accessorKey: "productOfferPrice",
        header: "Product Offer Price",
        Header: <i style={{ color: "#31435f" }}>Product Offer Price</i>,
      },
      {
        accessorKey: "openFiles",
        header: "Open Files",
        Header: <i style={{ color: "#31435f" }}>Open Files</i>,
      },
      {
        accessorKey: "format",
        header: "Format",
        Header: <i style={{ color: "#31435f" }}>Format</i>,
      },
      {
        accessorKey: "productDescription",
        header: "Product Description",
        Header: <i style={{ color: "#31435f" }}>Product Description</i>,
        Cell: ({ cell }) => {
          return (
            <div>
              <p
                onClick={() => {
                  setShowProductDescription(true);
                  setProductDescriptionMessage(
                    cell.row.original?.productDescription
                  );
                }}
                className="message__hover"
              >
                {cell.row.original?.productDescription}
              </p>
            </div>
          );
        },
      },
      {
        accessorKey: "productCategory.categoryName",
        header: "Product Category",
        Header: <i style={{ color: "#31435f" }}>Product Category</i>,
      },
      {
        accessorKey: "productSubCategory.subCategoryName",
        header: "Product Sub-Category",
        Header: <i style={{ color: "#31435f" }}>Product Sub-Category</i>,
      },
      {
        accessorKey: "mainImage",
        header: "Product Main Image",
        Header: <i style={{ color: "#31435f" }}>Product Main Image</i>,
        Cell: ({ cell }) => {
          return (
            <ImImages
              show={showImageSlider}
              onRequestClose={() => setShowImageSlider(false)}
              className="eye_icon"
              onClick={() => {
                setImgUrls([]);
                setShowImageSlider(true);
                setMainImg(cell?.row?.original?.productMainImgUrl);
              }}
            />
          );
        },
      },
      {
        accessorKey: "images",
        header: "Product Multiple Images",
        Header: <i style={{ color: "#31435f" }}>Product Multiple Images</i>,
        Cell: ({ cell }) => {
          return (
            cell?.row?.original?.productImgUrl.length > 0 && (
              <ImImages
                show={showImageSlider}
                onRequestClose={() => setShowImageSlider(false)}
                className="eye_icon"
                onClick={() => {
                  setMainImg(cell?.row?.original?.productMainImgUrl);
                  setImgUrls(cell?.row?.original?.productImgUrl);
                  setShowImageSlider(true);
                }}
              />
            )
          );
        },
      },
      {
        accessorKey: "createdAt",
        header: "Created At",
        Header: <i style={{ color: "#31435f" }}>Created At</i>,
        Cell: ({ cell }) =>
          moment(cell.row.original.createdAt).format("MM/DD/YYYY , LT"),
      },

      {
        accessorKey: "tags",
        header: "Tags",
        Header: <i style={{ color: "#31435f" }}>Tags</i>,
      },

      // {
      //   accessorKey: "isFeatured",
      //   header: "Featured Products",
      //   Header: <i style={{ color: "#31435f" }}>Featured Products</i>,
      //   Cell: ({ cell }) => {
      //     return <Switch />;
      //   },
      // },
    ],
    []
  );

  const onSubmit = (productData) => {
    let data = new FormData();
    data.append("productName", productData?.productName);
    data.append("productPrice", productData?.productPrice);
    data.append("productOfferPrice", productData?.productOfferPrice);
    data.append("openFiles", productData?.openFiles);
    data.append("format", productData?.format);
    data.append("productCategory", productData?.productCategory);
    data.append("productSubCategory", productData?.productSubCategory);
    data.append("productDescription", productData?.productDescription);
    data.append("isFeatured", productData?.isFeatured);
    data.append("image", productData?.image);
    const tags = productData?.tags.split(",");
    for (let i = 0; i < tags.length; i++) {
      data.append(`tags[${i}]`, tags[i]);
    }

    callAPI(APIUrl + NetworkConfiguration.ADDPRODUCTS, "POST", data)
      .then((res) => {
        successToast(res.message);

        ImageService.multiImageUpload(productData?.imageUrl, "product", res?.id)
          .then(() => {
            setProductData({
              productName: "",
              productPrice: "",
              productOfferPrice: "",
              openFiles: "",
              format: "",
              productCategory: "",
              productSubCategory: "",
              productDescription: "",
              image: null,
              // imageUrl: null,
              tags: "",
              isFeatured: false,
              id: "",
            });
            setShowUserForm(false);
            // window.location.reload()
            fetchProducts();
          })
          .catch((err) => {
            errorToast("Please enter every detail");
            console.error(err);
          });
      })
      .catch((err) => {
        errorToast("Please enter every detail");
        console.error("errorrr-------", err);
      });
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = () => {
    callAPI(APIUrl + NetworkConfiguration.PRODUCTS, "GET")
      .then((res) => {
        console.log("Products", res);
        setProductList(res);
      })
      .catch((err) => {
        if (err.status === 404) {
          setProductList([]);
        }
        console.log("error", err);
      });
  };

  const onUpdate = (data) => {
    console.log("Data: ", data);
    callAPI(
      APIUrl + NetworkConfiguration.UPDATEPRODUCT,
      "PUT",
      JSON.stringify(data),
      {
        "Content-Type": "application/json",
      }
    )
      .then((res) => {
        if (data?.image) {
          ImageService.imageUpload(data?.image, "product", data.id)
            .then(() => {
              setProductData({
                productName: "",
                productPrice: "",
                productOfferPrice: "",
                openFiles: "",
                format: "",
                productCategory: "",
                productSubCategory: "",
                productDescription: "",
                image: "",
                imageUrl: "",
                tags: "",
                isFeatured: false,
                id: "",
              });
              setIsEdited(false);
              setShowUserForm(false);
              fetchProducts();
              successToast(res.message);
            })
            .catch((err) => console.log("ImageService", err));
        } else {
          setProductData({
            productName: "",
            productPrice: "",
            productOfferPrice: "",
            openFiles: "",
            format: "",
            productCategory: "",
            productSubCategory: "",
            productDescription: "",
            image: "",
            imageUrl: "",
            tags: "",
            isFeatured: false,
            id: "",
          });
          setIsEdited(false);
          setShowUserForm(false);
          fetchProducts();
        }
      })
      .catch((err) => console.log(err));
  };

  const handleDeleteRow = (id) => {
    callAPI(APIUrl + NetworkConfiguration.DELETEPRODUCT + "/" + id, "DELETE")
      .then((res) => {
        successToast(res.message);
        fetchProducts();
        setDeleteAlert(false);
        setProductId("");
      })
      .catch((err) => console.log(err));
  };
  console.log("data", data);

  const onClickDelete = (id) => {
    setDeleteAlert(true);
    setProductId(id);
  };

  const csvConfig = mkConfig({
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: true,
    // headers: columns.map((c) => c.header),
  });

  const handleExportRows = (rows) => {
    const csv = generateCsv(csvConfig)(rows.map((row) => row.original));
    download(csvConfig)(csv);
  };

  const handleExportData = () => {
    const csv = generateCsv(csvConfig)(productList);
    download(csvConfig)(csv);
  };

  return (
    // <Layout>
    <>
      <div className="middle_container_top_alignment">
        <div className="breadcrumb__styling">
          <div className="path_styling">
            <h3 className="path__header">Product List</h3>
            {/* <Breadcrumbs /> */}
          </div>
        </div>

        <PopModal
          show={showProductDescription}
          onRequestClose={() => setShowProductDescription(false)}
        >
          <h2 className="description_heading" style={{ textAlign: "center" }}>
            Product Description
          </h2>

          <div
            className="messsage__para"
            style={{
              width: "30rem",
              height: "auto",
              overflow: "scroll",
              padding: ".5rem",
              textAlign: "justify",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {productDescriptionMessage}
          </div>
        </PopModal>

        <AlertPopUp
          show={deleteAlert}
          header="Delete Product"
          description="Do you want to delete this product?"
          submitText="Yes"
          cancelText="No"
          onCancelClick={() => setDeleteAlert(false)}
          onSubmitClick={() => handleDeleteRow(productId)}
        ></AlertPopUp>

        <Button
          text="Add Product"
          onClick={() => {
            setShowUserForm(true);
            setIsEdited(false);
          }}
          style={{
            height: "6vh",
            width: "10vw",
            margin: "20px",
            padding: "5px",
          }}
        />
      </div>
      <Form show={showUserForm} onRequestClose={() => setShowUserForm(false)}>
        <Product
          onSubmit={onSubmit}
          onUpdate={onUpdate}
          data={productData}
          isEdited={isEdited}
        />
      </Form>

      <div className="table_data">
        <MaterialReactTable
          columns={columns}
          data={productList}
          enableRowNumbers={true}
          enableColumnOrdering
          enableGlobalFilter={false} //turn off a feature
          enableRowActions
          enableRowSelection
          renderRowActions={({ row, table }) => (
            <Box sx={{ display: "flex", flexWrap: "nowrap" }}>
              <IconButton
                style={{ color: "#1468af" }}
                onClick={() => {
                  setProductData({
                    productName: row.original.productName,
                    productPrice: row.original.productPrice,
                    productOfferPrice: row.original.productOfferPrice,
                    openFiles: row.original?.openFiles,
                    format: row.original?.format,
                    productCategory: row.original.productCategory?._id,
                    productSubCategory: row.original.productSubCategory?._id,
                    productDescription: row.original.productDescription,
                    image: row.original.productMainImgUrl,
                    imageUrl: row.original.productImgUrl,
                    tags: row.original.tags,
                    isFeatured: row.original.isFeatured,
                    id: row.original._id,
                  });
                  setIsEdited(true);
                  setShowUserForm(true);

                  console.log(row, "row");
                }}
              >
                <EditIcon />
              </IconButton>
              <IconButton color="error">
                <DeleteIcon onClick={() => onClickDelete(row.original._id)} />
              </IconButton>
            </Box>
          )}
          renderTopToolbarCustomActions={({ table }) => (
            <Box
              sx={{
                display: "flex",
                gap: "1rem",
                p: "0.5rem",
                flexWrap: "wrap",
              }}
            >
              <MaterialButton
                color="primary"
                //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                onClick={handleExportData}
                startIcon={<FileDownloadIcon />}
                variant="contained"
              >
                Export All Data
              </MaterialButton>
              <MaterialButton
                disabled={table.getPrePaginationRowModel().rows.length === 0}
                //export all rows, including from the next page, (still respects filtering and sorting)
                onClick={() =>
                  handleExportRows(table.getPrePaginationRowModel().rows)
                }
                startIcon={<FileDownloadIcon />}
                variant="contained"
              >
                Export All Rows
              </MaterialButton>
              <MaterialButton
                disabled={table.getRowModel().rows.length === 0}
                //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
                onClick={() => handleExportRows(table.getRowModel().rows)}
                startIcon={<FileDownloadIcon />}
                variant="contained"
              >
                Export Page Rows
              </MaterialButton>
              <MaterialButton
                disabled={
                  !table.getIsSomeRowsSelected() &&
                  !table.getIsAllRowsSelected()
                }
                //only export selected rows
                onClick={() =>
                  handleExportRows(table.getSelectedRowModel().rows)
                }
                startIcon={<FileDownloadIcon />}
                variant="contained"
              >
                Export Selected Rows
              </MaterialButton>
            </Box>
          )}
        />
      </div>

      <PopModal
        show={showImageSlider}
        onRequestClose={() => setShowImageSlider(false)}
      >
        <div style={{ height: "100%" }}>
          <Swiper
            navigation={true}
            modules={[Navigation]}
            style={{
              width: "25rem",
              height: "auto",
              borderRadius: "10px",
              padding: "10px ",
            }}
          >
            {mainImg && (
              <SwiperSlide>
                <img src={mainImg} alt="swiper img" />
              </SwiperSlide>
            )}
            {console.log("Image urls", imgUrls)}
            {imgUrls.length > 0 &&
              imgUrls.map((img) => {
                console.log("Image: ", img);
                return (
                  <SwiperSlide>
                    <img src={img} alt="swiper img" />
                  </SwiperSlide>
                );
              })}
          </Swiper>
        </div>
      </PopModal>
    </>
    // </Layout>
  );
};

export default ProductList;
