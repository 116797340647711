import axios from "axios";
import { useEffect, useState } from "react";
import { APIUrl } from "../../../Network/networkConfiguration";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const AddGiveaway = () => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [selectedContest, setSelectedContest] = useState(null);
  const [selectedQuiz, setSelectedQuiz] = useState(null);
  const [image, setImage] = useState(null);
  const [contests, setContests] = useState([]);
  const [quizzes, setQuizzes] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchContests = async () => {
      try {
        const response = await axios.get(`${APIUrl}contest`);

        setContests(response.data.data);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchContests();
  }, []);

  useEffect(() => {
    const fetchContests = async () => {
      try {
        const response = await axios.get(`${APIUrl}quiz`);

        setQuizzes(response.data.data);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchContests();
  }, []);

  const handleContestChange = (e) => {
    setSelectedContest(e.target.value);
    setSelectedQuiz(null); // Ensure only one is selected
  };

  const handleQuizChange = (e) => {
    setSelectedQuiz(e.target.value);
    setSelectedContest(null);
  };

  const handleImageChange = (e) => {
    setImage(e.target.files[0]); // Set the selected image file
  };

  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append("title", title);
    formData.append("description", description);
    {
      selectedContest && formData.append("contest", selectedContest);
    }
    {
      selectedQuiz && formData.append("quiz", selectedQuiz);
    }

    if (image) {
      formData.append("image", image); // Append image only if selected
    }

    try {
      const response = await axios.post(`${APIUrl}giveaway`, formData);

      if (response) {
        toast.success("GiveAway created successfully");
        navigate("/Component/giveaway/list");
      } else {
        toast.error("Failed to create GiveAway");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Failed to create contest");
    }
  };

  const handleredirect = () => {
    navigate(`/Component/giveaway/list`);
  };

  return (
    <div className="container relative mx-auto p-4">
      <h1 className="text-2xl font-bold">Add Giveaway</h1>
      <div className="absolute right-4 top-10">
        <button onClick={handleredirect}>
          <h3 className="p-2 bg-blue-500 rounded-xl px-5 text-white">List</h3>
        </button>
      </div>
      <div className="mt-4">
        <label className="block mb-2">Title</label>
        <input
          type="text"
          className="border p-2 w-full"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />

        <label className="block mt-4 mb-2">Description</label>
        <textarea
          className="border p-2 w-full"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />

        <label className="block mt-4 mb-2">Image</label>
        <input
          type="file"
          className="border p-2 w-full"
          accept="image/*"
          onChange={handleImageChange}
        />

        <div className="mt-4">
          <label className="block mb-2">Select Contest</label>
          <select
            value={selectedContest || ""}
            onChange={handleContestChange}
            className="border p-2 w-full"
            disabled={!!selectedQuiz}
          >
            <option value="">Select Contest</option>
            {contests?.map((contest) => (
              <option key={contest._id} value={contest._id}>
                {contest.title}
              </option>
            ))}
          </select>
        </div>

        <div className="mt-4">
          <label className="block mb-2">Select Quiz</label>
          <select
            value={selectedQuiz || ""}
            onChange={handleQuizChange}
            className="border p-2 w-full"
            disabled={!!selectedContest}
          >
            <option value="">Select Quiz</option>
            {quizzes?.map((quiz) => (
              <option key={quiz._id} value={quiz._id}>
                {quiz.title}
              </option>
            ))}
          </select>
        </div>

        <button
          onClick={handleSubmit}
          className="mt-4 bg-blue-500 text-white p-2 rounded"
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default AddGiveaway;
