import { useState } from "react";
import Button from "../../library/Button";
import InputField from "../../library/TextField";
import NewPassword from "../NewPassword";
import "./style.css";
import { callAPI } from "../../Network/networkConnection";
import {
  APIUrl,
  NetworkConfiguration,
} from "../../Network/networkConfiguration";
import { errorToast, successToast } from "../../helper/toast";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const ChangePassword = () => {
  let navigate = useNavigate();
  const [getOtp, setGetOtp] = useState(false);
  const [email, setEmail] = useState("");
  const [id, setId] = useState("");
  const [otp, setOtp] = useState("");
  const [newPassEmail, setNewPassEmail] = useState("");

  const onPasswordSubmit = (e) => {
    e.preventDefault();

    callAPI(
      APIUrl + NetworkConfiguration.SENDOTP,
      "POST",
      JSON.stringify({ email: email }),
      { "Content-Type": "application/json" }
    )
      .then((res) => {
        successToast(res.message);
        setId(res.id);
        console.log(res.id);
      })
      .catch((err) => {
        errorToast("Enter valid email address");
        console.log(err, "reject..............");
      });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (email === "") {
      errorToast("Please fill out your email");
      return;
    } else if (otp === "") {
      errorToast("Please fill out your otp");
      return;
    }
    callAPI(
      APIUrl + NetworkConfiguration.VERIFYOTP,
      "POST",
      JSON.stringify({ otp: otp, id: id }),
      { "Content-Type": "application/json" }
    )
      .then((res) => {
        successToast(res.message);
        setNewPassEmail(email);
        localStorage.setItem("email", email);
        setGetOtp(true);
        navigate("/newpassword");
        console.log(res, "success----------------");
      })
      .catch((err) => {
        errorToast("Enter valid OTP");
        console.log(err, "reject..............");
      });
  };
  return (
    <div className="page_container">
      {getOtp ? (
        <NewPassword email={newPassEmail} />
      ) : (
        <div style={{ height: "55vh" }} className="login_container">
          <form onSubmit={onSubmit}>
            <h3 style={{ marginBottom: "5px" }}>Reset Password</h3>
            <p>Enter the below details to reset your password</p>
            <label className="enter__mail">Enter your mail</label>

            <InputField
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              icon={<span className="otp__button">Get OTP</span>}
              onEyeClick={(e) => onPasswordSubmit(e)}
              // error={error.username}
            />

            <br />

            <label className="enter__otp">Enter OTP</label>
            <InputField
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              type="type"

              // error={error.password}
            />
            <br />

            <Button
              onClick={(e) => {
                onSubmit(e);
              }}
              text="Submit"
              style={{
                height: "7vh",
                width: "12vw",
              }}
            />
            <br />
          </form>
        </div>
      )}
    </div>
  );
};

export default ChangePassword;
