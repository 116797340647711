import { useNavigate } from "react-router-dom";
import Layout from "../../component/Layout";
import Breadcrumbs from "../../library/Breadcrumbs";
import Button from "../../library/Button";
import InputField from "../../library/TextField";
import useUserStore from "../../store/userStore";
import "./style.css";

const EditProfile = () => {
  let navigate = useNavigate();
  const { user } = useUserStore();
  console.log("Edit Profile-->>>", user);

  return (
    <div>
      {/* <Layout> */}
      <div className="middle_container_top_alignment">
        <div className="breadcrumb__styling">
          <div className="path_styling">
            <h3 className="path__header">Edit Profile</h3>
            <Breadcrumbs />
          </div>
        </div>
      </div>
      <div style={{ marginTop: "10vh" }}>
        <InputField
          value={user.username}
          label="Username"
          labelStyle={{ marginLeft: "30px" }}
          style={{ marginLeft: "30px" }}
        />
      </div>

      <div style={{ marginTop: "20px" }}>
        <InputField
          value={user.password}
          label="Password"
          labelStyle={{ marginLeft: "30px" }}
          style={{ marginLeft: "30px" }}
        />
      </div>

      <div style={{ marginTop: "20px" }}>
        <Button
          onClick={() => {
            navigate("/changeoldpassword");
          }}
          text="Change Password"
          style={{ width: "18vw", height: "7vh", marginLeft: "30px" }}
        />
      </div>
      {/* </Layout> */}
    </div>
  );
};

export default EditProfile;
