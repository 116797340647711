import { Link } from "react-router-dom";
import "./style.css";

const Breadcrumbs = () => {
  const paths = window.location.pathname.split("/").slice(1);

  const arr = [];

  paths.map((item, index) => {
    return arr.push({
      title: item,
      link: `/${paths.slice(0, index + 1).join("/")}`,
    });
  });

  // console.log("breadcrumb ", arr);

  return (
    <div className="breadcrumb__container">
      {arr &&
        arr.map((b, index) =>
          index !== arr.length - 1 ? (
            <div key={b.title}>
              <Link
                href={b.link}
                className="pathname"
                style={{ textDecoration: "none" }}
              >
                {b.title}/
              </Link>
            </div>
          ) : (
            <p key={b.title} className="pathname">
              {b.title}
            </p>
          )
        )}
    </div>
  );
};

export default Breadcrumbs;




