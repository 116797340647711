import "./style.css";
// import { AiFillCloseCircle } from "react-icons/ai";

const PopModal = ({ children, show, onRequestClose, props }) => {
  return (
    <div
      className="modal__backdrop"
      style={{ display: show ? "flex" : "none" }}
      onClick={onRequestClose}
    >
      <div className="modal" onClick={(e) => e.stopPropagation()}>
        {children}
      </div>
      {/* <AiFillCloseCircle /> */}
    </div>
  );
};

export default PopModal;
