import MaterialReactTable from "material-react-table";
import Layout from "../../component/Layout";
import Breadcrumbs from "../../library/Breadcrumbs";
import { Edit as EditIcon, Delete as DeleteIcon } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";

import "./style.css";
import moment from "moment";
import { FaFileDownload } from "react-icons/fa";
import { useEffect, useMemo, useState } from "react";
import Button from "../../library/Button";
import Form from "../../component/Form";
import NotificationForm from "../../forms_components/NotificationForm";
import PopModal from "../../component/PopUpModal";
import { ImImages } from "react-icons/im";
import { callAPI } from "../../Network/networkConnection";
import {
  APIUrl,
  NetworkConfiguration,
} from "../../Network/networkConfiguration";
import { errorToast } from "../../helper/toast";

const Notification = () => {
  const [teamData, setTeamData] = useState([]);
  const [showUserImage, setShowUserImage] = useState(false);
  const [showNotificationForm, setShowNotificationForm] = useState(false);
  const [userImage, setUserImage] = useState("");
  const [rowSelection, setRowSelection] = useState({});
  const [userDetails, setUserDetails] = useState({
    registrationTokens: [],
    userIds: [],
  });
  const [notificationData, setNotificationData] = useState({
    title: "",
    body: "",
  });

  useEffect(() => {
    let userTokens = [];
    if (Object.keys(rowSelection).length > 0) {
      Object.keys(rowSelection).map((userId) => {
        const user = teamData.find((user) => user._id === userId);
        if (user?.deviceToken) userTokens.push(...user?.deviceToken);
      });
      setUserDetails({
        ...userDetails,
        userIds: Object.keys(rowSelection),
        registrationTokens: userTokens,
      });
    }
    // console.info("Row selection", Object.keys(rowSelection));
  }, [rowSelection]);

  useEffect(() => {
    console.log(userDetails, "user Details");
  }, [userDetails]);

  useEffect(() => {
    fetchTeamData();
  }, []);

  const fetchTeamData = () => {
    callAPI(APIUrl + NetworkConfiguration.GETUSERS, "GET")
      .then((res) => {
        setNotificationData({
          title: "",
          body: "",
        });
        console.log("res", res);
        setTeamData(res.users);
      })
      .catch((err) => console.log("err", err));
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "firstName",
        header: "First Name",
        Header: <i style={{ color: "#31435f" }}>First Name</i>,
      },
      {
        accessorKey: "lastName",
        header: "Last Name",
        Header: <i style={{ color: "#31435f" }}>Last Name</i>,
      },
      {
        accessorKey: "dob",
        header: "DOB",
        Header: <i style={{ color: "#31435f" }}>DOB</i>,
      },
      {
        accessorKey: "phone",
        header: "Mobile Number",
        Header: <i style={{ color: "#31435f" }}>Mobile Number</i>,
      },
      {
        accessorKey: "gender",
        header: "Gender",
        Header: <i style={{ color: "#31435f" }}>Gender</i>,
      },
      {
        accessorKey: "email",
        header: "E-mail",
        Header: <i style={{ color: "#31435f" }}>E-mail</i>,
      },
      {
        accessorKey: "address.state",
        header: "State",
        Header: <i style={{ color: "#31435f" }}>State</i>,
      },
      {
        accessorKey: "address.city",
        header: "City",
        Header: <i style={{ color: "#31435f" }}>City</i>,
      },
      {
        accessorKey: "address.zip_code",
        header: "Zip Code",
        Header: <i style={{ color: "#31435f" }}>Zip_code</i>,
      },
      {
        accessorKey: "companyName",
        header: "Company Name",
        Header: <i style={{ color: "#31435f" }}>Company Name</i>,
      },
      {
        accessorKey: "gstNumber",
        header: "GST Number",
        Header: <i style={{ color: "#31435f" }}>GST Number</i>,
      },

      {
        accessorKey: "imageUrl",
        header: "Image",
        Header: <i style={{ color: "#31435f" }}>Image</i>,
        Cell: ({ cell }) => {
          return (
            cell?.row?.original?.imageUrl && (
              <ImImages
                show={showUserImage}
                onRequestClose={() => setShowUserImage(false)}
                className="eye_icon"
                onClick={() => {
                  setShowUserImage(true);
                  setUserImage(cell.row.original.imageUrl);
                }}
              />
            )
          );
        },
      },
      {
        accessorKey: "createdAt",
        header: "Created At",
        Header: <i style={{ color: "#31435f" }}>Created At</i>,
        Cell: ({ cell }) =>
          moment(cell.row.original.createdAt).format("MM/DD/YYYY , LT"),
      },
    ],
    []
  );
  return (
    <div>
      {/* <Layout> */}
      <div className="middle_container_top_alignment">
        <div className="breadcrumb__styling">
          <div className="path_styling">
            <h3 className="path__header"> Notification</h3>
            {/* <Breadcrumbs /> */}
          </div>
        </div>

        <Button
          text="Send Notification"
          onClick={() => setShowNotificationForm(true)}
          style={{ height: "6vh", width: "10vw", margin: "20px" }}
        />
      </div>
      <Form
        show={showNotificationForm}
        onRequestClose={() => setShowNotificationForm(false)}
      >
        <NotificationForm
          userDetails={userDetails}
          onSubmit={() => {
            setShowNotificationForm(false);
            setRowSelection({});
          }}
        />
      </Form>

      <div className="table_data">
        <MaterialReactTable
          columns={columns}
          data={teamData}
          enableColumnOrdering
          enableGlobalFilter={false}
          enablePagination
          enableRowSelection
          getRowId={(row) => row._id}
          onRowSelectionChange={setRowSelection}
          state={{ rowSelection }}
        />
      </div>

      <PopModal
        show={showUserImage}
        onRequestClose={() => setShowUserImage(false)}
      >
        <img
          src={userImage}
          alt="team_member"
          style={{
            width: "25vw",
            height: "auto",
            textAlign: "center",
          }}
        />
      </PopModal>
      {/* </Layout> */}
    </div>
  );
};

export default Notification;
