import React, { useEffect, useState } from "react";
import Button from "../../library/Button";
import Breadcrumbs from "../../library/Breadcrumbs";
import Layout from "../../component/Layout";
import PopModal from "../../component/PopUpModal";
import ClientForm from "../../forms_components/ClientForm";
import { callAPI } from "../../Network/networkConnection";
import {
  APIUrl,
  NetworkConfiguration,
} from "../../Network/networkConfiguration";
import "./style.css";
import { ImageService } from "../../service/ImageService";
import MyCard from "../../component/MyCard/Card";
import AlertPopUp from "../../component/AlertPopUp";
import { successToast } from "../../helper/toast";

const Clients = () => {
  const [showUserForm, setShowUserForm] = useState(false);
  const [showClients, setShowClients] = useState([]);
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [clientId, setClientId] = useState("");
  const [clientData, setClientData] = useState({
    name: "",
    company: "",
    image: "",
  });
  const [isEdited, setIsEdited] = useState(false);

  const onSubmit = (clientData) => {
    callAPI(
      APIUrl + NetworkConfiguration.CLIENT,
      "POST",
      JSON.stringify(clientData),
      { "Content-Type": "application/json" }
    )
      .then((res) => {
        successToast(res.message);
        console.log("add product response: ", res, clientData?.image);
        ImageService.imageUpload(
          clientData?.image,
          "client",
          res?.response?._id
        ).then(() => {
          setClientData({
            name: "",
            company: "",
            image: "",
          });
          setShowUserForm(false);
          fetchClients();
        });
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    fetchClients();
  }, []);

  const fetchClients = () => {
    callAPI(APIUrl + NetworkConfiguration.CLIENT, "GET")
      .then((res) => {
        setShowClients(res.data);
      })
      .catch((err) => {
        if (err.status === 404) {
          setShowClients([]);
        }
        console.log("error", err);
      });
  };

  const handleDeleteRow = (id) => {
    callAPI(APIUrl + NetworkConfiguration.CLIENT + `/${id}`, "DELETE")
      .then((res) => {
        successToast(res.message);
        fetchClients();
        setClientId("");
        setDeleteAlert(false);
      })
      .catch((err) => console.log("error", err));
  };

  const onClickDelete = (id) => {
    setDeleteAlert(true);
    setClientId(id);
  };

  const onUpdate = (id) => {
    callAPI(
      APIUrl + NetworkConfiguration.CLIENT + `/${clientId}`,
      "PUT",
      JSON.stringify(clientData),
      { "Content-Type": "application/json" }
    )
      .then((res) => {
        console.log(res, "12345678");
        successToast(res.message);
        setIsEdited(false);
        setShowUserForm(false);
        fetchClients();
        if (clientData?.image) {
          ImageService.imageUpload(
            clientData?.image,
            "client",
            res?.result?._id
          )
            .then(() => {
              setClientData({
                name: "",
                company: "",
                image: "",
              });
              fetchClients();
            })
            .catch((err) => console.log(err));
        }
      })
      .catch((err) => console.log(err));
  };

  const onClickEdit = (id, data) => {
    setIsEdited(true);
    setClientId(id);
    setClientData(data);
    setShowUserForm(true);
  };

  return (
    <div>
      {/* <Layout> */}
      <div className="middle_container_top_alignment">
        <div className="breadcrumb__styling">
          <div className="path_styling">
            <h3 className="path__header">Clients</h3>
            {/* <Breadcrumbs /> */}
          </div>
        </div>

        <AlertPopUp
          show={deleteAlert}
          header="Delete Client"
          description="Do you want to delete this client?"
          submitText="Yes"
          cancelText="No"
          onCancelClick={() => setDeleteAlert(false)}
          onSubmitClick={() => handleDeleteRow(clientId)}
        ></AlertPopUp>

        <PopModal
          show={showUserForm}
          onRequestClose={() => setShowUserForm(false)}
        >
          <ClientForm
            onSubmit={onSubmit}
            onUpdate={onUpdate}
            data={clientData}
            setData={setClientData}
            isEdited={isEdited}
          />
        </PopModal>

        <Button
          text="Add Client"
          onClick={() => setShowUserForm(true)}
          style={{ height: "6vh", width: "10vw", margin: "20px" }}
        />
      </div>
      <div className="category__card">
        {showClients?.length > 0 &&
          showClients.map((data) => {
            return (
              <MyCard
                title={data?.name}
                desc={data?.company}
                image={data?.imageUrl}
                onClickDelete={() => onClickDelete(data._id)}
                onClickEdit={() => onClickEdit(data._id, data)}
              />
            );
          })}
      </div>
      {/* </Layout> */}
    </div>
  );
};

export default Clients;
