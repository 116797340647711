import axios from "axios";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { APIUrl } from "../../Network/networkConfiguration";
// Import axios or other API integration method

export default function ListComponents() {
  const [modals, setModals] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${APIUrl}components`);

        if (response) {
          console.log(response, "jkk");
          setModals(response?.data?.data);
        } else {
          toast.error("Failed to create contest");
        }
      } catch (error) {
        console.error("Error:", error);
        toast.error("Failed to create contest");
      }
    };
    fetchData();
  }, []);

  const handleredirect = (id) => {
    navigate(`/Component/edit/${id}`);
  };

  const deleteComponent = async (id) => {
    try {
      const response = await axios.delete(`${APIUrl}delete-component/${id}`);
      if (response) {
        toast.success(`Component deleted succesfully`);
        try {
          const response = await axios.get(`${APIUrl}components`);

          if (response) {
            console.log(response, "jkk");
            setModals(response?.data?.data);
          } else {
            toast.error("Failed to delete Component");
          }
        } catch (error) {
          console.error("Error:", error);
          toast.error("Failed to delete contest");
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4"> Components</h1>
      <table className="w-full border-collapse">
        <thead>
          <tr>
            <th className="border p-2">Name</th>
            <th className="border p-2">Description</th>
            <th className="border p-2">Actions</th>
          </tr>
        </thead>
        <tbody>
          {modals?.map((modal) => (
            <tr key={modal._id}>
              <td className="border p-2">{modal?.name}</td>
              <td className="border p-2">{modal?.description}</td>
              <td className="border p-2">
                <button
                  className="bg-yellow-500 text-white px-4 py-2 rounded hover:bg-yellow-600"
                  onClick={() => handleredirect(modal?._id)}
                >
                  Edit
                </button>
                <button
                  className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 ml-2"
                  onClick={() => deleteComponent(modal?._id)}
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
