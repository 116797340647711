// import { useState } from "react";
import InputField from "../../library/TextField";
import Button from "../../library/Button";
import { useState } from "react";
import "./style.css";

const ClientForm = ({ onSubmit, onUpdate, isEdited, data, setData }) => {
  const [error, setError] = useState({ name: "", company: "", image: "" });

  const onClickSubmit = () => {
    if (validate()) {
      isEdited ? onUpdate(data) : onSubmit(data);
    }
  };
  const setClientName = (e) => {
    setData({ ...data, name: e.target.value });
    setError({ ...error, name: "" });
  };

  const setCompanyName = (e) => {
    setData({ ...data, company: e.target.value });
    setError({ ...error, company: "" });
  };

  const setImage = (e) => {
    setData({ ...data, image: e.target.files[0] });
    setError({ ...error, imageUrl: "" });
  };

  const validate = () => {
    let result = true;
    if (!data.name) {
      setError({ ...error, name: "Client name is required" });
      result = false;
    } else if (!data.company) {
      setError({ ...error, company: "Company name is required" });
      result = false;
    } else if (!data.image) {
      setError({ ...error, imageUrl: "Image is required" });
      result = false;
    }
    return result;
  };
  return (
    <div style={{ width: "350px" }}>
      <h1 className="form__heading">
        {isEdited ? "Edit Client" : "Add Client"}
      </h1>

      <div className="form__data">
        <InputField
          onChange={setClientName}
          label="Client Name"
          // style={{ marginLeft: "20px", height: "6vh", width: "18vw" }}
          value={data.name}
          error={error.name}
        />
      </div>
      <br />

      <div className="form__data">
        <InputField
          onChange={setCompanyName}
          label="Company Name"
          // style={{ marginLeft: "20px", height: "6vh", width: "18vw" }}
          value={data.company}
          error={error.company}
        />
      </div>

      <br />

      <div className="form__data">
        <InputField
          onChange={setImage}
          label="Upload Image"
          type="file"
          accept="image/*"
          style={{ paddingTop: "10px" }}
          error={error.imageUrl}
        />
      </div>

      <Button
        onClick={onClickSubmit}
        text={isEdited ? "Save Changes" : "Add Client"}
        style={{
          width: "12vw",
          height: "7vh",
          borderRadius: "5px",
          margin: "10px auto",
        }}
      />
    </div>
  );
};

export default ClientForm;
