import React from "react";
import "./DashboardCard.css";

function DashboardCard({ title, desc, icon, onClick }) {
  return (
    <div className="dashboardCard" onClick={onClick}>
      <div className="dashboardCard__details">
        {title ? <p className="dashboardCard__title">{title}</p> : null}
        {desc ? <p className="dashboardCard__desc">{desc}</p> : null}
      </div>
      {icon ? <div className="dashboardCard__iconWrapper">{icon}</div> : null}
    </div>
    // </div>
  );
}

export default DashboardCard;
