import React, { useState, useRef, useEffect } from "react";
import Profile from "../../assets/images/profile.png";
import PopMenu from "../PopUpMenu";
import "./style.css";

const Navbar = () => {
  const [popOpen, setPopOpen] = useState(false);
  const myRef = useRef();

  const handleProfileClick = () => {
    setPopOpen(true);
  };

  const handleClickOutside = (e) => {
    console.log(e.target.className, "567890");
    if (
      !myRef.current.contains(e.target) &&
      e.target.className !== "logout_para"
    ) {
      setPopOpen(false);
    }
  };

  useEffect(() => {
    if (popOpen) {
      document.addEventListener("mousedown", handleClickOutside);
      return () =>
        document.removeEventListener("mousedown", handleClickOutside);
    }
  }, [popOpen]);

  return (
    <div className="navbar__container">
      <PopMenu popOpen={popOpen}>
        <div ref={myRef} onClick={handleProfileClick}>
          <img src={Profile} alt="profile" className="profile" />
        </div>
      </PopMenu>
    </div>
  );
};

export default Navbar;
