import { useEffect, useMemo, useState } from "react";
import Form from "../../component/Form";
import Layout from "../../component/Layout";
import Button from "../../library/Button";
import TestimonialForm from "../../forms_components/TestimonialForm";
import Breadcrumbs from "../../library/Breadcrumbs";
import { Edit as EditIcon, Delete as DeleteIcon } from "@mui/icons-material";
import { Box, IconButton, Button as MaterialButton } from "@mui/material";
import { callAPI } from "../../Network/networkConnection";
import {
  APIUrl,
  NetworkConfiguration,
} from "../../Network/networkConfiguration";
import MaterialReactTable from "material-react-table";
import "./style.css";
import moment from "moment/moment";
import AlertPopUp from "../../component/AlertPopUp";
import { successToast } from "../../helper/toast";
import PopModal from "../../component/PopUpModal";
import { mkConfig, generateCsv, download } from "export-to-csv";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

const Testimonial = () => {
  const [showUserForm, setShowUserForm] = useState(false);
  const [showTestimonialMessage, setShowTestimonialMessage] = useState(false);
  const [TestimonialList, setTestimonialList] = useState([]);
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [testimomialId, setTestimomialId] = useState("");
  const [testimonialMessage, setTestimonialMessage] = useState("");
  const [testimonialData, setTestimonialData] = useState({
    name: "",
    message: "",
    company: "",
  });

  useEffect(() => {
    fetch();
  }, []);

  const fetch = () => {
    callAPI(APIUrl + NetworkConfiguration.TESTIMONIALS, "GET")
      .then((res) => {
        setTestimonialList(res.data);
      })
      .catch((err) => {
        if (err.status === 404) {
          setTestimonialList([]);
        }
        console.log("error", err);
      });
  };

  const handleSaveRow = async ({ exitEditingMode, row, values }) => {
    callAPI(
      APIUrl + NetworkConfiguration.TESTIMONIAL + "/" + row.original._id,
      "PUT",
      JSON.stringify(values),
      { "Content-Type": "application/json" }
    )
      .then((res) => {
        successToast(res.message);
        fetch();
        return exitEditingMode();
      })
      .catch((err) => console.log(err));
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "name", //simple recommended way to define a column
        header: "Person Name",
        Header: <i style={{ color: "#31435f" }}>Person Name</i>,
      },
      {
        accessorKey: "message", //id required if you use accessorFn instead of accessorKey
        header: "Message",
        Header: <i style={{ color: "#31435f" }}>Message</i>,
        Cell: ({ cell }) => {
          return (
            <p
              onClick={() => {
                setShowTestimonialMessage(true);
                setTestimonialMessage(cell.row.original?.message);
              }}
              className="message__hover"
            >
              {cell.row.original?.message}
            </p>
          );
        },
      },
      {
        // accessorFn: (originalRow) => originalRow.email, //alternate way
        accessorKey: "company", //id required if you use accessorFn instead of accessorKey
        header: "Company",
        Header: <i style={{ color: "#31435f" }}>Company</i>,
      },
      {
        // accessorFn: (originalRow) => originalRow.email, //alternate way
        accessorKey: "createdAt", //id required if you use accessorFn instead of accessorKey
        header: "Created At",
        Header: <i style={{ color: "#31435f" }}>Created At</i>,
        Cell: ({ cell }) =>
          moment(cell.row.original.createdAt).format("MM/DD/YYYY , LT"),
      },
    ],
    []
  );

  const onSubmit = (testimonialData) => {
    let data = testimonialData;

    callAPI(
      APIUrl + NetworkConfiguration.TESTIMONIALS,
      "POST",
      JSON.stringify(data),
      { "Content-Type": "application/json" }
    )
      .then((res) => {
        setTestimonialData({
          name: "",
          message: "",
          company: "",
        });
        successToast(res.message);
        fetch();
        setShowUserForm(false);
      })
      .catch((err) => console.log(err));
  };

  const handleDeleteRow = (id) => {
    callAPI(APIUrl + NetworkConfiguration.TESTIMONIAL + "/" + id, "DELETE")
      .then((res) => {
        successToast(res.message);
        fetch();
        setTestimomialId("");
        setDeleteAlert(false);
      })
      .catch((err) => console.log(err));
  };

  const onClickDelete = (id) => {
    setDeleteAlert(true);
    setTestimomialId(id);
  };

  const csvConfig = mkConfig({
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: true,
    // headers: columns.map((c) => c.header),
  });

  const handleExportRows = (rows) => {
    const csv = generateCsv(csvConfig)(rows.map((row) => row.original));
    download(csvConfig)(csv);
  };

  const handleExportData = () => {
    const csv = generateCsv(csvConfig)(TestimonialList);
    download(csvConfig)(csv);
  };

  return (
    <div>
      {/* <Layout> */}
      <div className="middle_container_top_alignment">
        <div className="breadcrumb__styling">
          <div className="path_styling">
            <h3 className="path__header">Testimonial</h3>
            {/* <Breadcrumbs /> */}
          </div>
        </div>

        <AlertPopUp
          show={deleteAlert}
          header="Delete Testimonial"
          description="Do you want to delete this Testimonial?"
          submitText="Yes"
          cancelText="No"
          onCancelClick={() => setDeleteAlert(false)}
          onSubmitClick={() => handleDeleteRow(testimomialId)}
        ></AlertPopUp>

        <PopModal
          show={showTestimonialMessage}
          onRequestClose={() => setShowTestimonialMessage(false)}
        >
          <div
            style={{
              width: "30rem",
              height: "auto",
              overflow: "scroll",
              padding: ".5rem",
              textAlign: "justify",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {testimonialMessage}
          </div>
        </PopModal>

        <Button
          text="Add Testimonial"
          onClick={() => setShowUserForm(true)}
          style={{ height: "6vh", width: "12vw", margin: "20px" }}
        />
      </div>
      <Form show={showUserForm} onRequestClose={() => setShowUserForm(false)}>
        <TestimonialForm
          onSubmit={onSubmit}
          testimonialData={testimonialData}
          setTestimonialData={setTestimonialData}
        />
      </Form>

      <div className="table_data">
        <MaterialReactTable
          columns={columns}
          data={TestimonialList}
          enableRowNumbers={true}
          enableColumnOrdering
          enableGlobalFilter={false} //turn off a feature
          enableRowActions
          enableRowSelection
          onEditingRowSave={handleSaveRow}
          renderRowActions={({ row, table }) => (
            <Box sx={{ display: "flex", flexWrap: "nowrap" }}>
              <IconButton
                style={{ color: "#1468af", marginLeft: "-6px" }}
                onClick={() => {
                  table.setEditingRow(row);
                }}
              >
                <EditIcon />
              </IconButton>
              <IconButton color="error">
                <DeleteIcon onClick={() => onClickDelete(row.original._id)} />
              </IconButton>
            </Box>
          )}
          renderTopToolbarCustomActions={({ table }) => (
            <Box
              sx={{
                display: "flex",
                gap: "1rem",
                p: "0.5rem",
                flexWrap: "wrap",
              }}
            >
              <MaterialButton
                color="primary"
                //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                onClick={handleExportData}
                startIcon={<FileDownloadIcon />}
                variant="contained"
              >
                Export All Data
              </MaterialButton>
              <MaterialButton
                disabled={table.getPrePaginationRowModel().rows.length === 0}
                //export all rows, including from the next page, (still respects filtering and sorting)
                onClick={() =>
                  handleExportRows(table.getPrePaginationRowModel().rows)
                }
                startIcon={<FileDownloadIcon />}
                variant="contained"
              >
                Export All Rows
              </MaterialButton>
              <MaterialButton
                disabled={table.getRowModel().rows.length === 0}
                //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
                onClick={() => handleExportRows(table.getRowModel().rows)}
                startIcon={<FileDownloadIcon />}
                variant="contained"
              >
                Export Page Rows
              </MaterialButton>
              <MaterialButton
                disabled={
                  !table.getIsSomeRowsSelected() &&
                  !table.getIsAllRowsSelected()
                }
                //only export selected rows
                onClick={() =>
                  handleExportRows(table.getSelectedRowModel().rows)
                }
                startIcon={<FileDownloadIcon />}
                variant="contained"
              >
                Export Selected Rows
              </MaterialButton>
            </Box>
          )}
        />
      </div>
      {/* </Layout> */}
    </div>
  );
};

export default Testimonial;
