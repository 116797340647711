import { create } from "zustand";

// Create a Zustand store
const useUserStore = create((set) => ({
  user: {
    username: "",
    password: "",
  },

  setUser: (newUser) =>
    set((state) => ({ user: { ...state.user, ...newUser } })),
  clearUser: () => set({ user: { username: "", password: "" } }),
}));

export default useUserStore;
