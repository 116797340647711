import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
import Button from "../../library/Button";
import InputField from "../../library/TextField";
import "./style.css";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { callAPI } from "../../Network/networkConnection";
import {
  APIUrl,
  NetworkConfiguration,
} from "../../Network/networkConfiguration";
import { errorToast, successToast } from "../../helper/toast";

const NewPassword = () => {
  const email = localStorage.getItem("email");
  console.log("Email: " + email);
  const navigate = useNavigate();
  const [newpassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const onSubmit = (e) => {
    e.preventDefault();
    if (newpassword === "") {
      errorToast("Please fill out your new password");
      return;
    } else if (confirmPassword === "") {
      errorToast("Please fill out your confirm password");
      return;
    }
    callAPI(
      APIUrl + NetworkConfiguration.EDITPASSWORD,
      "POST",
      JSON.stringify({
        email: email,
        password: newpassword,
        confirmPassword: confirmPassword,
      }),
      { "Content-Type": "application/json" }
    )
      .then((res) => {
        successToast("Your password has been changed successfully");
        localStorage.removeItem("email");
        navigate("/");
      })
      .catch((err) => {
        errorToast("Passwords do not match");
      });
    console.log("New Passwords", newpassword);
  };

  const [eye, setEye] = useState(true);
  const [eye2, setEye2] = useState(true);

  const eyeIcon = () => {
    return eye ? <BsFillEyeFill /> : <BsFillEyeSlashFill />;
  };

  const eyeIcon2 = () => {
    return eye2 ? <BsFillEyeFill /> : <BsFillEyeSlashFill />;
  };

  const inputType = () => {
    return eye ? "password" : "text";
  };

  const inputType2 = () => {
    return eye2 ? "password" : "text";
  };

  const toHideShowPassword = () => {
    setEye(!eye);
  };

  const toHideShowPassword2 = () => {
    setEye2(!eye2);
  };
  return (
    <div className="page_container">
      <div style={{ height: "55vh" }} className="login_container">
        <form>
          <h3>Reset Password</h3>
          {/* <p>Reset your password </p> */}

          <label className="new__password">New Password</label>
          <div>
            <InputField
              value={newpassword}
              onChange={(e) => setNewPassword(e.target.value)}
              type={inputType()}
              icon={eyeIcon()}
              onEyeClick={toHideShowPassword}
              // error={error.username}
            />
          </div>
          <br />

          <label className="confirm__password">Confirm Password</label>
          <div>
            <InputField
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              // setAuthLocalStorage
              type={inputType2()}
              icon={eyeIcon2()}
              onEyeClick={toHideShowPassword2}
              // error={error.password}
            />
          </div>
          <br />

          <Button
            onClick={(e) => onSubmit(e)}
            text="Reset Password"
            style={{
              height: "7vh",
              width: "12vw",
              // marginLeft: "8vw",
              // marginBottom: "2vh",
            }}
          />
          <br />
        </form>
      </div>
    </div>
  );
};

export default NewPassword;
