import "./style.css";

const Form = ({ children, show, onRequestClose }) => {
  return (
    <div className="form" style={{ display: show ? "flex" : "none" }}>
      <div className="form__backdrop" onClick={onRequestClose}></div>
      <div className="form__container">{children} </div>
    </div>
  );
};

export default Form;
