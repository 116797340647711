import "./style.css";

const Button = (props) => {
  return (
    <button onClick={props.onClick} style={props.style} className="btn_style">
      <span>{props.text}</span>
    </button>
  );
};

export default Button;
