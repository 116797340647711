import { useState } from "react";
import Button from "../../library/Button";
import InputField from "../../library/TextField";
import "./style.css";

const BannerForm = ({ onSubmit, onUpdate, data, isEdited, setData }) => {
  const [error, setError] = useState({
    bannerName: "",
    imageUrl: "",
  });
  const onClickSubmit = () => {
    if (validate()) {
      isEdited ? onUpdate(data) : onSubmit(data);
    }
  };

  const setBannerName = (e) => {
    setData({ ...data, bannerName: e.target.value });
    setError({ ...error, bannerName: "" });
  };

  const setBannerImage = (e) => {
    setData({ ...data, imageUrl: e.target.files[0] });
    setError({ ...error, imageUrl: "" });
  };

  const validate = () => {
    let result = true;
    if (!data.bannerName) {
      setError({ ...error, bannerName: "Banner name is required" });
      result = false;
    } else if (!data.imageUrl) {
      setError({ ...error, imageUrl: "Image is required" });
      result = false;
    }
    return result;
  };

  return (
    <div style={{ width: "350px" }}>
      <h1 className="form__heading">
        {isEdited ? "Edit Banner" : "Add Banner"}
      </h1>

      <div className="form__data">
        <InputField
          onChange={setBannerName}
          label="Enter Banner Name"
          value={data.bannerName}
          error={error.bannerName}
        />
      </div>
      <br />
      <div className="form__data">
        <InputField
          onChange={setBannerImage}
          type="file"
          accept="image/*"
          label="Upload Banner Image"
          style={{ paddingTop: "8px" }}
          error={error.imageUrl}
        />
      </div>

      <Button
        onClick={onClickSubmit}
        text={isEdited ? "Save Changes" : "Add Banner"}
        style={{
          width: "12vw",
          height: "7vh",
          borderRadius: "5px",
          margin: "15px auto",
        }}
      />
    </div>
  );
};

export default BannerForm;
