import { useEffect, useState } from "react";
import Button from "../../library/Button";
import Dropdown from "../../library/DropDown";
import InputField from "../../library/TextField";
import "./style.css";
import TextArea from "../../library/TextArea";
import { callAPI } from "../../Network/networkConnection";
import {
  APIUrl,
  NetworkConfiguration,
} from "../../Network/networkConfiguration";
import Switch from "../../library/Switch";

const initialProductState = {
  productName: "",
  productPrice: "",
  productOfferPrice: "",
  openFiles: "",
  format: "",
  productCategory: "",
  productSubCategory: "",
  productDescription: "",
  image: "",
  imageUrl: [],
  tags: "",
  isFeatured: false,
};

const Product = ({ onSubmit, data, isEdited, onUpdate }) => {
  const [productData, setProductData] = useState(initialProductState);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [error, setError] = useState({
    productName: "",
    productDescription: "",
    productPrice: "",
    productOfferPrice: "",
    openFiles: "",
    format: "",
    productCategory: "",
    productSubCategory: "",
    image: "",
    imageUrl: "",
    tags: "",
  });

  const setProductName = (e) => {
    setProductData({ ...productData, productName: e.target.value });
    setError({ ...error, productName: "" });
  };

  const setProductDescription = (e) => {
    setProductData({ ...productData, productDescription: e.target.value });
    setError({ ...error, productDescription: "" });
  };

  const setProductPrice = (e) => {
    setProductData({ ...productData, productPrice: e.target.value });
    setError({ ...error, productPrice: "" });
  };

  const setProductOfferPrice = (e) => {
    if (+e.target.value < +productData?.productPrice) {
      setProductData({ ...productData, productOfferPrice: e.target.value });
      setError({ ...error, productOfferPrice: "" });
    } else {
      setError({
        ...error,
        productOfferPrice: "Offer price should be less than actual price.",
      });
    }
  };

  const setProductOpenFiles = (e) => {
    setProductData({ ...productData, openFiles: e.target.value });
    setError({ ...error, openFiles: "" });
  };

  const setProductFormat = (e) => {
    setProductData({ ...productData, format: e.target.value });
    setError({ ...error, format: "" });
  };

  const setCategoryName = (e) => {
    setProductData({ ...productData, productCategory: e.target.value });
    fetchSubCategories(e.target.value);
    setError({ ...error, productCategory: "" });
  };

  const setSubCategory = (e) => {
    setProductData({ ...productData, productSubCategory: e.target.value });
    setError({ ...error, productSubCategory: "" });
  };

  const setImage = (e) => {
    setProductData({ ...productData, image: e.target.files[0] });
    setError({ ...error, image: "" });
  };

  const setTags = (e) => {
    setProductData({ ...productData, tags: e.target.value });
    setError({ ...error, tags: "" });
  };

  const onClickSubmit = () => {
    if (validate()) {
      isEdited ? onUpdate(productData) : onSubmit(productData);
    }
  };

  const validate = () => {
    let result = true;
    if (!productData.productName) {
      setError({ ...error, productName: "Product Name is required" });
      result = false;
    } else if (!productData.productDescription) {
      setError({
        ...error,
        productDescription: "Product Description is required",
      });
      result = false;
    } else if (!productData.productPrice) {
      setError({ ...error, productPrice: "Product Price is required" });
      result = false;
    } else if (!productData.productOfferPrice) {
      setError({
        ...error,
        productOfferPrice: "Product Offer Price is required",
      });
      result = false;
    } else if (!productData.openFiles) {
      setError({
        ...error,
        openFiles: "Open files Price is required",
      });
      result = false;
    } else if (!productData.format) {
      setError({
        ...error,
        format: "Product Foramt is required",
      });
      result = false;
    } else if (!productData.productCategory) {
      setError({ ...error, productCategory: "Product Category is required" });
      result = false;
    } else if (!productData.productSubCategory) {
      setError({
        ...error,
        productSubCategory: "Product Sub Category is required",
      });
      result = false;
    } else if (!productData.image) {
      setError({ ...error, image: "Image is required" });
      result = false;
    } else if (!productData.tags) {
      setError({ ...error, tags: "Tags is required" });
      result = false;
    }
    return result;
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  useEffect(() => {
    if (isEdited) {
      setProductData({
        productName: data?.productName,
        productPrice: data?.productPrice,
        openFiles: data?.openFiles,
        format: data?.format,
        productCategory: data?.productCategory,
        productSubCategory: data?.productSubCategory,
        productOfferPrice: data?.productOfferPrice,
        productDescription: data?.productDescription,
        image: data?.image,
        imageUrl: data?.imageUrl,
        tags: data?.tags,
        isFeatured: data?.isFeatured,
        id: data?.id,
      });
      // setProductCategory(data?.productCategory);
    } else {
      setProductData(initialProductState);
    }
  }, [data, isEdited]);

  console.log({ productData });

  const convertItemsForDropdown = (items) => {
    let dropdownItems = [
      {
        name: "Any",
        value: "",
      },
    ];

    for (let i = 0; i < items.length; i++) {
      dropdownItems.push({
        name: items[i]?.categoryName,
        value: items[i]?._id,
      });
    }

    return dropdownItems;
  };

  const fetchCategories = () => {
    callAPI(APIUrl + NetworkConfiguration.GETCATEGORIES, "GET")
      .then((res) => {
        console.log("Categories", res);
        setCategories(convertItemsForDropdown(res.categories));
      })
      .catch((err) => console.log("error", err));
  };

  const convertItemsForSubCategories = (options) => {
    let subCategoryItems = [
      {
        name: "Any",
        value: "",
      },
    ];

    for (let i = 0; i < options.length; i++) {
      subCategoryItems.push({
        name: options[i]?.subCategoryName,
        value: options[i]?._id,
      });
    }

    return subCategoryItems;
  };

  const fetchSubCategories = (selectedCategory) => {
    callAPI(
      APIUrl +
        NetworkConfiguration.SUBCATEGORYBYCATEGORY +
        `/${selectedCategory}`,
      "GET"
    )
      .then((res) => {
        console.log("SubCategories", res);
        setSubCategories(convertItemsForSubCategories(res?.subCategories));
      })
      .catch((err) => console.log("error", err));
  };

  return (
    <div
      className="product_form_container"
      style={{ height: "auto", marginBottom: "10px" }}
    >
      <h1 className="form__heading">
        {isEdited ? "Update Product" : "Add Product"}
      </h1>

      <div className="form__data">
        <InputField
          value={productData?.productName}
          onChange={setProductName}
          label="Product Name"
          placeholder="Enter Product Name"
          // style={{ marginLeft: "20px", height: "6vh", width: "18vw" }}
          error={error.productName}
        />
      </div>

      <div className="form__data">
        <TextArea
          value={productData?.productDescription}
          onChange={setProductDescription}
          label="Product Description"
          style={{ height: "10vh", width: "20rem" }}
          placeholder="Add product description here...."
          error={error.productDescription}
        />
      </div>

      <div className="form__data">
        <InputField
          value={productData?.productPrice}
          onChange={setProductPrice}
          label="Product Price"
          type="Number"
          placeholder="Enter Price"
          // style={{ marginLeft: "20px", height: "6vh", width: "18vw" }}
          error={error.productPrice}
        />
      </div>

      <div className="form__data">
        <InputField
          value={productData?.productOfferPrice}
          onChange={setProductOfferPrice}
          label="Offer Price"
          type="Number"
          placeholder="Enter Price"
          // style={{ marginLeft: "20px", height: "6vh", width: "18vw" }}
          error={error.productOfferPrice}
        />
      </div>

      <div className="form__data">
        <InputField
          value={productData?.openFiles}
          onChange={setProductOpenFiles}
          label="Open Files"
          type="text"
          placeholder="Enter Open files"
          // style={{ marginLeft: "20px", height: "6vh", width: "18vw" }}
          error={error.openFiles}
        />
      </div>

      <div className="form__data">
        <InputField
          value={productData?.format}
          onChange={setProductFormat}
          label="Product Format"
          type="text"
          placeholder="Enter Format (with comma(,) separated)"
          // style={{ marginLeft: "20px", height: "6vh", width: "18vw" }}
          error={error.format}
        />
      </div>

      <div className="form__data">
        <Dropdown
          selectedValue={productData?.productCategory}
          onChange={setCategoryName}
          label="Product Category"
          options={categories}
          // style={{ width: "18vw" }}
          error={error.productCategory}
        />
      </div>

      <div className="form__data">
        <Dropdown
          selectedValue={productData?.productSubCategory}
          onChange={setSubCategory}
          label="Product Sub-Category"
          options={subCategories}
          // style={{ width: "18vw" }}
          error={error.productSubCategory}
        />
      </div>

      <div className="form__data">
        <InputField
          onChange={setImage}
          label="Upload Main Image"
          type="file"
          style={{
            paddingTop: "9px",
          }}
          error={error.image}
          accept="image/*"
        />
        {productData?.image ? (
          <a rel="noreferrer" href={productData?.image} target="_blank">
            selected image
          </a>
        ) : null}
      </div>

      <div className="form__data">
        <InputField
          onChange={(e) => {
            setProductData({
              ...productData,
              imageUrl: Object.values(e.target.files),
            });
          }}
          label="Upload Multiple Images"
          type="file"
          multiple={true}
          accept="image/*"
          style={{
            paddingTop: "9px",
          }}
        />
        {productData?.imageUrl.length > 0 ? (
          <a rel="noreferrer" href={productData?.imageUrl} target="_blank">
            selected image
          </a>
        ) : null}
      </div>

      <div className="form__data">
        <InputField
          value={productData?.tags}
          onChange={setTags}
          label="Tags"
          placeholder="Ex- #products, #3dassets"
          error={error.tags}
        />
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h3 className="featured_style">Is Featured</h3>
        <Switch
          checked={productData?.isFeatured}
          onChange={(e) => {
            setProductData({ ...productData, isFeatured: e.target.checked });
          }}
        />
      </div>
      <br />

      <div className="form__data">
        <Button
          onClick={onClickSubmit}
          text={isEdited ? "Save Changes" : "Add Product"}
          style={{
            width: "8vw",
            height: "6vh",
            borderRadius: "5px",
            margin: "auto",
            padding: "8px",
          }}
        />
      </div>
      <br />
    </div>
  );
};

export default Product;
