import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { APIUrl } from "../../../Network/networkConfiguration";
import { toast } from "react-toastify";

export default function ListContests() {
  const [contests, setContests] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchContests = async () => {
      try {
        const response = await axios.get(`${APIUrl}contest`);

        setContests(response.data.data);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchContests();
  }, []);

  const handleDelete = async (id) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this contest?"
    );
    if (confirmDelete) {
      try {
        const response = await axios.delete(`${APIUrl}contest/${id}`);

        if (response) {
          toast.success("Contest deleted successfully");
          try {
            const response = await axios.get(`${APIUrl}contest`);

            setContests(response.data.data);
          } catch (error) {
            console.error("Error:", error);
          }
        } else {
          toast.error("Failed to delete contest");
        }
      } catch (error) {
        console.error("Error:", error);
        toast.error("Failed to delete contest");
      }
    }
  };

  return (
    <div className="max-w-4xl mx-auto p-8">
      <h1 className="text-2xl font-bold mb-4">Contests List</h1>
      <Link
        to="/Component/contest/add"
        className="mb-4 inline-block px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-700"
      >
        Add New Contest
      </Link>
      <table className="min-w-full bg-white border border-gray-300">
        <thead>
          <tr>
            <th className="py-2 px-4 border-b">Title</th>
            <th className="py-2 px-4 border-b">Description</th>
            <th className="py-2 px-4 border-b">Prize Money</th>
            <th className="py-2 px-4 border-b">Actions</th>
          </tr>
        </thead>
        <tbody>
          {contests?.map((contest) => (
            <tr key={contest?.id}>
              <td className="py-2 px-4 border-b">{contest?.title}</td>
              <td className="py-2 px-4 border-b">{contest?.description}</td>
              <td className="py-2 px-4 border-b">{contest?.priceMoney}</td>
              <td className="py-2 px-4 border-b">
                <Link
                  to={`/Component/contest/edit/${contest?._id}`}
                  className="text-blue-500 hover:underline mr-4"
                >
                  Edit
                </Link>
                <button
                  onClick={() => handleDelete(contest?._id)}
                  className="text-red-500 hover:underline"
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
