// import { useState } from "react";
import InputField from "../../library/TextField";
import Button from "../../library/Button";
import "./style.css";
import { useState } from "react";

const EventForm = ({ onSubmit, onUpdate, isEdited, data, setData }) => {
  const [error, setError] = useState({
    eventName: "",
    imageUrl: "",
  });

  const setEventName = (e) => {
    setData({ ...data, eventName: e.target.value });
    setError({ ...error, eventName: "" });
  };

  const setImageUrl = (e) => {
    setData({ ...data, imageUrl: e.target.files[0] });
    setError({ ...error, imageUrl: "" });
  };

  const onClickSubmit = () => {
    if (validate()) {
      isEdited ? onUpdate(data) : onSubmit(data);
    }
  };

  const validate = () => {
    let result = true;
    if (!data.eventName) {
      setError({ ...error, eventName: "Event name is required" });
      result = false;
    } else if (!data.imageUrl) {
      setError({ ...error, imageUrl: "Image is required" });
      result = false;
    }
    return result;
  };

  return (
    <div style={{ width: "350px" }}>
      <h1 className="form__heading">{isEdited ? "Edit Event" : "Add Event"}</h1>
      <br />

      <div className="form__data">
        <InputField
          value={data.eventName}
          onChange={setEventName}
          label="Event Name"
          error={error.eventName}
        />
      </div>
      <br />

      <div className="form__data">
        <InputField
          onChange={setImageUrl}
          label="Upload Picture"
          type="file"
          accept="image/*"
          style={{ paddingTop: "10px" }}
          error={error.imageUrl}
        />
      </div>
      <Button
        onClick={onClickSubmit}
        text={isEdited ? "Save Changes" : "Add Event"}
        style={{
          width: "12vw",
          height: "7vh",
          borderRadius: "5px",
          margin: "15px auto",
        }}
      />
    </div>
  );
};

export default EventForm;
