import { useState } from "react";
import axios from "axios";
import { APIUrl } from "../../../Network/networkConfiguration";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const AddPoll = () => {
  const [question, setQuestion] = useState("");
  const [choices, setChoices] = useState([{ value: "", isCorrect: false }]);
  const navigate = useNavigate();

  const handleChoiceChange = (index, value) => {
    const updatedChoices = [...choices];
    updatedChoices[index].value = value;
    setChoices(updatedChoices);
  };

  const handleAddChoice = () => {
    setChoices([...choices, { value: "", isCorrect: false }]);
  };

  const handleRemoveChoice = (index) => {
    const updatedChoices = choices.filter((_, i) => i !== index);
    setChoices(updatedChoices);
  };

  const handleCorrectAnswerChange = (index) => {
    const updatedChoices = choices.map((choice, i) => ({
      ...choice,
      isCorrect: i === index,
    }));
    setChoices(updatedChoices);
  };

  const handleSubmit = async () => {
    const pollData = {
      question,
      choices,
    };

    try {
      await axios.post(`${APIUrl}poll`, pollData);
      toast.success("Poll created successfully");
      navigate("/Component/polls/list");
    } catch (error) {
      toast.error("Failed to create poll");
    }
  };

  const handleredirect = () => {
    navigate(`/Component/polls/list`);
  };

  return (
    <div className="container relative mx-auto p-4">
      <h1 className="text-2xl font-bold">Add Poll</h1>
      <div className="absolute right-4 top-10">
        <button onClick={handleredirect}>
          <h3 className="p-2 bg-blue-500 rounded-xl px-5 text-white">List</h3>
        </button>
      </div>
      <div className="mt-4">
        <label className="block mb-2">Poll Question</label>
        <input
          type="text"
          className="border p-2 w-full"
          value={question}
          onChange={(e) => setQuestion(e.target.value)}
        />

        <label className="block mt-4 mb-2">Poll Options</label>
        {choices?.map((choice, index) => (
          <div key={index} className="flex items-center mb-2">
            <input
              type="text"
              className="border p-2 w-full"
              value={choice.value}
              onChange={(e) => handleChoiceChange(index, e.target.value)}
            />
            {/* <button
              onClick={() => handleCorrectAnswerChange(index)}
              className={`ml-2 p-2 ${
                choice.isCorrect ? "bg-green-500" : "bg-gray-300"
              } text-white rounded`}
            >
              Correct
            </button> */}
            {choices.length > 1 && (
              <button
                onClick={() => handleRemoveChoice(index)}
                className="ml-2 p-2 bg-red-500 text-white rounded"
              >
                Remove
              </button>
            )}
          </div>
        ))}
        <button
          onClick={handleAddChoice}
          className="mt-4 bg-blue-500 text-white p-2 rounded"
        >
          Add Option
        </button>
        <br />

        <button
          onClick={handleSubmit}
          className="mt-4 bg-blue-500 text-white p-2 rounded"
        >
          Submit Poll
        </button>
      </div>
    </div>
  );
};

export default AddPoll;
