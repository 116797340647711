import { useEffect, useState } from "react";
import "./style.css";
import InputField from "../../library/TextField";
import Button from "../../library/Button";

const TechnologyForm = ({ onSubmit, onUpdate, data, isEdited, setData }) => {
  const [error, setError] = useState({
    name: "",
  });
  useEffect(() => {
    setData(data);
  }, [data]);

  const onClickSubmit = () => {
    if (validate()) {
      isEdited ? onUpdate(data) : onSubmit(data);
    }
  };

  const validate = () => {
    let result = true;
    if (!data.name) {
      setError({ ...error, name: "Technology name is required" });
      result = false;
    }
    return result;
  };

  const setTechName = (e) => {
    setData({ ...data, name: e.target.value });
    setError({ ...error, name: "" });
  };

  return (
    <div style={{ width: "350px" }}>
      <h1 className="form__heading">
        {isEdited ? "Edit Technology" : "Add Technology"}
      </h1>
      <br />
      <div className="form__data">
        <InputField
          value={data.name}
          onChange={setTechName}
          label="Technology Name"
          // style={{ marginLeft: "20px", height: "6vh", width: "18vw" }}
          error={error.name}
        />
      </div>

      <Button
        onClick={onClickSubmit}
        // onClick={() => onSubmit(technologyData)}
        text={isEdited ? "Save Changes" : "Add Technology"}
        style={{
          width: "12vw",
          height: "7vh",
          borderRadius: "5px",
          margin: "15px auto",
        }}
      />
    </div>
  );
};

export default TechnologyForm;
