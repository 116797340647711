import Layout from "../../component/Layout";
import Breadcrumbs from "../../library/Breadcrumbs";
import Button from "../../library/Button";
import { useEffect, useState } from "react";
import CategoryForm from "../../forms_components/categoryForm";
import PopModal from "../../component/PopUpModal";
import "./style.css";
import { callAPI } from "../../Network/networkConnection";
import {
  APIUrl,
  NetworkConfiguration,
} from "../../Network/networkConfiguration";
import MyCard from "../../component/MyCard/Card";
import AlertPopUp from "../../component/AlertPopUp";
import { ImageService } from "../../service/ImageService";
import { errorToast, successToast } from "../../helper/toast";

const Products = () => {
  const [showUserForm, setShowUserForm] = useState(false);
  const [showCategories, setShowCategories] = useState([]);
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [categoryId, setCategoryId] = useState("");
  const [categoryImage, setCategoryImage] = useState("");
  const [isEdited, setIsEdited] = useState(false);
  const [categoryData, setCategoryData] = useState({
    categoryName: "",
    imageUrl: "",
  });
  const [subCategoryData, setSubCategoryData] = useState({
    subCategoryName: "",
    imageUrl: "",
  });

  useEffect(() => {
    fetchCategories();
  }, []);

  const onSubmit = (categoryData, subCategoryData) => {
    addCategory(categoryData, subCategoryData);
  };

  const addCategory = (categoryData, subCategoryData) => {
    let data = new FormData();
    data.append("categoryName", categoryData.categoryName);
    data.append("image", categoryData.imageUrl);

    callAPI(APIUrl + NetworkConfiguration.ADDCATEGORY, "POST", data)
      .then((res) => {
        setCategoryData({
          categoryName: "",
          imageUrl: null,
        });
        successToast(res.message);
        setShowUserForm(false);
        fetchCategories();
        if (subCategoryData?.subCategoryName && subCategoryData?.imageUrl) {
          addSubCategory(res?.category._id, subCategoryData);
        }
      })
      .catch((err) => console.log(err));
  };

  const addSubCategory = (categoryId, subCategoryData) => {
    let data = new FormData();
    data.append("category", categoryId);
    data.append("subCategoryName", subCategoryData.subCategoryName);
    data.append("image", subCategoryData.imageUrl);

    callAPI(APIUrl + NetworkConfiguration.ADDSUBCATEGORY, "POST", data)
      .then((res) => {
        setSubCategoryData({
          subCategoryName: "",
          imageUrl: "",
        });
        successToast(res.message);
        console.log("add subcategory response: ", res);
      })
      .catch((err) => {
        errorToast(" Please enter full details");
        console.log(err, "reject.......");
      });
  };

  const onClickEdit = (id) => {
    setCategoryId(id);
    setIsEdited(true);
    setShowUserForm(true);
  };

  const onUpdate = (categoryData) => {
    // console.log(categoryId, "categoryId");
    categoryData.id = categoryId;
    console.log(categoryData, "category data");
    callAPI(
      APIUrl + NetworkConfiguration.UPDATECATEGORY,
      "PUT",
      JSON.stringify(categoryData),
      { "Content-Type": "application/json" }
    )
      .then((res) => {
        // console.log(res, "---------------");
        // fetchCategories();
        // setShowUserForm(false);

        if (categoryData?.imageUrl) {
          ImageService.imageUpload(categoryData?.imageUrl, "category", res?.id)
            .then(() => {
              setCategoryData({
                categoryName: "",
                imageUrl: "",
              });
              successToast(res.message);
              fetchCategories();
              setShowUserForm(false);
            })
            .catch((err) => {
              errorToast(err.message);
              console.log(err);
            });
        }
      })
      .catch((err) => {
        errorToast(err.message);
        console.log(err);
      });
  };

  const fetchCategories = () => {
    callAPI(APIUrl + NetworkConfiguration.GETCATEGORIES, "GET")
      .then((res) => {
        setShowCategories(res.categories);
        setCategoryData({ categoryName: "", categoryImage: null });
      })
      .catch((err) => {
        if (err.status === 404) {
          setShowCategories([]);
        }
        console.log("error", err);
      });
  };
  const handleDeleteRow = (_id, iconUrl) => {
    callAPI(
      APIUrl + NetworkConfiguration.DELETECATEGORY,
      "DELETE",
      JSON.stringify({
        categoryId: _id,
        iconUrl: iconUrl,
      }),
      { "Content-Type": "application/json" }
    )
      .then((res) => {
        successToast(res.message);
        fetchCategories();
        setDeleteAlert(false);
        setCategoryId("");
        setCategoryImage("");
      })
      .catch((err) => console.log("error", err));
  };

  const onClickDelete = (id) => {
    setDeleteAlert(true);
    setCategoryId(id);
    setCategoryImage(id);
  };

  return (
    <div>
      {/* <Layout> */}
      <div className="middle_container_top_alignment">
        <div className="breadcrumb__styling">
          <div className="path_styling">
            <h3 className="path__header"> Product Categories</h3>
            {/* <Breadcrumbs /> */}
          </div>
        </div>

        <AlertPopUp
          show={deleteAlert}
          header="Delete Category"
          description="Do you want to delete this Category?"
          submitText="Yes"
          cancelText="No"
          onCancelClick={() => setDeleteAlert(false)}
          onSubmitClick={() => {
            handleDeleteRow(categoryId, categoryImage);
          }}
        ></AlertPopUp>

        <PopModal
          show={showUserForm}
          onRequestClose={() => setShowUserForm(false)}
        >
          <CategoryForm
            onSubmit={onSubmit}
            onUpdate={onUpdate}
            data={categoryData}
            isEdited={isEdited}
          />
        </PopModal>

        <Button
          text="Add Category"
          onClick={() => {
            setShowUserForm(true);
            setIsEdited(false);
          }}
          style={{
            height: "6vh",
            width: "10vw",
            margin: "20px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        />
      </div>
      <div style={{ padding: "2rem" }} className="category__card">
        {showCategories?.length > 0 &&
          showCategories.map((category) => {
            return (
              <MyCard
                title={category.categoryName}
                image={category.imageUrl}
                onClickEdit={() => {
                  setCategoryData({
                    categoryName: category.categoryName,
                    imageUrl: category.imageUrl,
                  });
                  onClickEdit(category._id, category.image);
                }}
                onClickDelete={() =>
                  onClickDelete(category._id, category.image)
                }
              />
            );
          })}
      </div>
      {/* </Layout> */}
    </div>
  );
};

export default Products;
