import { useEffect, useState } from "react";
import Button from "../../library/Button";
import InputField from "../../library/TextField";
import "./style.css";
import moment from "moment";

const initialUserState = {
  firstName: "",
  lastName: "",
  dob: "",
  phone: "",
  email: "",
  gender: "",
  address: {
    state: "",
    city: "",
    zip_code: "",
  },
  companyName: "",
  gstNumber: "",
  imageUrl: "",
};

const UserForm = ({ onSubmit, data, isEdited, onUpdate }) => {
  const [userData, setUserData] = useState(initialUserState);
  const [error, setError] = useState({
    firstName: "",
    lastName: "",
    dob: "",
    phone: "",
    email: "",
    gender: "",
    address: {
      state: "",
      city: "",
      zip_code: "",
    },
    companyName: "",
    gstNumber: "",
    imageUrl: "",
  });

  const setFirstName = (e) => {
    setUserData({ ...userData, firstName: e.target.value });
    setError({ ...error, firstName: "" });
  };

  const setLastName = (e) => {
    setUserData({ ...userData, lastName: e.target.value });
    setError({ ...error, lastName: "" });
  };

  const setDob = (e) => {
    setUserData({ ...userData, dob: e.target.value });
    setError({ ...error, dob: "" });
  };

  const setPhone = (e) => {
    setUserData({ ...userData, phone: e.target.value });
    setError({ ...error, phone: "" });
  };

  const setEmail = (e) => {
    setUserData({ ...userData, email: e.target.value });
    setError({ ...error, email: "" });
  };

  const setGender = (e) => {
    setUserData({ ...userData, gender: e.target.value });
    setError({ ...error, gender: "" });
  };

  const setState = (e) => {
    setUserData({ ...userData, address: { state: e.target.value } });
    setError({ ...error, address: { state: "" } });
  };

  const setCity = (e) => {
    setUserData({ ...userData, address: { city: e.target.value } });
    setError({ ...error, address: { city: "" } });
  };

  const setZipCode = (e) => {
    setUserData({ ...userData, address: { zip_code: e.target.value } });
    setError({ ...error, address: { zip_code: "" } });
  };

  const setCompanyName = (e) => {
    setUserData({ ...userData, companyName: e.target.value });
    setError({ ...error, companyName: "" });
  };

  const setGstNumber = (e) => {
    setUserData({ ...userData, gstNumber: e.target.value });
    setError({ ...error, gstNumber: "" });
  };

  const setImage = (e) => {
    setUserData({ ...userData, imageUrl: e.target.files[0] });
    setError({ ...error, imageUrl: "" });
  };

  const onClickSubmit = () => {
    if (validate()) {
      isEdited ? onUpdate(userData) : onSubmit(userData);
    }
  };

  const validate = () => {
    let result = true;
    if (!userData.firstName) {
      setError({ ...error, firstName: "First Name is required" });
      result = false;
    } else if (!userData.lastName) {
      setError({
        ...error,
        lastName: "Last Name is required",
      });
      result = false;
    } else if (!userData.dob) {
      setError({ ...error, dob: "DOB is required" });
      result = false;
    } else if (!userData.phone) {
      setError({
        ...error,
        phone: "Phone is required",
      });
      result = false;
    } else if (!userData.email) {
      setError({ ...error, email: "Email is required" });
      result = false;
    } else if (!userData.gender) {
      setError({
        ...error,
        gender: "Gender is required",
      });
      result = false;
    } else if (!userData.address.state) {
      setError({
        ...error,
        address: { state: "State is required" },
      });
      result = false;
    } else if (!userData.address.city) {
      setError({
        ...error,
        address: { city: "City is required" },
      });
      result = false;
    } else if (!userData.address.zip_code) {
      setError({ ...error, address: { zip_code: "Zip Code is required" } });
      result = false;
    } else if (!userData.imageUrl) {
      setError({ ...error, imageUrl: "Image is required" });
      result = false;
    } else if (!userData.companyName) {
      setError({ ...error, companyName: "Company Name is required" });
      result = false;
    }
    return result;
  };

  useEffect(() => {
    if (isEdited) {
      setUserData({
        firstName: data?.firstName,
        lastName: data?.lastName,
        dob: data?.dob,
        phone: data?.phone,
        email: data?.email,
        gender: data?.gender,
        address: {
          state: data?.address?.state,
          city: data?.address?.city,
          zip_code: data?.address?.zip_code,
        },
        companyName: data?.companyName,
        gstNumber: data?.gstNumber,
        imageUrl: data?.imageUrl,
        id: data?.id,
      });
      // setProductCategory(data?.productCategory);
    } else {
      setUserData(initialUserState);
    }
  }, [data, isEdited]);

  console.log({ userData });

  return (
    <div
      className="product_form_container"
      style={{ height: "auto", marginBottom: "10px" }}
    >
      <h1 className="form__heading">{isEdited ? "Update User" : "Add User"}</h1>

      <div className="form__data">
        <InputField
          value={userData?.firstName}
          onChange={setFirstName}
          label="First Name"
          placeholder="Enter First Name"
          // style={{ marginLeft: "20px", height: "6vh", width: "18vw" }}
          error={error.firstName}
        />
      </div>

      <div className="form__data">
        <InputField
          value={userData?.lastName}
          onChange={setLastName}
          label="Last Name"
          placeholder="Enter Last Name"
          // style={{ marginLeft: "20px", height: "6vh", width: "18vw" }}
          error={error.lastName}
        />
      </div>

      <div className="form__data">
        <InputField
          value={moment(userData?.dob).format("YYYY-MM-DD")}
          onChange={setDob}
          type={"date"}
          label="Date of Birth"
          placeholder="Enter DOB"
          // style={{ marginLeft: "20px", height: "6vh", width: "18vw" }}
          error={error.dob}
        />
      </div>

      <div className="form__data">
        <InputField
          value={userData?.phone}
          onChange={setPhone}
          label="Phone"
          max={10}
          type="Number"
          placeholder="Enter Phone"
          // style={{ marginLeft: "20px", height: "6vh", width: "18vw" }}
          error={error.phone}
        />
      </div>

      <div className="form__data">
        <InputField
          value={userData?.email}
          onChange={setEmail}
          label="Email"
          type="email"
          placeholder="Enter Email"
          // style={{ marginLeft: "20px", height: "6vh", width: "18vw" }}
          error={error.email}
        />
      </div>

      <div className="form__data">
        <InputField
          value={userData?.gender}
          onChange={setGender}
          label="Gender"
          placeholder="Enter Gender"
          // style={{ marginLeft: "20px", height: "6vh", width: "18vw" }}
          error={error.gender}
        />
      </div>

      <div className="form__data">
        <InputField
          value={userData?.address?.state}
          onChange={setState}
          label="State"
          type="text"
          placeholder="Enter State"
          // style={{ marginLeft: "20px", height: "6vh", width: "18vw" }}
          error={error.state}
        />
      </div>

      <div className="form__data">
        <InputField
          value={userData?.address?.city}
          onChange={setCity}
          label="City"
          type="text"
          placeholder="Enter City"
          // style={{ marginLeft: "20px", height: "6vh", width: "18vw" }}
          error={error.city}
        />
      </div>

      <div className="form__data">
        <InputField
          value={userData?.address?.zip_code}
          onChange={setZipCode}
          label="Zip Code"
          type="number"
          max={6}
          placeholder="Enter Zip Code"
          // style={{ marginLeft: "20px", height: "6vh", width: "18vw" }}
          error={error.zip_code}
        />
      </div>

      <div className="form__data">
        <InputField
          value={userData?.companyName}
          onChange={setCompanyName}
          label="Company Name"
          type="text"
          placeholder="Enter Company Name"
          // style={{ marginLeft: "20px", height: "6vh", width: "18vw" }}
          error={error.companyName}
        />
      </div>

      <div className="form__data">
        <InputField
          value={userData?.gstNumber}
          onChange={setGstNumber}
          label="Gst Number"
          type="text"
          placeholder="Enter Gst number"
          // style={{ marginLeft: "20px", height: "6vh", width: "18vw" }}
          error={error.gstNumber}
        />
      </div>

      <div className="form__data">
        <InputField
          onChange={setImage}
          label="Upload Image"
          type="file"
          accept="image/*"
          multiple={true}
          style={{
            paddingTop: "9px",
          }}
          error={error.imageUrl}
        />
        {userData?.imageUrl ? (
          <a rel="noreferrer" href={userData?.imageUrl} target="_blank">
            selected image
          </a>
        ) : null}
      </div>

      <div className="form__data">
        <Button
          onClick={onClickSubmit}
          text={isEdited ? "Save Changes" : "Add User"}
          style={{
            width: "8vw",
            height: "6vh",
            borderRadius: "5px",
            margin: "auto",
            padding: "8px",
          }}
        />
      </div>
      <br />
    </div>
  );
};

export default UserForm;
