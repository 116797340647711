import "./style.css";

const Dropdown = (props) => {
  console.log("Selected value ", props?.selectedValue, props?.options);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      {props?.label ? (
        <label className="label_styling">{props.label}</label>
      ) : null}
      <select
        name="categories"
        className="input-style"
        value={props.selectedValue}
        onChange={props?.onChange}
        style={props.style}
      >
        {props?.options?.map((item, index) => {
          return (
            <option
              key={index}
              value={item.value}
              style={{
                marginTop: "2vh",
                marginBottom: "4vh",
              }}
            >
              {item.name}
            </option>
          );
        })}
      </select>
      {props?.error ? <p className="error_styling">*{props.error}</p> : null}
    </div>
  );
};

export default Dropdown;
