import { useEffect, useMemo, useState } from "react";
import Layout from "../../component/Layout";
import Breadcrumbs from "../../library/Breadcrumbs";
import MaterialReactTable from "material-react-table";
import { callAPI } from "../../Network/networkConnection";
import {
  APIUrl,
  NetworkConfiguration,
} from "../../Network/networkConfiguration";
import "./style.css";
import { ImImages } from "react-icons/im";
// import Image1 from "../../assets/images/AK47.png";
import PopModal from "../../component/PopUpModal";
import { mkConfig, generateCsv, download } from "export-to-csv";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { Box, Button as MaterialButton } from "@mui/material";

const Cart = () => {
  const [cartList, setCartList] = useState([]);
  const [showUserImage, setShowUserImage] = useState(false);

  const [mainImg, setMainImg] = useState("");

  useEffect(() => {
    fetchCart();
  }, []);

  const fetchCart = () => {
    callAPI(APIUrl + NetworkConfiguration.GETCARTS, "GET")
      .then((res) => {
        console.log("cart", res.cart);
        setCartList(res.cart);
      })
      .catch((err) => console.log("error", err));
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "productName",
        header: "Product Name",
        Header: <i style={{ color: "#31435f" }}> Product Name</i>,
      },
      {
        accessorKey: "productPrice",
        header: "Product Price",
        Header: <i style={{ color: "#31435f" }}> Product Price</i>,
      },
      {
        accessorKey: "productOfferPrice",
        header: "Product Offer Price",
        Header: <i style={{ color: "#31435f" }}> Product Offer Price</i>,
      },
      {
        accessorKey: "productMainImgUrl",
        header: "Product Main Image",
        Header: <i style={{ color: "#31435f" }}> Product Main Image</i>,
        Cell: ({ cell }) => {
          return (
            <ImImages
              show={showUserImage}
              onRequestClose={() => setShowUserImage(false)}
              className="eye_icon"
              onClick={() => {
                setMainImg(cell?.row?.original?.productMainImgUrl);
                setShowUserImage(true);
              }}
            />
          );
        },
      },

      {
        accessorKey: "quantity",
        header: "Product Quantity",
        Header: <i style={{ color: "#31435f" }}> Product Quantity</i>,
      },

      {
        accessorKey: "name",
        header: "Customer Name",
        Header: <i style={{ color: "#31435f" }}>Customer Name</i>,
      },
      {
        accessorKey: "phone",
        header: "Mobile Number",
        Header: <i style={{ color: "#31435f" }}>Mobile Number</i>,
      },
      {
        accessorKey: "email",
        header: "Email",
        Header: <i style={{ color: "#31435f" }}>Email</i>,
      },

      {
        accessorKey: "companyName",
        header: "Company Name",
        Header: <i style={{ color: "#31435f" }}>companyName</i>,
      },

      {
        accessorKey: "totalAmount",
        header: "Total Amount",
        Header: <i style={{ color: "#31435f" }}>Total Amount</i>,
      },
    ],
    []
  );

  const csvConfig = mkConfig({
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: true,
    // headers: columns.map((c) => c.header),
  });

  const handleExportRows = (rows) => {
    const csv = generateCsv(csvConfig)(rows.map((row) => row.original));
    download(csvConfig)(csv);
  };

  const handleExportData = () => {
    const csv = generateCsv(csvConfig)(cartList);
    download(csvConfig)(csv);
  };

  return (
    <div>
      {/* <Layout> */}
      <div className="middle_container_top_alignment">
        <div className="breadcrumb__styling">
          <div className="path_styling">
            <h3 className="path__header">Cart</h3>
            {/* <Breadcrumbs /> */}
          </div>
        </div>
      </div>
      <div className="table_data">
        <MaterialReactTable
          columns={columns}
          data={cartList}
          enableRowNumbers={true}
          enableColumnOrdering
          enableGlobalFilter={false}
          enableRowActions={false}
          enableRowSelection
          renderTopToolbarCustomActions={({ table }) => (
            <Box
              sx={{
                display: "flex",
                gap: "1rem",
                p: "0.5rem",
                flexWrap: "wrap",
              }}
            >
              <MaterialButton
                color="primary"
                //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                onClick={handleExportData}
                startIcon={<FileDownloadIcon />}
                variant="contained"
              >
                Export All Data
              </MaterialButton>
              <MaterialButton
                disabled={table.getPrePaginationRowModel().rows.length === 0}
                //export all rows, including from the next page, (still respects filtering and sorting)
                onClick={() =>
                  handleExportRows(table.getPrePaginationRowModel().rows)
                }
                startIcon={<FileDownloadIcon />}
                variant="contained"
              >
                Export All Rows
              </MaterialButton>
              <MaterialButton
                disabled={table.getRowModel().rows.length === 0}
                //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
                onClick={() => handleExportRows(table.getRowModel().rows)}
                startIcon={<FileDownloadIcon />}
                variant="contained"
              >
                Export Page Rows
              </MaterialButton>
              <MaterialButton
                disabled={
                  !table.getIsSomeRowsSelected() &&
                  !table.getIsAllRowsSelected()
                }
                //only export selected rows
                onClick={() =>
                  handleExportRows(table.getSelectedRowModel().rows)
                }
                startIcon={<FileDownloadIcon />}
                variant="contained"
              >
                Export Selected Rows
              </MaterialButton>
            </Box>
          )}
        />

        <PopModal
          show={showUserImage}
          onRequestClose={() => setShowUserImage(false)}
        >
          <img
            src={mainImg}
            alt="product images"
            style={{
              width: "25vw",
              height: "auto",
              textAlign: "center",
            }}
          />
        </PopModal>
      </div>
      {/* </Layout> */}
    </div>
  );
};

export default Cart;
