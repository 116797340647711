import Breadcrumbs from "../../library/Breadcrumbs";
import Button from "../../library/Button";
import Layout from "../../component/Layout";
import "./style.css";
import { useEffect, useState } from "react";
import PopModal from "../../component/PopUpModal";
import EventForm from "../../forms_components/EventForm";
import {
  APIUrl,
  NetworkConfiguration,
} from "../../Network/networkConfiguration";
import { callAPI } from "../../Network/networkConnection";
import { ImageService } from "../../service/ImageService";
import MyCard from "../../component/MyCard/Card";
import AlertPopUp from "../../component/AlertPopUp";
import { successToast } from "../../helper/toast";

const InitialEventData = {
  eventName: "",
  imageUrl: "",
};

const Events = () => {
  const [showUserForm, setShowUserForm] = useState(false);
  const [eventList, setEventList] = useState({});
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [eventId, setEventId] = useState("");
  const [eventData, setEventData] = useState(InitialEventData);
  const [isEdited, setIsEdited] = useState(false);

  useEffect(() => {
    fetchEvent();
  }, []);

  useEffect(() => {
    if (showUserForm) {
      setEventData(InitialEventData);
    }
  }, [showUserForm]);

  const fetchEvent = () => {
    callAPI(APIUrl + NetworkConfiguration.EVENTS, "GET")
      .then((res) => {
        setEventList(res.data);
      })
      .catch((err) => {
        if (err.status === 404) {
          setEventList([]);
        }
        console.log("error", err);
      });
  };

  const onSubmit = (eventData) => {
    callAPI(
      APIUrl + NetworkConfiguration.EVENT,
      "POST",
      JSON.stringify(eventData),
      { "Content-Type": "application/json" }
    )
      .then((res) => {
        // console.log("add product response: ", res, eventData?.image);
        ImageService.imageUpload(
          eventData?.imageUrl,
          "event",
          res?.response?._id
        ).then(() => {
          successToast(res.message);
          setEventData({
            eventName: "",
            imageUrl: "",
          });
          setShowUserForm(false);
          setEventData(InitialEventData);
          fetchEvent();
        });
      })
      .catch((err) => console.log(err));
  };

  const handleDeleteRow = (e) => {
    console.log(e, "id");
    callAPI(APIUrl + NetworkConfiguration.EVENT + `/${e}`, "DELETE")
      .then((res) => {
        successToast(res.message);
        fetchEvent();
        setDeleteAlert(false);
        setEventId("");
      })
      .catch((err) => console.log("error", err));
  };

  const onClickDelete = (id) => {
    setDeleteAlert(true);
    setEventId(id);
  };

  const onUpdate = (eventData) => {
    console.log(eventData, "data");
    callAPI(
      APIUrl + NetworkConfiguration.EVENT + "/" + eventData._id,
      "PUT",
      JSON.stringify(eventData),
      { "Content-Type": "application/json" }
    )
      .then((res) => {
        successToast(res.message);
        setShowUserForm(false);
        setIsEdited(false);
        fetchEvent();
        if (eventData?.imageUrl) {
          ImageService.imageUpload(
            eventData?.imageUrl,
            "event",
            res?.result?._id
          )
            .then(() => {
              setEventData({
                eventName: "",
                imageUrl: "",
              });
              fetchEvent();
              setShowUserForm(false);
            })
            .catch((err) => console.log(err));
        }
      })

      .catch((err) => console.log(err));
  };

  const onClickEdit = (data) => {
    console.log(data, "12345678");
    setIsEdited(true);
    setEventData(data);
    setShowUserForm(true);
  };

  return (
    <div>
      {/* <Layout> */}
      <div className="middle_container_top_alignment">
        <div className="breadcrumb__styling">
          <div className="path_styling">
            <h3 className="path__header"> Events</h3>
            {/* <Breadcrumbs /> */}
          </div>
        </div>

        <AlertPopUp
          show={deleteAlert}
          header="Delete Event"
          description="Do you want to delete this Event?"
          submitText="Yes"
          cancelText="No"
          onCancelClick={() => setDeleteAlert(false)}
          onSubmitClick={() => handleDeleteRow(eventId)}
        ></AlertPopUp>

        <PopModal
          show={showUserForm}
          onRequestClose={() => setShowUserForm(false)}
        >
          {console.log(eventData, "eventData")}
          <EventForm
            onSubmit={onSubmit}
            onUpdate={onUpdate}
            data={eventData}
            setData={setEventData}
            isEdited={isEdited}
          />
        </PopModal>
        <Button
          text="Add Event"
          onClick={() => setShowUserForm(true)}
          style={{ height: "6vh", width: "10vw", margin: "20px" }}
        />
      </div>
      <div className="category__card">
        {eventList?.length &&
          eventList.map((data) => {
            return (
              <MyCard
                title={data?.eventName}
                image={data?.imageUrl}
                onClickEdit={() => onClickEdit(data)}
                onClickDelete={() => onClickDelete(data._id)}
              />
            );
          })}
      </div>
      {/* </Layout> */}
    </div>
  );
};

export default Events;
