import Layout from "../../component/Layout";
import Breadcrumbs from "../../library/Breadcrumbs";
import MaterialReactTable from "material-react-table";
import { useEffect, useMemo, useState } from "react";
import { Edit as EditIcon, Delete as DeleteIcon } from "@mui/icons-material";
import { Box, IconButton, Button as MaterialButton } from "@mui/material";

import "./style.css";
import { callAPI } from "../../Network/networkConnection";
import {
  APIUrl,
  NetworkConfiguration,
} from "../../Network/networkConfiguration";
import AlertPopUp from "../../component/AlertPopUp";
import { successToast } from "../../helper/toast";
import { mkConfig, generateCsv, download } from "export-to-csv";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

const Contact = () => {
  const [contactDetails, setContactDetails] = useState([]);
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [contactId, setContactId] = useState("");

  useEffect(() => {
    fetchContactDetails();
  }, []);

  const fetchContactDetails = () => {
    callAPI(APIUrl + NetworkConfiguration.CONTACTDETAILS, "GET")
      .then((res) => {
        console.log("Contact Details", res);
        setContactDetails(res.data);
      })
      .catch((err) => {
        // if (err.status === 404) {
        setContactDetails([]);
        // }

        console.log("error", err);
      });
  };

  const handleDeleteRow = (id) => {
    callAPI(APIUrl + NetworkConfiguration.DELETECONTACT + "/" + id, "DELETE")
      .then((res) => {
        successToast(res.message);
        fetchContactDetails();
        setContactId("");
        setDeleteAlert(false);
      })
      .catch((err) => console.log(err));
  };

  const onClickDelete = (id) => {
    setDeleteAlert(true);
    setContactId(id);
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "name",
        header: "Name",
        Header: <i style={{ color: "#31435f" }}>Name</i>,
      },
      {
        accessorKey: "email",
        header: "E-mail",
        Header: <i style={{ color: "#31435f" }}>E-mail</i>,
      },
      {
        accessorKey: "message",
        header: "Message",
        Header: <i style={{ color: "#31435f" }}>Message</i>,
      },
    ],
    []
  );

  const csvConfig = mkConfig({
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: true,
    // headers: columns.map((c) => c.header),
  });

  const handleExportRows = (rows) => {
    const csv = generateCsv(csvConfig)(rows.map((row) => row.original));
    download(csvConfig)(csv);
  };

  const handleExportData = () => {
    const csv = generateCsv(csvConfig)(contactDetails);
    download(csvConfig)(csv);
  };

  return (
    <div>
      {/* <Layout> */}
      <div className="middle_container_top_alignment">
        <div className="breadcrumb__styling">
          <div className="path_styling">
            <h3 className="path__header">Contact Us</h3>
            {/* <Breadcrumbs /> */}
          </div>
        </div>
      </div>

      <AlertPopUp
        show={deleteAlert}
        header="Delete Contact"
        description="Do you want to delete this Contact?"
        submitText="Yes"
        cancelText="No"
        onCancelClick={() => setDeleteAlert(false)}
        onSubmitClick={() => handleDeleteRow(contactId)}
      ></AlertPopUp>

      <div className="table_data">
        <MaterialReactTable
          columns={columns}
          data={contactDetails}
          enableRowNumbers={true}
          enableColumnOrdering
          enableGlobalFilter={false} //turn off a feature
          enableRowActions
          enableRowSelection
          renderRowActions={({ row, table }) => (
            <Box sx={{ display: "flex", flexWrap: "nowrap" }}>
              <IconButton
                color="error"
                onClick={() => {
                  contactDetails.splice(row.index, 1);
                }}
              >
                <DeleteIcon onClick={() => onClickDelete(row.original._id)} />
              </IconButton>
            </Box>
          )}
          renderTopToolbarCustomActions={({ table }) => (
            <Box
              sx={{
                display: "flex",
                gap: "1rem",
                p: "0.5rem",
                flexWrap: "wrap",
              }}
            >
              <MaterialButton
                color="primary"
                //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                onClick={handleExportData}
                startIcon={<FileDownloadIcon />}
                variant="contained"
              >
                Export All Data
              </MaterialButton>
              <MaterialButton
                disabled={table.getPrePaginationRowModel().rows.length === 0}
                //export all rows, including from the next page, (still respects filtering and sorting)
                onClick={() =>
                  handleExportRows(table.getPrePaginationRowModel().rows)
                }
                startIcon={<FileDownloadIcon />}
                variant="contained"
              >
                Export All Rows
              </MaterialButton>
              <MaterialButton
                disabled={table.getRowModel().rows.length === 0}
                //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
                onClick={() => handleExportRows(table.getRowModel().rows)}
                startIcon={<FileDownloadIcon />}
                variant="contained"
              >
                Export Page Rows
              </MaterialButton>
              <MaterialButton
                disabled={
                  !table.getIsSomeRowsSelected() &&
                  !table.getIsAllRowsSelected()
                }
                //only export selected rows
                onClick={() =>
                  handleExportRows(table.getSelectedRowModel().rows)
                }
                startIcon={<FileDownloadIcon />}
                variant="contained"
              >
                Export Selected Rows
              </MaterialButton>
            </Box>
          )}
        />
      </div>
      {/* </Layout> */}
    </div>
  );
};

export default Contact;
