import Layout from "../../component/Layout";
import Breadcrumbs from "../../library/Breadcrumbs";
import MaterialReactTable from "material-react-table";
import "./style.css";
import { useEffect, useMemo, useState } from "react";
import { Edit as EditIcon, Delete as DeleteIcon } from "@mui/icons-material";
import { Box, IconButton, Button as MaterialButton } from "@mui/material";
import { callAPI } from "../../Network/networkConnection";
import {
  APIUrl,
  NetworkConfiguration,
} from "../../Network/networkConfiguration";
import { ImImages } from "react-icons/im";
import PopModal from "../../component/PopUpModal";
import moment from "moment";
import { MdHistory } from "react-icons/md";
import { TiTick } from "react-icons/ti";
// import InputField from "../../component/TextField";
import InputField from "../../library/TextField";
import Button from "../../library/Button";
import { successToast } from "../../helper/toast";
import Form from "../../component/Form";
import UserForm from "../../forms_components/userForm";
import { ImageService } from "../../service/ImageService";
import AlertPopUp from "../../component/AlertPopUp";
import { mkConfig, generateCsv, download } from "export-to-csv";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

const Users = () => {
  const [teamData, setTeamData] = useState([]);
  const [showUserImage, setShowUserImage] = useState(false);
  const [userImage, setUserImage] = useState("");
  const [showOldNumber, setShowOldNumber] = useState(false);
  const [showOldMail, setShowOldMail] = useState(false);
  const [oldNumbers, setOldNumbers] = useState([]);
  const [oldEmail, setOldEmail] = useState([]);
  const [id, setId] = useState("");
  const [suspend, setSuspend] = useState("");
  const [showSuspendPopup, setShowSuspendPopup] = useState(false);
  const [warning, setWarning] = useState("");
  const [showWarningPopup, setShowWarningPopup] = useState(false);
  const [suspendDate, setSuspendDate] = useState("");
  const [warnUserTitle, setWarnUserTitle] = useState("");
  const [warnUserDesc, setWarnUserDesc] = useState("");
  const [showUserEditForm, setShowUserEditForm] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [userId, setUserId] = useState("");
  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    dob: "",
    phone: "",
    email: "",
    gender: "",
    state: "",
    city: "",
    zip_code: "",
    companyName: "",
    gstNumber: "",
    imageUrl: "",
  });

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = () => {
    callAPI(APIUrl + NetworkConfiguration.GETUSERS, "GET")
      .then((res) => {
        console.log("res", res);
        setTeamData(res.users);
      })
      .catch((err) => console.log("err", err));
  };

  const onsubmit = () => {
    const suspendPayload = {
      id: id,
      suspensionEndDate: suspendDate,
    };
    callAPI(
      APIUrl + NetworkConfiguration.SUSPENDUSER,
      "POST",
      JSON.stringify(suspendPayload),
      { "content-type": "application/json" }
    )
      .then((res) => {
        setShowSuspendPopup(false);
        successToast(res.message);
        fetchUsers();
      })
      .catch((err) => console.log("err", err));
  };

  const onWarnUser = () => {
    const warnUserPayload = {
      id: id,
      title: warnUserTitle,
      body: warnUserDesc,
    };
    callAPI(
      APIUrl + NetworkConfiguration.WARN_USER,
      "POST",
      JSON.stringify(warnUserPayload),
      { "content-type": "application/json" }
    )
      .then((res) => {
        setShowWarningPopup(false);
        successToast(res.message);
        setWarnUserTitle("");
        setWarnUserDesc("");
        fetchUsers();
      })
      .catch((err) => console.log("err", err));
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "firstName",
        header: "First Name",
        Header: <i style={{ color: "#31435f" }}>First Name</i>,
      },
      {
        accessorKey: "lastName",
        header: "Last Name",
        Header: <i style={{ color: "#31435f" }}>Last Name</i>,
      },
      {
        accessorKey: "dob",
        header: "DOB",
        Header: <i style={{ color: "#31435f" }}>DOB</i>,
      },
      {
        accessorKey: "phone",
        header: "Mobile Number",
        Header: <i style={{ color: "#31435f" }}>Mobile Number</i>,
        Cell: ({ cell }) => {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "5px",
              }}
            >
              {cell.row.original.phone}

              {cell.row.original.oldPhone.length > 0 && (
                <MdHistory
                  onClick={() => {
                    setShowOldNumber(true);
                    setOldNumbers(cell.row.original.oldPhone);
                  }}
                  style={{
                    width: "20px",
                    height: "auto",
                    cursor: "pointer",
                  }}
                />
              )}
            </div>
          );
        },
      },
      {
        accessorKey: "email",
        header: "E-mail",
        Header: <i style={{ color: "#31435f" }}>E-mail</i>,
        Cell: ({ cell }) => {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "5px",
              }}
            >
              {cell.row.original.email}
              {cell.row.original.oldEmail.length > 0 && (
                <MdHistory
                  onClick={() => {
                    setShowOldMail(true);
                    setOldEmail(cell.row.original.oldEmail);
                  }}
                  style={{
                    width: "20px",
                    height: "auto",
                    cursor: "pointer",
                  }}
                />
              )}
            </div>
          );
        },
      },
      {
        accessorKey: "gender",
        header: "Gender",
        Header: <i style={{ color: "#31435f" }}>Gender</i>,
      },
      {
        accessorKey: "address.state",
        header: "State",
        Header: <i style={{ color: "#31435f" }}>State</i>,
      },
      {
        accessorKey: "address.city",
        header: "City",
        Header: <i style={{ color: "#31435f" }}>City</i>,
      },
      {
        accessorKey: "address.zip_code",
        header: "Zip Code",
        Header: <i style={{ color: "#31435f" }}>Zip_code</i>,
      },
      {
        accessorKey: "companyName",
        header: "Company Name",
        Header: <i style={{ color: "#31435f" }}>Company Name</i>,
      },
      {
        accessorKey: "gstNumber",
        header: "GST Number",
        Header: <i style={{ color: "#31435f" }}>GST Number</i>,
      },

      {
        accessorKey: "imageUrl",
        header: "Image",
        Header: <i style={{ color: "#31435f" }}>Image</i>,
        Cell: ({ cell }) => {
          return (
            cell.row.original.imageUrl && (
              <ImImages
                show={showUserImage}
                onRequestClose={() => setShowUserImage(false)}
                className="eye_icon"
                onClick={() => {
                  setShowUserImage(true);
                  setUserImage(cell.row.original.imageUrl);
                }}
              />
            )
          );
        },
      },
      {
        accessorKey: "createdAt",
        header: "Created At",
        Header: <i style={{ color: "#31435f" }}>Created At</i>,
        Cell: ({ cell }) =>
          moment(cell.row.original.createdAt).format("MM/DD/YYYY , LT"),
      },
      {
        // accessorKey: "createdAt",
        header: "Status",
        Header: <i style={{ color: "#31435f" }}>Suspend Warning</i>,
        Cell: ({ cell }) =>
          cell?.row?.original?.suspendedUntil ? (
            <div>
              <h4 style={{ color: "red" }}> Suspended Until </h4>
              <p>{cell?.row?.original?.suspendedUntil.substring(0, 10)}</p>
            </div>
          ) : (
            <UserStatus
              cell={cell}
              setShowSuspendPopup={setShowSuspendPopup}
              setShowWarningPopup={setShowWarningPopup}
              setWarnUserTitle={setWarnUserTitle}
              setWarnUserDesc={setWarnUserDesc}
              setId={setId}
            />
          ),
      },
    ],
    []
  );

  const csvConfig = mkConfig({
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: true,
  });

  const onUpdate = (data) => {
    console.log("Data: ", data);
    callAPI(
      APIUrl + NetworkConfiguration.UPDATE_USER,
      "POST",
      JSON.stringify(data),
      {
        "Content-Type": "application/json",
      }
    )
      .then((res) => {
        successToast(res.message);
        if (data?.imageUrl) {
          ImageService.imageUpload(data?.imageUrl, "user", data.id)
            .then(() => {
              setUserData({
                id: "",
                firstName: "",
                lastName: "",
                dob: "",
                phone: "",
                email: "",
                gender: "",
                address: {
                  state: "",
                  city: "",
                  zip_code: "",
                },
                companyName: "",
                gstNumber: "",
                imageUrl: "",
              });
              setIsEdited(false);
              setShowUserEditForm(false);
              fetchUsers();
            })
            .catch((err) => console.log("ImageService in updating user", err));
        } else {
          setUserData({
            id: "",
            firstName: "",
            lastName: "",
            dob: "",
            phone: "",
            email: "",
            gender: "",
            address: {
              state: "",
              city: "",
              zip_code: "",
            },
            companyName: "",
            gstNumber: "",
            imageUrl: "",
          });
          setIsEdited(false);
          setShowUserEditForm(false);
          fetchUsers();
        }
      })
      .catch((err) => {
        console.log(err);
        setIsEdited(false);
        setShowUserEditForm(false);
        fetchUsers();
      });
  };

  const handleDeleteRow = (id) => {
    callAPI(
      APIUrl + NetworkConfiguration.DELETE_USER,
      "POST",
      JSON.stringify({ id }),
      {
        "Content-Type": "application/json",
      }
    )
      .then((res) => {
        successToast(res.message);
        fetchUsers();
        setDeleteAlert(false);
        setUserId("");
      })
      .catch((err) => console.log(err));
  };
  // console.log("data", data);

  const onClickDelete = (id) => {
    setDeleteAlert(true);
    setUserId(id);
  };

  const handleExportRows = (rows) => {
    const csv = generateCsv(csvConfig)(rows.map((row) => row.original));
    download(csvConfig)(csv);
  };

  const handleExportData = () => {
    const csv = generateCsv(csvConfig)(teamData);
    download(csvConfig)(csv);
  };

  return (
    <div>
      {/* <Layout> */}
      <div className="middle_container_top_alignment">
        <div className="breadcrumb__styling">
          <div className="path_styling">
            <h3 className="path__header">Users</h3>
            {/* <Breadcrumbs /> */}
          </div>
        </div>
      </div>
      <Form
        show={showUserEditForm}
        onRequestClose={() => setShowUserEditForm(false)}
      >
        <UserForm
          onSubmit={() => {}}
          onUpdate={onUpdate}
          data={userData}
          isEdited={isEdited}
        />
      </Form>
      <div className="table_data">
        <MaterialReactTable
          columns={columns}
          data={teamData}
          enableRowNumbers={true}
          enableColumnOrdering
          enableGlobalFilter={false}
          enableRowActions
          enableRowSelection
          // onEditingRowSave={handleSaveRow}
          renderRowActions={({ row, table }) => (
            <Box sx={{ display: "flex", flexWrap: "nowrap" }}>
              <IconButton
                style={{ color: "#1468af", marginLeft: "-6px" }}
                onClick={() => {
                  setUserData({
                    id: row.original._id,
                    firstName: row.original.firstName,
                    lastName: row.original.lastName,
                    dob: row.original.dob,
                    phone: row.original.phone,
                    email: row.original.email,
                    gender: row.original.gender,
                    address: {
                      state: row.original.address?.state,
                      city: row.original.address?.city,
                      zip_code: row.original.address?.zip_code,
                    },
                    companyName: row.original.companyName,
                    gstNumber: row.original.gstNumber,
                    imageUrl: row.original.imageUrl,
                  });
                  setIsEdited(true);
                  setShowUserEditForm(true);
                }}
              >
                <EditIcon />
              </IconButton>
              <IconButton color="error">
                <DeleteIcon onClick={() => onClickDelete(row.original._id)} />
              </IconButton>
            </Box>
          )}
          renderTopToolbarCustomActions={({ table }) => (
            <Box
              sx={{
                display: "flex",
                gap: "1rem",
                p: "0.5rem",
                flexWrap: "wrap",
              }}
            >
              <MaterialButton
                color="primary"
                //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                onClick={handleExportData}
                startIcon={<FileDownloadIcon />}
                variant="contained"
              >
                Export All Data
              </MaterialButton>
              <MaterialButton
                disabled={table.getPrePaginationRowModel().rows.length === 0}
                //export all rows, including from the next page, (still respects filtering and sorting)
                onClick={() =>
                  handleExportRows(table.getPrePaginationRowModel().rows)
                }
                startIcon={<FileDownloadIcon />}
                variant="contained"
              >
                Export All Rows
              </MaterialButton>
              <MaterialButton
                disabled={table.getRowModel().rows.length === 0}
                //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
                onClick={() => handleExportRows(table.getRowModel().rows)}
                startIcon={<FileDownloadIcon />}
                variant="contained"
              >
                Export Page Rows
              </MaterialButton>
              <MaterialButton
                disabled={
                  !table.getIsSomeRowsSelected() &&
                  !table.getIsAllRowsSelected()
                }
                //only export selected rows
                onClick={() =>
                  handleExportRows(table.getSelectedRowModel().rows)
                }
                startIcon={<FileDownloadIcon />}
                variant="contained"
              >
                Export Selected Rows
              </MaterialButton>
            </Box>
          )}
        />

        <PopModal
          show={showUserImage}
          onRequestClose={() => setShowUserImage(false)}
        >
          <img
            src={userImage}
            alt="team_member"
            style={{
              width: "25vw",
              maxHeight: "95vh",
              textAlign: "center",
            }}
          />
        </PopModal>
        <PopModal
          show={showOldNumber}
          onRequestClose={() => {
            setShowOldNumber(false);
          }}
        >
          <div>
            <p className="old__data">
              {oldNumbers.length !== 0 ? (
                <>
                  <h3 style={{ marginBottom: "10px" }}>Old Numbers</h3>
                  <div>
                    <span className="old__numbers">
                      {oldNumbers?.map((item) => (
                        <>{item}</>
                      ))}
                    </span>
                  </div>
                </>
              ) : (
                <h3>No data found</h3>
              )}
            </p>
          </div>
        </PopModal>

        <PopModal
          show={showSuspendPopup}
          onRequestClose={() => {
            setShowSuspendPopup(false);
          }}
        >
          <div style={{ width: "auto", height: "auto", padding: "10px" }}>
            <h2 className="suspension__letter">Suspension Letter</h2>

            <br />
            <InputField
              onChange={(e) => setSuspendDate(e.target.value)}
              type="date"
              label="For how many days do you want the user to suspend?"
              // placeholder="DD/MM/YYYY"
            />
            {suspend}
            <br />
            <Button
              onClick={onsubmit}
              text="Submit"
              style={{ width: "10vw", height: "6vh", margin: "auto" }}
            />
          </div>
        </PopModal>

        <PopModal
          show={showWarningPopup}
          onRequestClose={() => {
            setShowWarningPopup(false);
          }}
        >
          <div style={{ width: "auto", height: "auto", padding: "10px" }}>
            <h2 className="suspension__letter">Warning Letter</h2>
            <br />
            <InputField
              onChange={(e) => setWarnUserTitle(e.target.value)}
              label="Title"
              style={{ width: "18vw", marginBottom: "10px" }}
            />
            <InputField
              onChange={(e) => setWarnUserDesc(e.target.value)}
              label="Body"
              style={{ width: "18vw" }}
            />
            <br />
            {warning}
            <Button
              onClick={onWarnUser}
              text="Submit"
              style={{ width: "10vw", height: "6vh", margin: "auto" }}
            />
          </div>
        </PopModal>

        <PopModal
          show={showOldMail}
          onRequestClose={() => {
            setShowOldMail(false);
          }}
        >
          <div>
            <p className="old__data">
              {oldEmail.length !== 0 ? (
                <>
                  <h3 style={{ marginBottom: "10px" }}>Old Emails</h3>
                  <div>
                    <span className="old__emails">
                      {oldEmail?.map((item) => (
                        <>{item}</>
                      ))}
                    </span>
                  </div>
                </>
              ) : (
                <h3>No data found</h3>
              )}
            </p>
          </div>
        </PopModal>

        <AlertPopUp
          show={deleteAlert}
          header="Delete Product"
          description="Do you want to delete this user?"
          submitText="Yes"
          cancelText="No"
          onCancelClick={() => setDeleteAlert(false)}
          onSubmitClick={() => handleDeleteRow(userId)}
        ></AlertPopUp>
      </div>
      {/* </Layout> */}
    </div>
  );
};

const UserStatus = ({
  cell,
  setShowSuspendPopup,
  setShowWarningPopup,
  setWarnUserTitle,
  setWarnUserDesc,
  setId,
}) => {
  const [action, setAction] = useState("");

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        gap: "10px",
        alignItems: "center",
      }}
    >
      <select
        onChange={(e) => {
          setId(cell.row.original._id);
          console.log("Action value changed ", e.target.value, setAction);
          console.log("Id value changed ", cell.row.original._id);
          setAction(e.target.value);
        }}
        style={{ padding: "10px", borderRadius: "none" }}
        className="suspend_warning_action"
      >
        <option value="" selected default>
          Select
        </option>
        <option className="suspend_warning_action" value="suspend">
          Suspend
        </option>
        <option className="suspend_warning_action" value="warning">
          Warning
        </option>
      </select>
      <TiTick
        onClick={() => {
          console.log("Action value: ", action);
          if (action === "suspend") {
            setShowSuspendPopup(true);
          } else if (action === "warning") {
            setShowWarningPopup(true);
            setWarnUserTitle("");
            setWarnUserDesc("");
          }
        }}
        style={{ cursor: "pointer", width: "30px", height: "auto" }}
      />
    </div>
  );
};

export default Users;
