import { useEffect, useMemo, useState } from "react";
import Layout from "../../component/Layout";
import Breadcrumbs from "../../library/Breadcrumbs";
import MaterialReactTable from "material-react-table";
import { Box, IconButton, Button as MaterialButton } from "@mui/material";
import { Edit as EditIcon, Delete as DeleteIcon } from "@mui/icons-material";
import { callAPI } from "../../Network/networkConnection";
import {
  APIUrl,
  NetworkConfiguration,
} from "../../Network/networkConfiguration";
import { ImImages } from "react-icons/im";
import PopModal from "../../component/PopUpModal";
// import Image1 from "../../assets/images/AK47.png";
import moment from "moment/moment";
import Form from "../../component/Form";
// import Product from "../../forms_components/product";
import Button from "../../library/Button";
import TeamForm from "../../forms_components/TeamFom";
import "./style.css";
import Dropdown from "../../library/DropDown";
import { ImageService } from "../../service/ImageService";
import EditTeam from "../../forms_components/EditTeam";
import AlertPopUp from "../../component/AlertPopUp";
import { errorToast, successToast } from "../../helper/toast";
import { mkConfig, generateCsv, download } from "export-to-csv";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

const Team = () => {
  const [showUserForm, setShowUserForm] = useState(false);
  const [teamList, setTeamList] = useState([]);
  const [showTeamimage, setShowTeamImage] = useState(false);
  const [modalImg, setModalImg] = useState("");
  const [showEditUserForm, setShowEditUserForm] = useState(false);
  const [data, setData] = useState([]);
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [teamId, setTeamId] = useState("");
  const [teamData, setTeamData] = useState({
    name: "",
    role: "",
    teamType: "",
    image: "",
  });

  const changeDropdown = (e) => {
    fetchTeamList(e.target.value);
  };

  useEffect(() => {
    fetchTeamList();
  }, []);

  const fetchTeamList = (teamType = "") => {
    callAPI(
      APIUrl + NetworkConfiguration.TEAMS,
      "POST",
      JSON.stringify({ teamType: teamType }),
      { "Content-Type": "application/json" }
    )
      .then((res) => {
        setTeamData({
          name: "",
          role: "",
          teamType: "",
          image: "",
        });
        console.log("----", res.data);
        setTeamList(res.data);
      })
      .catch((err) => {
        if (err.status === 404) {
          setTeamList([]);
        }
        console.log("error", err);
      });
  };

  const onSubmit = (teamData) => {
    callAPI(
      APIUrl + NetworkConfiguration.TEAM,
      "POST",
      JSON.stringify(teamData),
      { "Content-Type": "application/json" }
    )
      .then((res) => {
        successToast(res.message);
        ImageService.imageUpload(
          teamData?.image,
          "team",
          res?.response?._id
        ).then(() => {
          setShowUserForm(false);
          fetchTeamList();
        });
      })
      .catch((err) => {
        errorToast("Please enter full details");
        console.log(err);
      });
  };
  const onUpdate = (id) => {
    callAPI(
      APIUrl + NetworkConfiguration.TEAM + `/${id}`,
      "PUT",
      JSON.stringify(data),
      {
        "Content-Type": "application/json",
      }
    )
      .then((res) => {
        successToast(res.message);

        if (data?.image) {
          ImageService.imageUpload(
            data?.image,
            "team",
            res?.response?._id
          ).then(() => {
            setShowEditUserForm(!showEditUserForm);
            fetchTeamList();
          });
        } else {
          console.log("hellooowowo");
          setShowEditUserForm(!showEditUserForm);
          fetchTeamList();
        }
      })
      .catch((err) => console.log(err));
  };

  const handleDeleteRow = (id) => {
    callAPI(APIUrl + NetworkConfiguration.TEAM + "/" + id, "DELETE")
      .then((res) => {
        successToast(res.message);
        fetchTeamList();
        setDeleteAlert(false);
        setTeamId("");
      })
      .catch((err) => console.log(err));
  };

  const onClickDelete = (id) => {
    setDeleteAlert(true);
    setTeamId(id);
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "name", //simple recommended way to define a column
        header: "Name",
        Header: <i style={{ color: "#31435f" }}>Name</i>,
      },
      {
        accessorKey: "role",
        header: "Role",
        Header: <i style={{ color: "#31435f" }}>Role</i>,
      },
      {
        accessorKey: "teamType",
        header: "Team Type",
        Header: <i style={{ color: "#31435f" }}>Team Type</i>,
      },
      {
        accessorKey: "imageUrl",
        header: "Image Url",
        Header: <i style={{ color: "#31435f" }}>Image Url</i>,
        Cell: ({ cell }) => {
          return (
            <ImImages
              show={showTeamimage}
              onRequestClose={() => setShowTeamImage(false)}
              className="eye_icon"
              onClick={() => {
                console.log("image=------", cell?.row?.original?.imageUrl);
                setShowTeamImage(true);
                setModalImg(cell?.row?.original?.imageUrl);
              }}
            />
          );
        },
      },
      {
        accessorKey: "createdAt",
        header: "Date & Time",
        Header: <i style={{ color: "#31435f" }}>Date & Time</i>,
        Cell: ({ cell }) =>
          moment(cell.row.original.createdAt).format("MM/DD/YYYY , LT"),
      },
    ],
    []
  );

  const csvConfig = mkConfig({
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: true,
    // headers: columns.map((c) => c.header),
  });

  const handleExportRows = (rows) => {
    const csv = generateCsv(csvConfig)(rows.map((row) => row.original));
    download(csvConfig)(csv);
  };

  const handleExportData = () => {
    const csv = generateCsv(csvConfig)(teamList);
    download(csvConfig)(csv);
  };

  return (
    <div>
      {/* <Layout> */}
      <div className="middle_container_top_alignment">
        <div className="breadcrumb__styling">
          <div className="path_styling">
            <h3 className="path__header">Team</h3>
            {/* <Breadcrumbs /> */}
          </div>
        </div>

        <div className="header__align">
          <Dropdown
            style={{ width: "10vw" }}
            onChange={changeDropdown}
            options={[
              {
                name: "Any",
                value: "",
              },
              {
                name: "Employee",
                value: "employee",
              },
              {
                name: "Leadership",
                value: "leadership",
              },
              {
                name: "Professional",
                value: "professional",
              },
            ]}
          />
          <Button
            text="Add Team"
            onClick={() => setShowUserForm(true)}
            style={{ height: "6vh", width: "10vw", margin: "20px" }}
          />
        </div>
      </div>
      <AlertPopUp
        show={deleteAlert}
        header="Delete Team Member"
        description="Do you want to delete this Team Member?"
        submitText="Yes"
        cancelText="No"
        onCancelClick={() => setDeleteAlert(false)}
        onSubmitClick={() => handleDeleteRow(teamId)}
      ></AlertPopUp>

      <Form show={showUserForm} onRequestClose={() => setShowUserForm(false)}>
        <TeamForm
          onSubmit={onSubmit}
          teamData={teamData}
          setTeamData={setTeamData}
        />
      </Form>

      <Form
        show={showEditUserForm}
        onRequestClose={() => setShowEditUserForm(false)}
      >
        <EditTeam onUpdate={onUpdate} data={data} setData={setData} />
      </Form>

      <div className="table_data">
        <MaterialReactTable
          columns={columns}
          data={teamList}
          enableRowNumbers={true}
          enableColumnOrdering
          enableGlobalFilter={false}
          enableRowActions
          enableRowSelection
          renderRowActions={({ row, table }) => (
            <Box sx={{ display: "flex", flexWrap: "nowrap" }}>
              <IconButton
                style={{ color: "#1468af", marginLeft: "-6px" }}
                onClick={() => {
                  setShowEditUserForm(true);
                  setData(row.original);
                }}
              >
                <EditIcon />
              </IconButton>
              <IconButton
                color="error"
                onClick={() => {
                  teamList.splice(row.index, 1);
                }}
              >
                <DeleteIcon onClick={() => onClickDelete(row.original._id)} />
              </IconButton>
            </Box>
          )}
          renderTopToolbarCustomActions={({ table }) => (
            <Box
              sx={{
                display: "flex",
                gap: "1rem",
                p: "0.5rem",
                flexWrap: "wrap",
              }}
            >
              <MaterialButton
                color="primary"
                //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                onClick={handleExportData}
                startIcon={<FileDownloadIcon />}
                variant="contained"
              >
                Export All Data
              </MaterialButton>
              <MaterialButton
                disabled={table.getPrePaginationRowModel().rows.length === 0}
                //export all rows, including from the next page, (still respects filtering and sorting)
                onClick={() =>
                  handleExportRows(table.getPrePaginationRowModel().rows)
                }
                startIcon={<FileDownloadIcon />}
                variant="contained"
              >
                Export All Rows
              </MaterialButton>
              <MaterialButton
                disabled={table.getRowModel().rows.length === 0}
                //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
                onClick={() => handleExportRows(table.getRowModel().rows)}
                startIcon={<FileDownloadIcon />}
                variant="contained"
              >
                Export Page Rows
              </MaterialButton>
              <MaterialButton
                disabled={
                  !table.getIsSomeRowsSelected() &&
                  !table.getIsAllRowsSelected()
                }
                //only export selected rows
                onClick={() =>
                  handleExportRows(table.getSelectedRowModel().rows)
                }
                startIcon={<FileDownloadIcon />}
                variant="contained"
              >
                Export Selected Rows
              </MaterialButton>
            </Box>
          )}
        />
        <PopModal
          show={showTeamimage}
          onRequestClose={() => setShowTeamImage(false)}
        >
          {modalImg ? (
            <img
              src={modalImg}
              alt="team_member"
              style={{
                width: "25vw",
                height: "auto",
                textAlign: "center",
              }}
            />
          ) : (
            <p style={{ margin: "2rem" }}>No image found</p>
          )}
        </PopModal>
      </div>
      {/* </Layout> */}
    </div>
  );
};

export default Team;
