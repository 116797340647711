import { useState, useEffect } from "react";
import axios from "axios";
import { APIUrl, APIUser } from "../../../Network/networkConfiguration";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { FaEye } from "react-icons/fa";

const PollList = () => {
  const [polls, setPolls] = useState([]);
  const [selectedPoll, setSelectedPoll] = useState(null);
  const [showUserModal, setShowUserModal] = useState(false);
  const [users, setUsers] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPolls = async () => {
      try {
        const response = await axios.get(`${APIUser}polls`);
        console.log(response?.data?.polls, "kkk");
        setPolls(response?.data?.polls);
      } catch (error) {
        toast.error("Failed to load polls");
      }
    };
    fetchPolls();
  }, []);

  const handleDeletePoll = async (pollId) => {
    try {
      const res = await axios.delete(`${APIUrl}poll/${pollId}`);
      if (res) {
        try {
          const response = await axios.get(`${APIUser}polls`);
          console.log(response?.data?.polls, "kkk");
          setPolls(response?.data?.polls);
        } catch (error) {
          toast.error("Failed to load polls");
        }
      }
      toast.success("Poll deleted successfully");
    } catch (error) {
      toast.error("Failed to delete poll");
    }
  };

  const handleViewUsers = (poll) => {
    setSelectedPoll(poll);
    setUsers(poll.userVoted);
    setShowUserModal(true);
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold">Poll List</h1>
      <table className="min-w-full table-auto mt-4">
        <thead>
          <tr>
            <th className="px-4 py-2">Question</th>
            <th className="px-4 py-2">Options</th>
            <th className="px-4 py-2">Actions</th>
          </tr>
        </thead>
        <tbody>
          {polls?.map((poll) => (
            <tr key={poll?._id}>
              <td className="border px-4 py-2">{poll?.question}</td>
              <td className="border px-4 py-2">
                {poll?.choices.map((choice) => (
                  <div key={choice?._id}>
                    {choice?.value} - {choice?.count} votes
                  </div>
                ))}
              </td>
              <td className="border px-4 py-2 flex">
                <button
                  onClick={() => navigate(`/Component/polls/edit/${poll?._id}`)}
                  className="bg-blue-500 text-white p-2 rounded mr-2"
                >
                  Edit
                </button>
                <button
                  onClick={() => handleDeletePoll(poll?._id)}
                  className="bg-red-500 text-white p-2 rounded"
                >
                  Delete
                </button>
                <button
                  onClick={() => handleViewUsers(poll)}
                  className="bg-green-500 text-white p-2 rounded ml-2"
                >
                  <FaEye />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {showUserModal && (
        <div className="modal">
          {/* Modal content for showing user answers */}
          <div className="modal-content">
            <h2>Users and their answers</h2>
            {users?.map((user, index) => (
              <div key={index}>
                <p>User ID: {user}</p>
                {/* Logic for showing whether their answer is correct */}
              </div>
            ))}
            <button onClick={() => setShowUserModal(false)}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default PollList;
